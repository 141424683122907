import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Steps,
  message,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBookSharp, IoDocumentSharp } from "react-icons/io5";
import { GrUserExpert } from "react-icons/gr";
import { IoIosTime } from "react-icons/io";
import { FaUserCheck } from "react-icons/fa";
import { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddAboutUs from "../Leads/AboutUs/AddAboutUs";
import AddReferral from "../Leads/Referral/AddReferral";
import { date, dateUTC, scrollToTopFormError } from "../../HelperFunction";
import AddDocumentTypes from "./AddDocumentTypes";
import AddContacts from "../Contacts/AddContacts";
import AddCountry from "../Leads/Country/AddCountry";
import AddCampaign from "../Contacts/AddCampaign";
import { getCookie } from "../../Helpers/FrontendHelper";
import axios from "axios";
const { Option } = Select;
const { TextArea } = Input;

const AddClient = ({
  record,
  isAddClientModalVisible,
  setIsAddClientModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [clientId, setClientId] = useState();
  const [custometDetails, setCustometDetails] = useState({});
  const [customerDocuments, setCustomerDocuments] = useState({});
  const [customerCourses, setCustomerCourses] = useState({});
  const [customerQualification, setCustomerQualification] = useState({});
  const [customerExperience, setCustomerExperience] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [customenrInfoValues, setCustomenrInfoValues] = useState({
    ContactType: true,
    contact: record?.id,
    contactName: `${record?.fullName ?? ""} 
    ${
      record?.email !== null &&
      record?.email !== "" &&
      record?.email !== undefined
        ? `(${record?.email})`
        : record?.phoneNumber !== null &&
          record?.phoneNumber !== "" &&
          record?.phoneNumber !== undefined
        ? `(${record?.phoneNumber ?? ""})`
        : ""
    }
    `,
    firstName: record?.firstName ?? "",
    lastName: record?.lastName ?? "",
    email: record?.email ?? "",
    mobile: record?.phoneNumber ?? "",
    dateOfBirth: "",
    passportNumber: "",
    visaType: "",
    visaStatus: "",
    visaExpiryDate: "",
    passportExpiryDate: "",
    campaign: record?.campaign?.id ?? "",
    campaignName: record?.campaign?.name ?? "",
    phone: "",
    aboutUs: "",
    aboutUsName: "",
    referral: "",
    referralName: "",
    address: "",
    maritalStatus: "",
    sexStatus: "",
    countryOfResidence: record?.countryOfResidence ?? "",
    countryName: "",
    sendByEmail: false,
  });
  const [clientCourseData, setClientCourseData] = useState({
    completedIelts: false,
    completedTofel: false,
    completedGmat: false,
    completedGre: false,
    completedSat: false,
    completedPte: false,
    satIbtScore: "",
    gmatIbtScore: "",
    greIbtScore: "",
    pteSpeaking: "",
    pteWriting: "",
    pteReading: "",
    pteListening: "",
    pteScore: "",
    tofelIbtScore: "",
    speaking: "",
    writing: "",
    reading: "",
    listening: "",
    totalIeltsScore: "",
  });

  const [qualification, setQualification] = useState([]);
  const [experience, setExperience] = useState([]);
  const { Option } = Select;

  const addCustomerPhotoPayload = (response) => {
    setClientId(response.id);
    const photoFormData = new FormData();

    photoFormData.append("image", selectedPhoto);
    selectedPhoto !== null &&
      selectedPhoto !== undefined &&
      dispatch({
        type: "ADD_CLIENT_PHOTO_REQUEST",
        payload: { clientId: response.id, photoFormData },
      });
  };

  const addClientSubmitHandler = (value, selectedPhoto) => {
    setCustometDetails(value);
    setSelectedPhoto(selectedPhoto);
    // dispatch({ type: "ADD_CLIENT_REQUEST", payload: formData });
    setCurrent(1);
  };
  const addCustomerCoursesSubmitHandler = (value) => {
    setCustomerCourses(value);

    setCurrent(2);
  };
  const addCustomerQualificationSubmitHandler = (qualification) => {
    setCustomerQualification(qualification);

    setCurrent(3);
  };
  const addCustomerExperienceSubmitHandler = (value) => {
    setCustomerExperience(value);
    const clientData = {
      ...custometDetails,
      ...customerCourses,
      branchId: localStorage.getItem("branchId"),
      qualifications: customerQualification,
      experiences: value,
    };
    dispatch({
      type: "ADD_CLIENT_REQUEST",
      payload: { clientData, photoPayloadFn: addCustomerPhotoPayload },
    });
    setCustomenrInfoValues({
      ContactType: true,
      contact: "",
      contactName: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      dateOfBirth: "",
      passportNumber: "",
      visaType: "",
      visaStatus: "",
      visaExpiryDate: "",
      passportExpiryDate: "",
      campaign: "",
      campaignName: "",
      phone: "",
      aboutUs: "",
      aboutUsName: "",
      referral: "",
      referralName: "",
      address: "",
      maritalStatus: "",
      sexStatus: "",
      countryOfResidence: "",
      countryName: "",
      sendByEmail: false,
    });
    setClientCourseData({
      completedIelts: false,
      completedTofel: false,
      completedGmat: false,
      completedGre: false,
      completedSat: false,
      completedPte: false,
      satIbtScore: "",
      gmatIbtScore: "",
      greIbtScore: "",
      pteSpeaking: "",
      pteWriting: "",
      pteReading: "",
      pteListening: "",
      pteScore: "",
      tofelIbtScore: "",
      speaking: "",
      writing: "",
      reading: "",
      listening: "",
      totalIeltsScore: "",
    });
    setQualification([]);
    setExperience([]);

    setCurrent(4);
  };
  const addCustomentDocumentsSubmitHandler = (value) => {
    setCustomerDocuments(value);
  };

  const forms = [
    <CustomerInfo
      current={current}
      setCurrent={setCurrent}
      onAddCustomerSubmitHandler={addClientSubmitHandler}
      customenrInfoValues={customenrInfoValues}
      setCustomenrInfoValues={setCustomenrInfoValues}
    />,
    <CustomerCourses
      current={current}
      setCurrent={setCurrent}
      clientCourseData={clientCourseData}
      setClientCourseData={setClientCourseData}
      onCustomerCoursesSubmitHandler={addCustomerCoursesSubmitHandler}
    />,
    <CustomerQualification
      current={current}
      setCurrent={setCurrent}
      clientId={clientId}
      qualification={qualification}
      setQualification={setQualification}
      onCustomerQualificationSubmitHandler={
        addCustomerQualificationSubmitHandler
      }
    />,
    <CustomerExperience
      current={current}
      setCurrent={setCurrent}
      clientId={clientId}
      setExperience={setExperience}
      experience={experience}
      onCustomerExperienceSubmitHandler={addCustomerExperienceSubmitHandler}
    />,
    <CustomerDocument
      clientId={clientId}
      onCustomerDocumentsSubmitHandler={addCustomentDocumentsSubmitHandler}
      setCurrent={setCurrent}
    />,
  ];

  return (
    <Drawer
      title="Add Client"
      open={isAddClientModalVisible}
      onClose={() => {
        setIsAddClientModalVisible(false);
        setCustometDetails("");
        setCustomerDocuments("");
        setCustomerCourses("");
        setCustomerQualification("");
        setCustomerExperience("");
        setCustomenrInfoValues({
          ContactType: true,
          contact: "",
          contactName: "",
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          dateOfBirth: "",
          passportNumber: "",
          visaType: "",
          visaStatus: "",
          visaExpiryDate: "",
          passportExpiryDate: "",

          campaign: "",
          campaignName: "",
          phone: "",
          aboutUs: "",
          aboutUsName: "",
          referral: "",
          referralName: "",
          address: "",
          maritalStatus: "",
          sexStatus: "",
          countryOfResidence: "",
          countryName: "",
          sendByEmail: false,
        });
        setClientCourseData({
          completedIelts: false,
          completedTofel: false,
          completedGmat: false,
          completedGre: false,
          completedSat: false,
          completedPte: false,
          satIbtScore: "",
          gmatIbtScore: "",
          greIbtScore: "",
          pteSpeaking: "",
          pteWriting: "",
          pteReading: "",
          pteListening: "",
          pteScore: "",
          tofelIbtScore: "",
          speaking: "",
          writing: "",
          reading: "",
          listening: "",
          totalIeltsScore: "",
        });
        setQualification([]);
        setExperience([]);
        setCurrent(0);
      }}
      footer={null}
      width={1163}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
        >
          <Steps.Step title="Customer Info" icon={<BsFillPersonFill />}>
            Personal Info
          </Steps.Step>
          <Steps.Step title="Course" icon={<IoBookSharp />}>
            Score
          </Steps.Step>
          <Steps.Step title="Qualification" icon={<FaUserCheck />}>
            Qualification
          </Steps.Step>
          <Steps.Step title="Experience" icon={<IoIosTime />}>
            Experience
          </Steps.Step>
          <Steps.Step title="Documents" icon={<IoDocumentSharp />}>
            Documents
          </Steps.Step>
        </Steps>
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default AddClient;

const CustomerInfo = ({
  current,
  setCurrent,
  customenrInfoValues,
  setCustomenrInfoValues,
  onAddCustomerSubmitHandler,
}) => {
  const leadState = useSelector((state) => state.LeadReducer);
  const [visaSubclassList, setVisaSubclassList] = useState([]);

  useEffect(() => {
    dispatch({
      type: "FETCH_VISAS_SUBCLASS_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setVisaSubclassList(res?.data?.data);
      },
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
        visaSuccessFunction: clientSuccessFunction,
      },
    });
  }, []);
  const [isContactType, setIsContactType] = useState(true);

  const [record, setRecord] = useState({});
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddCampaignModalVisible, setIsAddCampaignModalVisible] =
    useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [form] = Form.useForm();
  const clientSuccessFunction = (response) => {
    setCustomenrInfoValues((previousData) => {
      return {
        ...previousData,
        countryOfResidence: response?.data?.defaultCountry?.name,
      };
    });
  };
  const addClientSubmitHandler = (value) => {
    const dob = value.dateOfBirth ? dateUTC(value.dateOfBirth) : null;
    const customenrInfo = {
      contact: customenrInfoValues.ContactType
        ? customenrInfoValues.contact
        : null,
      firstName: customenrInfoValues.firstName,
      lastName: customenrInfoValues.lastName,
      email: customenrInfoValues.email,
      mobile: customenrInfoValues.mobile,
      dateOfBirth: customenrInfoValues.dateOfBirth
        ? moment
            .utc(customenrInfoValues.dateOfBirth)
            .local()
            .format("YYYY-MM-DD")
        : null,
      dob: customenrInfoValues.dateOfBirth
        ? moment
            .utc(customenrInfoValues.dateOfBirth)
            .local()
            .format("YYYY-MM-DD")
        : null,
      passportNumber: customenrInfoValues.passportNumber,
      visaType:
        customenrInfoValues.visaType !== ""
          ? customenrInfoValues.visaType ?? null
          : null,
      visaStatus:
        customenrInfoValues.visaStatus !== ""
          ? customenrInfoValues.visaStatus ?? null
          : null,
      visaExpiryDate: customenrInfoValues.visaExpiryDate
        ? date(customenrInfoValues.visaExpiryDate)
        : null,
      passportExpiryDate: customenrInfoValues.passportExpiryDate
        ? date(customenrInfoValues.passportExpiryDate)
        : null,
      campaign: customenrInfoValues.campaign ?? null,
      campaignName: customenrInfoValues.campaignName,
      phone: customenrInfoValues.phone,
      aboutUs: customenrInfoValues.aboutUs,
      aboutUsName: customenrInfoValues.aboutUsName,
      referral: customenrInfoValues.referral,
      referralName: customenrInfoValues.referralName,
      address: customenrInfoValues.address,
      maritalStatus: customenrInfoValues.maritalStatus,
      sexStatus: customenrInfoValues.sexStatus,
      countryOfResidence: customenrInfoValues.countryOfResidence,
      countryName: customenrInfoValues.countryName,
      sendByEmail: customenrInfoValues.sendByEmail,
    };

    onAddCustomerSubmitHandler(customenrInfo, selectedPhoto);
  };
  return (
    <>
      <Form
        layout="vertical"
        onFinish={addClientSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["ContactType"],
            value: customenrInfoValues?.ContactType,
          },
          {
            name: ["contact"],
            value: customenrInfoValues?.contactName,
          },
          {
            name: ["firstName"],
            value: customenrInfoValues?.firstName,
          },
          {
            name: ["lastName"],
            value: customenrInfoValues?.lastName,
          },
          {
            name: ["email"],
            value: customenrInfoValues?.email,
          },
          {
            name: ["mobile"],
            value: customenrInfoValues?.mobile,
          },
          {
            name: ["dateOfBirth"],
            value: customenrInfoValues?.dateOfBirth
              ? moment.utc(customenrInfoValues?.dateOfBirth).local()
              : null,
          },
          {
            name: ["passportNumber"],
            value: customenrInfoValues?.passportNumber,
          },

          {
            name: ["visaStatus"],
            value: customenrInfoValues?.visaStatus,
          },
          {
            name: ["visaExpiryDate"],
            value: customenrInfoValues?.visaExpiryDate
              ? moment.utc(customenrInfoValues?.visaExpiryDate).local()
              : null,
          },
          {
            name: ["passportExpiryDate"],
            value: customenrInfoValues?.passportExpiryDate
              ? moment.utc(customenrInfoValues?.passportExpiryDate).local()
              : null,
          },
          {
            name: ["campaign"],
            value: customenrInfoValues?.campaignName,
          },

          {
            name: ["phone"],
            value: customenrInfoValues?.phone,
          },
          {
            name: ["referral"],
            value: customenrInfoValues?.referralName,
          },
          {
            name: ["aboutUs"],
            value: customenrInfoValues?.aboutUsName,
          },
          {
            name: ["address"],
            value: customenrInfoValues?.address,
          },
          {
            name: ["maritalStatus"],
            value: customenrInfoValues?.maritalStatus,
          },
          {
            name: ["sendByEmail"],
            value: customenrInfoValues?.sendByEmail,
          },
          {
            name: ["sexStatus"],
            value: customenrInfoValues?.sexStatus,
          },
          {
            name: ["countryOfResidence"],
            value: customenrInfoValues?.countryOfResidence,
          },
        ]}
        // initialValues={{
        //   firstName: record?.firstName,
        //   lastName: record?.lastName,
        //   email: record?.email,
        //   mobile: record?.mobile,
        //   passportNumber: record?.passportNumber,
        //   visaType: record?.visaType,
        //   visaStatus: record?.visaStatus,
        //   phone: record?.phone,
        //   aboutUsName: record?.aboutUs?.aboutUs,
        //   referralName: record?.referral?.emailId,
        //   address: record?.address,
        //   maritalStatus: record?.maritalStatus,
        //   sexStatus: record?.sexStatus,
        //   sendByEmail: record?.sendByEmail,
        // }}
      >
        <Form.Item
          name={"ContactType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select client type",
            },
          ]}
        >
          <Radio.Group
            defaultValue={true}
            onChange={(e) => {
              setCustomenrInfoValues((previousData) => {
                return {
                  ...previousData,
                  ContactType: e.target.value,
                };
              });
              setIsContactType(e.target.value);
              if (e.target.value === false) {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    contact: null,
                    contactName: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    mobile: null,
                    countryOfResidence: null,
                    campaign: null,
                    campaignName: null,
                  };
                });
                setRecord(null);
              }
            }}
          >
            <Radio value={true}>Existing Contact</Radio>
            <Radio value={false}>New Contact</Radio>
          </Radio.Group>
        </Form.Item>
        {customenrInfoValues.ContactType === true ? (
          <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
            <Form.Item label="Contact" name="contact" style={{ width: "100%" }}>
              <Select
                showSearch
                placeholder="Select a Contact"
                optionFilterProp="children"
                onSearch={(e) => {}}
                filterOption={(input, option) => {
                  return (option?.children?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {leadState?.leadsDropdown?.contactsOnly?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      <div
                        onClick={() => {
                          setRecord(dataObj);
                          setCustomenrInfoValues((previousData) => {
                            return {
                              ...previousData,
                              contact: dataObj.id,
                              contactName: `${dataObj.firstName ?? ""} ${
                                dataObj?.lastName ?? ""
                              } 
                              ${
                                dataObj.email !== null && dataObj.email !== ""
                                  ? `(${dataObj.email})`
                                  : dataObj.phoneNumber !== null &&
                                    dataObj.phoneNumber !== ""
                                  ? `(${dataObj.phoneNumber ?? ""})`
                                  : ""
                              }
                              `,
                              firstName: dataObj?.firstName,
                              lastName: dataObj?.lastName,
                              email: dataObj?.email,
                              mobile: dataObj?.phoneNumber,
                              countryOfResidence: dataObj?.countryOfResidence,
                              campaignName: dataObj?.campaign?.name,
                              campaign: dataObj?.campaign?.id,
                            };
                          });
                        }}
                        className={"selectOptionDiv"}
                      >
                        {`${dataObj.firstName ?? ""} ${dataObj?.lastName ?? ""} 
                            ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.phoneNumber !== null &&
                                  dataObj.phoneNumber !== ""
                                ? `(${dataObj.phoneNumber ?? ""})`
                                : ""
                            }
                            `}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddContactVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        ) : null}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="First Name"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                type: "email",
                message: "Please enter valid email",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const phoneValue = getFieldValue("mobile");
                  if (!value && !phoneValue) {
                    return Promise.reject(
                      "Please enter either email or mobile number"
                    );
                  }
                  return Promise.resolve();
                },
              }),
              {
                validator: async (_, value) => {
                  const token = getCookie("accessToken");
                  const response = await axios.get(
                    // `${
                    //   process.env.REACT_APP_TEST_URL
                    // }v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                    //   "branchId"
                    // )}?email=${value}`,
                    `v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                      "branchId"
                    )}?email=${value}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                      },
                    }
                  );
                  if (
                    response?.data === false ||
                    value === "" ||
                    value === null ||
                    value === undefined
                  ) {
                    return Promise.resolve();
                  } else if (
                    response?.data === true &&
                    isContactType === false
                  ) {
                    return Promise.reject(
                      new Error("Entered email already exists")
                    );
                  }
                },
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <Input
              type="email"
              placeholder="E.g. name@domainname.com"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const emailValue = getFieldValue("email");
                  if (!value && !emailValue) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),

              {
                validator: async (_, value) => {
                  const token = getCookie("accessToken");
                  const response = await axios.get(
                    // `${
                    //   process.env.REACT_APP_TEST_URL
                    // }v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                    //   "branchId"
                    // )}?mobile=${value}`,
                    `v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                      "branchId"
                    )}?mobile=${value}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                      },
                    }
                  );
                  if (
                    response?.data === false ||
                    value === "" ||
                    value === null ||
                    value === undefined
                  ) {
                    return Promise.resolve();
                  } else if (
                    response?.data === true &&
                    isContactType === false
                  ) {
                    return Promise.reject(
                      new Error("Entered mobile number already exists")
                    );
                  }
                },
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <Input
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    mobile: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Date of Birth"
            name={"dateOfBirth"}
            style={{ width: "100%" }}
            validateStatus={error ? "error" : undefined}
            help={error}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please pick birth date!",
            //     },
            //   ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate > date;
              }}
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    dateOfBirth: e,
                  };
                });
                if (e) {
                  const age = moment().diff(e, "years");
                  if (age < 18) {
                    setError("Client must be above 18 years old.");
                    form.setFields([
                      {
                        name: "dateOfBirth",
                        errors: ["You must be at least 18 years old."],
                      },
                    ]);
                  } else {
                    setError("");
                    form.setFields([
                      {
                        name: "dateOfBirth",
                        errors: [],
                      },
                    ]);
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Passport Number"
            name={"passportNumber"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please enter a valid passport number !",
            //     },
            //   ]}
          >
            <Input
              type="number"
              placeholder="passport number"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    passportNumber: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Visa Type"
            name={"visaType"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick birth date!",
            //   },
            // ]}
          >
            {/* <Input
              placeholder="Visa Type"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    visaType: e.target.value,
                  };
                });
              }}
            /> */}
            <Select placeholder="Select Visa Type">
              {visaSubclassList?.map((value) => (
                <Option value={value?.subClass}>{value?.subClass}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Visa Status"
            name={"visaStatus"}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Visa Status"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    visaStatus: e,
                  };
                });
              }}
            >
              <Option value="IN_PROGRESS">In Progress</Option>
              <Option value="EXPIRED">Expired</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "flex-start", gap: "1rem", width: "100%" }}
          >
            <Form.Item
              label="Visa Expiry Date"
              name={"visaExpiryDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={(e) => {
                  setCustomenrInfoValues((previousData) => {
                    return {
                      ...previousData,
                      visaExpiryDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Passport Expiry Date"
              name={"passportExpiryDate"}
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD-MM-YYYY"
                onChange={(e) => {
                  setCustomenrInfoValues((previousData) => {
                    return {
                      ...previousData,
                      passportExpiryDate: e,
                    };
                  });
                }}
              />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Campaign"
              name={"campaign"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter campaign!",
              //   },
              // ]}
            >
              <Select>
                {leadState.leadsDropdown?.campaign?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      <div
                        onClick={() => {
                          setCustomenrInfoValues((previousData) => {
                            return {
                              ...previousData,
                              campaign: dataObj.id,
                              campaignName: dataObj.campaignName,
                            };
                          });
                        }}
                      >
                        {dataObj.campaignName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCampaignModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Phone Number"
            name={"phone"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Phone number should contain 9 to 11 numbers",
              },
            ]}
          >
            <Input
              placeholder="Phone Number"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    phone: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Referral"
              name={"referral"}
              style={{ width: "100%" }}
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please select a referral !",
              //     },
              //   ]}
            >
              <Select>
                {leadState.leadsDropdown?.references?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      <div
                        onClick={(e) => {
                          setCustomenrInfoValues((previousData) => {
                            return {
                              ...previousData,
                              referral: dataObj.id,
                              referralName: `${dataObj.firstName} ${dataObj.lastName}`,
                            };
                          });
                        }}
                      >
                        {dataObj.firstName} {dataObj.lastName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddReferralModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="About Us"
              name={"aboutUs"}
              style={{ width: "100%" }}
            >
              <Select>
                {leadState.leadsDropdown?.aboutUs?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id}>
                      <div
                        onClick={(e) => {
                          setCustomenrInfoValues((previousData) => {
                            return {
                              ...previousData,
                              aboutUs: dataObj.id,
                              aboutUsName: dataObj.aboutUs,
                            };
                          });
                        }}
                      >
                        {dataObj.aboutUs}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddAboutUsModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Address"
            name={"address"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please enter a address !",
            //     },
            //   ]}
          >
            <Input
              placeholder="address"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    address: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Gender"
            name={"sexStatus"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please select sex status!",
            //     },
            //   ]}
          >
            <Select
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    sexStatus: e,
                  };
                });
              }}
            >
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHERS">Others</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Marital Status"
            name={"maritalStatus"}
            style={{ width: "100%" }}
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please select a maritalStatus!",
            //     },
            //   ]}
          >
            <Select
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    maritalStatus: e,
                  };
                });
              }}
            >
              <Option value="SINGLE">Single</Option>
              <Option value="MARRIED">Married</Option>
              <Option value="DIVORCED">Divorced</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "flex-start", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country of Residence"
              name={"countryOfResidence"}
              style={{ width: "100%" }}
            >
              <Select
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) => {
                  return (option?.children?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {leadState.leadsDropdown?.countries?.map((dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      <div
                        onClick={(e) => {
                          setCustomenrInfoValues((previousData) => {
                            return {
                              ...previousData,
                              country: dataObj.id,
                              countryOfResidence: dataObj.countryName,
                            };
                          });
                        }}
                      >
                        {dataObj.countryName}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Photo"
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter photo!",
            //   },
            // ]}
          >
            <input
              type={"file"}
              placeholder="photo"
              onChange={(e) => {
                setSelectedPhoto(e.target.files[0]);
              }}
            />
          </Form.Item>
        </div>
        {customenrInfoValues.email === "" ||
          (customenrInfoValues.email === null && (
            <Form.Item
              name={"sendByEmail"}
              valuePropName="checked"
              onChange={(e) => {
                setCustomenrInfoValues((previousData) => {
                  return {
                    ...previousData,
                    sendByEmail: e.target.checked,
                  };
                });
              }}
            >
              <Checkbox>Send Email/SMS</Checkbox>
            </Form.Item>
          ))}
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "flex-end",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          <Form.Item
            name="clientInfoNext"
            // rules={[
            //   ({ getFieldValue }) => ({
            //     validator(_, value) {
            //       if (
            //         (getFieldValue("email") === undefined ||
            //           getFieldValue("email") === "" ||
            //           getFieldValue("email") === null) &&
            //         (getFieldValue("mobile") === undefined ||
            //           getFieldValue("mobile") === "" ||
            //           getFieldValue("mobile") === null)
            //       ) {
            //         return Promise.reject(
            //           new Error("Please enter either email or mobile number!")
            //         );
            //       } else {
            //         return Promise.resolve();
            //       }
            //     },
            //   }),
            // ]}
            // hasFeedback
          >
            <Button name="clientInfoNext" type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>

      {isAddReferralModalVisible && (
        <AddReferral
          isAddReferralModalVisible={isAddReferralModalVisible}
          setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
      {isAddAboutUsModalVisible && (
        <AddAboutUs
          isAddAboutUsModalVisible={isAddAboutUsModalVisible}
          setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
        />
      )}
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddContactVisible && (
        <AddContacts
          fromClient={true}
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
        />
      )}
      {isAddCampaignModalVisible && (
        <AddCampaign
          // isHeadBranch={isHeadBranch}
          // branchRecord={branchRecord}
          isAddCampaignModalVisible={isAddCampaignModalVisible}
          setIsAddCampaignModalVisible={setIsAddCampaignModalVisible}
        />
      )}
    </>
  );
};

const CustomerDocument = ({
  clientId,
  onCustomerDocumentsSubmitHandler,
  setCurrent,
}) => {
  const documentChecklistState = useSelector(
    (state) => state?.DocumentCheckListReducer
  );

  const documentState = useSelector((state) => state.ClientsReducer);
  const [selectedDocument, setSelectedDocument] = useState();
  const dispatch = useDispatch({});
  useEffect(() => {
    dispatch({ type: "FETCH_DOCUMENT_TYPES_REQUEST" });
  }, []);

  useEffect(() => {
    handleGetDocumentChecklist();
  }, []);

  const handleGetDocumentChecklist = () => {
    dispatch({
      type: "FETCH_DOCUMENT_CHECKLIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const [isAddDocumentTypeModalVisible, setIsAddDocumentTypeModalVisible] =
    useState(false);
  const addClientDocumentsSubmitHandler = (value) => {
    const documentFormData = new FormData();
    documentFormData.append("document", value.documentType);
    documentFormData.append("description", value.description);
    documentFormData.append("document", selectedDocument);
    onCustomerDocumentsSubmitHandler(documentFormData);
  };

  const [documents, setDocuments] = useState([]);
  const addDocumentsInput = () => {
    setDocuments([
      ...documents,
      {
        documentType: "",
        documents: {},
        description: "",
      },
    ]);
  };
  const removeDocumentInput = (index) => {
    const list = [...documents];
    list.splice(index, 1);
    setDocuments(list);
  };
  const handleDocumentTypeChange = (e, index) => {
    const list = [...documents];
    list[index].documentType = e;
    setDocuments(list);
  };
  const handleDocumentsChange = (e, index) => {
    const list = [...documents];
    list[0].documents = selectedDocument ?? e;
    setDocuments(list);
  };
  const handleDescriptionChange = (e, index) => {
    const list = [...documents];
    list[index].description = e.target.value;
    setDocuments(list);
  };
  const uploadDocumentHandler = (index) => {
    if (clientId) {
      const documentsFormData = new FormData();
      documentsFormData.append("documentType", documents[index].documentType);
      documentsFormData.append("document", documents[index].documents);
      documentsFormData.append("description", documents[index].description);

      dispatch({
        type: "ADD_CLIENT_DOCUMENT_REQUEST",
        payload: { id: clientId, documentsFormData },
      });
    } else {
      message.warn("Please Add Client First");

      setTimeout(() => {
        setCurrent(0);
      }, 1000);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={addClientDocumentsSubmitHandler}
      colon={true}
      className="drawerStyle"
    >
      {documents.map((singleDocument, index) => {
        return (
          <div key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Document Type"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please pick document type!",
                      },
                    ]}
                  >
                    <Select
                      name={"documentType"}
                      placeholder="Document Type"
                      onChange={(e) => {
                        handleDocumentTypeChange(e, index);
                      }}
                      // value={singleDocument[index].documentType}
                    >
                      {/* {documentState.documentTypes?.data?.map((dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            {dataObj.documentType}
                          </Option>
                        );
                      })} */}
                      {documentChecklistState?.documentCheclistData?.data?.map(
                        (dataObj) => {
                          return (
                            <Option key={dataObj.id}>{dataObj.name}</Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      setIsAddDocumentTypeModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
                <Form.Item
                  label="Document"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please upload the document!",
                    },
                  ]}
                >
                  <input
                    type="file"
                    name={"documents"}
                    placeholder="document"
                    onChange={(e) => {
                      setSelectedDocument(() => e.target.files[0]);
                      handleDocumentsChange(e.target.files[0], index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Description"
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     type: "number",
                  //     message: "Please pick documents!",
                  //   },
                  // ]}
                >
                  <TextArea
                    name={"description"}
                    onChange={(e) => {
                      handleDescriptionChange(e, index);
                    }}
                    // value={singleDocument[index].description}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      uploadDocumentHandler(index);
                    }}
                  >
                    Upload
                  </Button>
                </Form.Item>
              </Form>
              <Button
                color="red"
                onClick={() => removeDocumentInput(index)}
                style={{
                  padding: "0",
                  borderRadius: "50%",
                  height: "1.5rem",
                  width: "1.5rem",
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        );
      })}
      <Button
        block
        type="dashed"
        onClick={addDocumentsInput}
        style={{ marginBottom: "1rem" }}
      >
        <PlusOutlined />
        Add Documents
      </Button>

      {isAddDocumentTypeModalVisible && (
        <AddDocumentTypes
          isAddDocumentTypesModalVisible={isAddDocumentTypeModalVisible}
          setIsAddDocumentTypesModalVisible={setIsAddDocumentTypeModalVisible}
        />
      )}
    </Form>
  );
};
const CustomerCourses = ({
  current,
  setCurrent,
  clientCourseData,
  setClientCourseData,
  onCustomerCoursesSubmitHandler,
}) => {
  // const [isCompletedIELTS, setIsCompletedIELTS] = useState(false);
  // const [isCompletedTofel, setIsCompletedTofel] = useState(false);
  // const [isCompletedPTE, setIsCompletedPTE] = useState(false);
  // const [isCompletedSAT, setIsCompletedSAT] = useState(false);
  // const [isCompletedGRE, setIsCompletedGRE] = useState(false);
  // const [isCompletedGMAT, setIsCompletedGMAT] = useState(false);

  const addClientCoursesSubmitHandler = (value) => {
    const formData = {
      completedIelts: clientCourseData?.completedIelts,
      completedTofel: clientCourseData?.completedTofel,
      completedGmat: clientCourseData?.completedGmat,
      completedGre: clientCourseData?.completedGre,
      completedSat: clientCourseData?.completedSat,
      completedPte: clientCourseData?.completedPte,
      satIbtScore: clientCourseData?.completedSat
        ? clientCourseData?.satIbtScore
        : null,
      gmatIbtScore: clientCourseData?.completedGmat
        ? clientCourseData?.gmatIbtScore
        : null,
      greIbtScore: clientCourseData?.completedGre
        ? clientCourseData?.greIbtScore
        : null,
      pteSpeaking: clientCourseData?.completedPte
        ? clientCourseData?.pteSpeaking
        : null,
      pteWriting: clientCourseData?.completedPte
        ? clientCourseData?.pteWriting
        : null,
      pteReading: clientCourseData?.completedPte
        ? clientCourseData?.pteReading
        : null,
      pteListening: clientCourseData?.completedPte
        ? clientCourseData?.pteListening
        : null,
      pteScore: clientCourseData?.completedPte
        ? clientCourseData?.pteScore
        : null,
      tofelIbtScore: clientCourseData?.completedTofel
        ? clientCourseData?.tofelIbtScore
        : null,
      speaking: clientCourseData?.completedIelts
        ? clientCourseData?.speaking
        : null,
      writing: clientCourseData?.completedIelts
        ? clientCourseData?.writing
        : null,
      reading: clientCourseData?.completedIelts
        ? clientCourseData?.reading
        : null,
      listening: clientCourseData?.completedIelts
        ? clientCourseData?.listening
        : null,
      totalIeltsScore: clientCourseData?.completedIelts
        ? clientCourseData?.totalIeltsScore
        : null,
    };

    onCustomerCoursesSubmitHandler(formData);
  };
  return (
    <Form
      layout="vertical"
      onFinish={addClientCoursesSubmitHandler}
      onFinishFailed={scrollToTopFormError}
      colon={true}
      className="drawerStyle"
      fields={[
        {
          name: ["completedIelts"],
          value: clientCourseData?.completedIelts,
        },
        {
          name: ["completedTofel"],
          value: clientCourseData?.completedTofel,
        },
        {
          name: ["completedGmat"],
          value: clientCourseData?.completedGmat,
        },
        {
          name: ["completedGre"],
          value: clientCourseData?.completedGre,
        },
        {
          name: ["completedSat"],
          value: clientCourseData?.completedSat,
        },
        {
          name: ["greIbtScore"],
          value: clientCourseData?.greIbtScore,
        },
        {
          name: ["completedPte"],
          value: clientCourseData?.completedPte,
        },
        {
          name: ["totalIeltsScore"],
          value: clientCourseData?.totalIeltsScore,
        },
        {
          name: ["listening"],
          value: clientCourseData?.listening,
        },
        {
          name: ["reading"],
          value: clientCourseData?.reading,
        },
        {
          name: ["writing"],
          value: clientCourseData?.writing,
        },
        {
          name: ["speaking"],
          value: clientCourseData?.speaking,
        },
        {
          name: ["tofelIbtScore"],
          value: clientCourseData?.tofelIbtScore,
        },
        {
          name: ["pteScore"],
          value: clientCourseData?.pteScore,
        },
        {
          name: ["pteListening"],
          value: clientCourseData?.pteListening,
        },
        {
          name: ["pteReading"],
          value: clientCourseData?.pteReading,
        },
        {
          name: ["pteWriting"],
          value: clientCourseData?.pteWriting,
        },
        {
          name: ["pteSpeaking"],
          value: clientCourseData?.pteSpeaking,
        },
        {
          name: ["gmatIbtScore"],
          value: clientCourseData?.gmatIbtScore,
        },
        {
          name: ["satIbtScore"],
          value: clientCourseData?.satIbtScore,
        },
      ]}
      initialValues={{
        completedIelts: false,
        completedTofel: false,
        completedGmat: false,
        completedGre: false,
        completedSat: false,
        completedPte: false,
      }}
    >
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        <Form.Item
          label="Completed IELTS?"
          name={"completedIelts"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter ielts score!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setClientCourseData((previousData) => {
                return {
                  ...previousData,
                  completedIelts: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {clientCourseData.completedIelts ? (
          <>
            <Form.Item
              label="IELTS score"
              name={"totalIeltsScore"}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="IELTS Score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      totalIeltsScore: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Listening"
              name={"listening"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter listening score!",
                },
              ]}
            >
              <Input
                placeholder="listening"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      listening: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Reading"
              name={"reading"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter reading score!",
                },
              ]}
            >
              <Input
                placeholder="writing score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      reading: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Writing"
              name={"writing"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter writing score!",
                },
              ]}
            >
              <Input
                placeholder="writing score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      writing: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Speaking"
              name={"speaking"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter speaking score!",
                },
              ]}
            >
              <Input
                placeholder="speaking score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      speaking: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </div>

      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        <Form.Item
          label="Completed TOFEL?"
          name={"completedTofel"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter completedTofel!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setClientCourseData((previousData) => {
                return {
                  ...previousData,
                  completedTofel: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {clientCourseData.completedTofel ? (
          <>
            <Form.Item
              label="TOFEL IBT Score?"
              name={"tofelIbtScore"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter tofelIbtScore!",
                },
              ]}
            >
              <Input
                placeholder="tofelIbtScore"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      tofelIbtScore: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        <Form.Item
          label="Completed PTE?"
          name={"completedPte"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter completedPte!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setClientCourseData((previousData) => {
                return {
                  ...previousData,
                  completedPte: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {clientCourseData.completedPte ? (
          <>
            <Form.Item
              label="PTE Score"
              name={"pteScore"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter pteScore!",
                },
              ]}
            >
              <Input
                placeholder="pteScore"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      pteScore: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="PTE Listening"
              name={"pteListening"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter pte listening score!",
                },
              ]}
            >
              <Input
                placeholder="pte listening score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      pteListening: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="PTE Reading"
              name={"pteReading"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter pte reading score!",
                },
              ]}
            >
              <Input
                placeholder="pte reading score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      pteReading: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="PTE Writing"
              name={"pteWriting"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter PTE Writing score!",
                },
              ]}
            >
              <Input
                placeholder="pte Writing score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      pteWriting: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="PTE Speaking"
              name={"pteSpeaking"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter pteSpeaking!",
                },
              ]}
            >
              <Input
                placeholder="pte Speaking score"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      pteSpeaking: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        <Form.Item
          label="Completed SAT?"
          name={"completedSat"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter completedSat!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setClientCourseData((previousData) => {
                return {
                  ...previousData,
                  completedSat: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {clientCourseData.completedSat ? (
          <>
            <Form.Item
              label="SAT IBT Score"
              name={"satIbtScore"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter satIbtScore!",
                },
              ]}
            >
              <Input
                placeholder="satIbtScore"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      satIbtScore: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        <Form.Item
          label="Completed GRE?"
          name={"completedGre"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter completedGre!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setClientCourseData((previousData) => {
                return {
                  ...previousData,
                  completedGre: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {clientCourseData.completedGre ? (
          <>
            <Form.Item
              label="GRE IBT Score"
              name={"greIbtScore"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter greIbtScore!",
                },
              ]}
            >
              <Input
                placeholder="greIbtScore"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      greIbtScore: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        <Form.Item
          label="Completed Gmat?"
          name={"completedGmat"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter completedGmat!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setClientCourseData((previousData) => {
                return {
                  ...previousData,
                  completedGmat: e.target.value,
                };
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {clientCourseData.completedGmat ? (
          <>
            <Form.Item
              label="GMAT IBT Score"
              name={"gmatIbtScore"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter gmatIbtScore!",
                },
              ]}
            >
              <Input
                placeholder="gmatIbtScore"
                type="number"
                onChange={(e) => {
                  setClientCourseData((previousData) => {
                    return {
                      ...previousData,
                      gmatIbtScore: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        {current === 1 && (
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setCurrent(0);
              }}
            >
              Back
            </Button>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

const CustomerQualification = ({
  current,
  setCurrent,
  clientId,
  qualification,
  setQualification,
  onCustomerQualificationSubmitHandler,
}) => {
  const dispatch = useDispatch();
  const leadState = useSelector((state) => state.LeadReducer);
  useEffect(() => {
    dispatch({ type: '"FETCH_LEADS_DROPDOWN_REQUEST"' });
  }, []);
  const addClientQualificationSubmitHandler = (value) => {
    onCustomerQualificationSubmitHandler(qualification);
  };

  const removeQualificationdInput = (index) => {
    const list = [...qualification];
    list.splice(index, 1);
    setQualification(list);
  };
  const addQualificationInput = () => {
    setQualification([
      ...qualification,
      {
        qualification: "",
        institution: "",
        country: "",
        yearsOfCommencement: "",
        yearsOfCompletion: "",
        percentage: "",
        documentsAttached: "",
      },
    ]);
  };
  const handleQualificationChange = (e, index) => {
    const list = [...qualification];
    list[index].qualification = e.target.value;
    setQualification(list);
  };
  const handleInstitutionChange = (e, index) => {
    const list = [...qualification];
    list[index].institution = e.target.value;
    setQualification(list);
  };
  const handleCountryChange = (e, index) => {
    const list = [...qualification];
    list[index].country = e;
    setQualification(list);
  };
  const handleYearsOfCommencementChange = (e, index) => {
    const list = [...qualification];
    list[index].yearsOfCommencement = moment.utc(e).local().format("YYYY");
    setQualification(list);
  };
  const handleYearsOfCompletionChange = (e, index) => {
    const list = [...qualification];
    list[index].yearsOfCompletion = moment.utc(e).local().format("YYYY");
    setQualification(list);
  };
  const handlePercentageChange = (e, index) => {
    const list = [...qualification];
    list[index].percentage = e.target.value;
    setQualification(list);
  };
  const handledocumentsAttachedChange = (e, index) => {
    const list = [...qualification];
    list[index].documentsAttached = e;
    setQualification(list);
  };
  return (
    <Form
      layout="vertical"
      onFinish={addClientQualificationSubmitHandler}
      colon={true}
      className="drawerStyle"
    >
      {qualification.map((singleQualification, index) => {
        return (
          <div key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  label="Qualification"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter qualification!",
                    },
                  ]}
                >
                  <Input
                    name={"qualification"}
                    value={qualification[index].qualification}
                    placeholder="qualification"
                    onChange={(e) => {
                      handleQualificationChange(e, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Institution"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter institution!",
                    },
                  ]}
                >
                  <Input
                    name={"institution"}
                    placeholder="institution"
                    value={qualification[index].institution}
                    onChange={(e) => {
                      handleInstitutionChange(e, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Country"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter country!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Country"
                    value={qualification[index].country}
                    onChange={(e) => {
                      handleCountryChange(e.target.value, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Commencement Year"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter commencement year!",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format={"YYYY"}
                    name={"yearsOfCommencement"}
                    placeholder="Commencement year"
                    value={
                      qualification[index].yearsOfCommencement
                        ? moment
                            .utc(qualification[index].yearsOfCommencement)
                            .local()
                        : ""
                    }
                    onChange={(e) => {
                      if (e) {
                        handleYearsOfCommencementChange(e, index);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Completion Year"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Completion year!",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    name={"yearsOfCompletion"}
                    value={
                      qualification[index].yearsOfCompletion
                        ? moment
                            .utc(qualification[index].yearsOfCompletion)
                            .local()
                        : ""
                    }
                    placeholder="Completion Year"
                    onChange={(e) => {
                      if (e) {
                        handleYearsOfCompletionChange(e, index);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Percentage/Grade"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter percentage!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    name={"percentage"}
                    value={qualification[index].percentage}
                    placeholder="percentage"
                    onChange={(e) => {
                      handlePercentageChange(e, index);
                    }}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="Document Attachment"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Checkbox
                    name={"documentsAttached"}
                    onChange={(e) => {
                   
                      handledocumentsAttachedChange(e.target.checked, index);
                    }}
                  ></Checkbox>
                </Form.Item> */}
              </div>
              <Button
                color="red"
                onClick={() => removeQualificationdInput(index)}
                style={{
                  padding: "0",
                  borderRadius: "50%",
                  height: "1.5rem",
                  width: "1.5rem",
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        );
      })}
      <Button
        block
        type="dashed"
        onClick={addQualificationInput}
        style={{ marginBottom: "1rem" }}
      >
        <PlusOutlined />
        Add Qualification
      </Button>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        {current === 2 && (
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setCurrent(1);
              }}
            >
              Back
            </Button>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

const CustomerExperience = ({
  current,
  setCurrent,
  experience,
  setExperience,
  onCustomerExperienceSubmitHandler,
}) => {
  const leadState = useSelector((state) => state.LeadReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: '"FETCH_LEADS_DROPDOWN_REQUEST"' });
  }, []);

  const addClientExperienceSubmitHandler = (value) => {
    onCustomerExperienceSubmitHandler(experience);
  };

  const removeExperienceInput = (index) => {
    const list = [...experience];
    list.splice(index, 1);
    setExperience(list);
  };
  const addExperienceInput = () => {
    setExperience([
      ...experience,
      {
        experience: "",
        institution: "",
        country: "",
        yearsOfCommencement: "",
        yearsOfCompletion: "",
        documentsAttached: "",
      },
    ]);
  };
  const handleExperienceChange = (e, index) => {
    const list = [...experience];
    list[index].experience = e.target.value;
    setExperience(list);
  };
  const handleInstitutionChange = (e, index) => {
    const list = [...experience];
    list[index].institution = e.target.value;
    setExperience(list);
  };
  const handlePositionChange = (e, index) => {
    const list = [...experience];
    list[index].position = e.target.value;
    setExperience(list);
  };
  const handleCountryChange = (e, index) => {
    const list = [...experience];
    list[index].country = e;
    setExperience(list);
  };
  const handleYearsOfCommencementChange = (e, index) => {
    const list = [...experience];
    list[index].yearsOfCommencement = moment(e).format("YYYY");
    setExperience(list);
  };
  const handleYearsOfCompletionChange = (e, index) => {
    const list = [...experience];
    list[index].yearsOfCompletion = moment(e).format("YYYY");
    setExperience(list);
  };

  const handledocumentsAttachedChange = (e, index) => {
    const list = [...experience];
    list[index].documentsAttached = e;
    setExperience(list);
  };

  return (
    <Form
      layout="vertical"
      onFinish={addClientExperienceSubmitHandler}
      colon={true}
      className="drawerStyle"
    >
      {experience.map((singleExperiece, index) => {
        return (
          <div key={index}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  gap: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  label="Experience"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter experience!",
                    },
                  ]}
                >
                  <Input
                    name={"experience"}
                    value={experience[index].experience}
                    placeholder="experience"
                    onChange={(e) => {
                      handleExperienceChange(e, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Institution"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter institution!",
                    },
                  ]}
                >
                  <Input
                    name={"institution"}
                    value={experience[index].institution}
                    placeholder="institution"
                    onChange={(e) => {
                      handleInstitutionChange(e, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Position"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter position!",
                    },
                  ]}
                >
                  <Input
                    name={"position"}
                    placeholder="position"
                    value={experience[index].position}
                    onChange={(e) => {
                      handlePositionChange(e, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Country"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter country!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Country"
                    value={experience[index].country}
                    onChange={(e) => {
                      handleCountryChange(e.target.value, index);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Commencement Year"
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter yearsOfCommencement!",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    name={"yearsOfCommencement"}
                    value={
                      experience[index].yearsOfCommencement
                        ? moment
                            .utc(experience[index].yearsOfCommencement)
                            .local()
                        : ""
                    }
                    placeholder="yearsOfCommencement"
                    onChange={(e) => {
                      if (e) {
                        handleYearsOfCommencementChange(e, index);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Completion Year"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter years of completion!",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    value={
                      experience[index].yearsOfCompletion
                        ? moment
                            .utc(experience[index].yearsOfCompletion)
                            .local()
                        : ""
                    }
                    name={"yearsOfCompletion"}
                    placeholder="yearsOfCompletion"
                    onChange={(e) => {
                      if (e) {
                        handleYearsOfCompletionChange(e, index);
                      }
                    }}
                  />
                </Form.Item>
                {/* <Form.Item label="Document Attached" style={{ width: "100%" }}>
                  <Checkbox
                    name={"documentsAttached"}
                    onChange={(e) => {
                 
                      handledocumentsAttachedChange(e.target.checked, index);
                    }}
                  ></Checkbox>
                </Form.Item> */}
              </div>
              <Button
                color="red"
                onClick={() => removeExperienceInput(index)}
                style={{
                  padding: "0",
                  borderRadius: "50%",
                  height: "1.5rem",
                  width: "1.5rem",
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        );
      })}
      <Button
        block
        type="dashed"
        onClick={addExperienceInput}
        style={{ marginBottom: "1rem" }}
      >
        <PlusOutlined />
        Add Experience
      </Button>
      <div
        className="flexRowWithoutStyle"
        style={{ justifyContent: "space-between", gap: "1rem" }}
      >
        {current === 3 && (
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setCurrent(2);
              }}
            >
              Back
            </Button>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" block htmlType="submit">
            Save and proceed to add Documents
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

{
  /* <Form
        layout="vertical"
        onFinish={addClientSubmitHandler}
        colon={true}
        form={form}
        className="drawerStyle"
      >
       
      </Form> */
}

{
  /* <div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="firstName"
  name={"firstName"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter firstName!",
    },
  ]}
>
  <Input placeholder="firstName" />
</Form.Item>
<Form.Item
  label="lastName"
  name={"lastName"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter lastName!",
    },
  ]}
>
  <Input placeholder="lastName" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="email"
  name={"email"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      type: "email",
      message: "Please enter valid email",
    },
  ]}
>
  <Input type="email" placeholder="E.g. name@domainname.com" />
</Form.Item>
<Form.Item
  label="mobile"
  name={"mobile"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      type: "number",
      message: "Please enter mobile",
    },
  ]}
>
  <Input type="number" placeholder="Mobile Number" />
</Form.Item>
</div>

<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="dob"
  name={"dob"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please pick birth date!",
    },
  ]}
>
  <DatePicker />
</Form.Item>
<Form.Item
  label="passportNumber"
  name={"passportNumber"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      type: "number",
      message: "Please enter a valid passportNumber !",
    },
  ]}
>
  <Input type="number" placeholder="passportNumber" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="phone"
  name={"phone"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter phone!",
    },
  ]}
>
  <Input placeholder="phone" />
</Form.Item>
<Form.Item
  label="referral"
  name={"referral"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please select a referral !",
    },
  ]}
>
  <Select>
    <Option value="1">refferal</Option>
  </Select>
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="aboutUs"
  name={"aboutUs"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please select option!",
    },
  ]}
>
  <Select>
    <Option value="1">About Us</Option>
  </Select>
</Form.Item>
<Form.Item
  label="address"
  name={"address"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter a address !",
    },
  ]}
>
  <Input placeholder="address" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="sexStatus"
  name={"sexStatus"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please select sex status!",
    },
  ]}
>
  <Select>
    <Option value="MALE">Male</Option>
    <Option value="FEMALE">Female</Option>
  </Select>
</Form.Item>
<Form.Item
  label="maritalStatus"
  name={"maritalStatus"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please select a maritalStatus!",
    },
  ]}
>
  <Select>
    <Option value="SINGLE">Single</Option>
    <Option value="MARRIED">Married</Option>
  </Select>
</Form.Item>
</div>

<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="photo"
  name={"photo"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter photo!",
    },
  ]}
>
  <input type={"file"} placeholder="photo" />
</Form.Item>
<Form.Item
  label="documents"
  name={"documents"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      type: "number",
      message: "Please pick documents!",
    },
  ]}
>
  <input type="file" placeholder="documents" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="completedIelts"
  name={"completedIelts"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter phone!",
    },
  ]}
>
  <Radio.Group>
    <Radio value={true}>Yes</Radio>
    <Radio value={false}>No</Radio>
  </Radio.Group>
</Form.Item>
<Form.Item
  label="listening"
  name={"listening"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter listening!",
    },
  ]}
>
  <Input placeholder="listening" />
</Form.Item>
<Form.Item
  label="reading"
  name={"reading"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter reading!",
    },
  ]}
>
  <Input placeholder="writing" />
</Form.Item>
<Form.Item
  label="writing"
  name={"writing"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter writing!",
    },
  ]}
>
  <Input placeholder="writing" />
</Form.Item>
<Form.Item
  label="speaking"
  name={"speaking"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter speaking!",
    },
  ]}
>
  <Input placeholder="speaking" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="completedTofel"
  name={"completedTofel"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter completedTofel!",
    },
  ]}
>
  <Radio.Group>
    <Radio value={true}>Yes</Radio>
    <Radio value={false}>No</Radio>
  </Radio.Group>
</Form.Item>
<Form.Item
  label="tofelIbtScore"
  name={"tofelIbtScore"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter tofelIbtScore!",
    },
  ]}
>
  <Input placeholder="tofelIbtScore" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="completedPte"
  name={"completedPte"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter completedPte!",
    },
  ]}
>
  <Radio.Group>
    <Radio value={true}>Yes</Radio>
    <Radio value={false}>No</Radio>
  </Radio.Group>
</Form.Item>
<Form.Item
  label="pteScore"
  name={"pteScore"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter pteScore!",
    },
  ]}
>
  <Input placeholder="pteScore" />
</Form.Item>
<Form.Item
  label="pteListening"
  name={"pteListening"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter pteListening!",
    },
  ]}
>
  <Input placeholder="pteListening" />
</Form.Item>
<Form.Item
  label="pteReading"
  name={"pteReading"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter pteReading!",
    },
  ]}
>
  <Input placeholder="pteReading" />
</Form.Item>
<Form.Item
  label="pteWriting"
  name={"pteWriting"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter pteWriting!",
    },
  ]}
>
  <Input placeholder="pteWriting" />
</Form.Item>
<Form.Item
  label="pteSpeaking"
  name={"pteSpeaking"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter pteSpeaking!",
    },
  ]}
>
  <Input placeholder="pteSpeaking" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="completedSat"
  name={"completedSat"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter completedSat!",
    },
  ]}
>
  <Radio.Group>
    <Radio value={true}>Yes</Radio>
    <Radio value={false}>No</Radio>
  </Radio.Group>
</Form.Item>
<Form.Item
  label="satIbtScore"
  name={"satIbtScore"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter satIbtScore!",
    },
  ]}
>
  <Input placeholder="satIbtScore" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="completedGre"
  name={"completedGre"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter completedGre!",
    },
  ]}
>
  <Radio.Group>
    <Radio value={true}>Yes</Radio>
    <Radio value={false}>No</Radio>
  </Radio.Group>
</Form.Item>
<Form.Item
  label="greIbtScore"
  name={"greIbtScore"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter greIbtScore!",
    },
  ]}
>
  <Input placeholder="greIbtScore" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="completedGmat"
  name={"completedGmat"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter completedGmat!",
    },
  ]}
>
  <Radio.Group>
    <Radio value={true}>Yes</Radio>
    <Radio value={false}>No</Radio>
  </Radio.Group>
</Form.Item>
<Form.Item
  label="gmatIbtScore"
  name={"gmatIbtScore"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter gmatIbtScore!",
    },
  ]}
>
  <Input placeholder="gmatIbtScore" />
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="Experience"
  name={"qualification"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter qualification!",
    },
  ]}
>
  <Input placeholder="qualification" />
</Form.Item>
<Form.Item
  label="institution"
  name={"institution"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter institution!",
    },
  ]}
>
  <Input placeholder="institution" />
</Form.Item>
<Form.Item
  label="country"
  name={"country"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter country!",
    },
  ]}
>
  <Input placeholder="country" />
</Form.Item>
<Form.Item
  label="yearsOfCommencement"
  name={"yearsOfCommencement"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter yearsOfCommencement!",
    },
  ]}
>
  <Input placeholder="yearsOfCommencement" />
</Form.Item>
<Form.Item
  label="yearsOfCompletion"
  name={"yearsOfCompletion"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter yearsOfCompletion!",
    },
  ]}
>
  <Input placeholder="yearsOfCompletion" />
</Form.Item>
<Form.Item
  label="percentage"
  name={"percentage"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter percentage!",
    },
  ]}
>
  <Input placeholder="percentage" />
</Form.Item>
<Form.Item
  label="documentsAttached"
  name={"documentsAttached"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter documentsAttached!",
    },
  ]}
>
  <Checkbox
    onChange={(e) => {
    }}
  >
    Checkbox
  </Checkbox>
</Form.Item>
</div>
<div
className="flexRowWithoutStyle"
style={{ justifyContent: "space-between", gap: "1rem" }}
>
<Form.Item
  label="experience"
  name={"experience"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter experience!",
    },
  ]}
>
  <Input placeholder="experience" />
</Form.Item>
<Form.Item
  label="institution"
  name={"institution"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter institution!",
    },
  ]}
>
  <Input placeholder="institution" />
</Form.Item>
<Form.Item
  label="position"
  name={"position"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter position!",
    },
  ]}
>
  <Input placeholder="position" />
</Form.Item>
<Form.Item
  label="country"
  name={"country"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter country!",
    },
  ]}
>
  <Input placeholder="country" />
</Form.Item>
<Form.Item
  label="yearsOfCommencement"
  name={"yearsOfCommencement"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter yearsOfCommencement!",
    },
  ]}
>
  <Input placeholder="yearsOfCommencement" />
</Form.Item>
<Form.Item
  label="yearsOfCompletion"
  name={"yearsOfCompletion"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter yearsOfCompletion!",
    },
  ]}
>
  <Input placeholder="yearsOfCompletion" />
</Form.Item>
<Form.Item
  label="documentsAttached"
  name={"documentsAttached"}
  style={{ width: "100%" }}
  rules={[
    {
      required: true,
      message: "Please enter documentsAttached!",
    },
  ]}
>
  <Input placeholder="documentsAttached" />
</Form.Item>
</div>
<Form.Item>
<Button block type="primary" htmlType="submit">
  Add Client
</Button>
</Form.Item> */
}
