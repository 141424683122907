import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import AddVisaCases from "../Visas/AddVisas";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import CollegeFinderInstituteDescription from "../CollegeFinder/CollegeFinderInstituteDescription";
import { formattedDate } from "../../HelperFunction";
const { Option } = Select;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Image",
    dataIndex: "image",
    align: "center",
  },
  {
    title: "Institute Name",
    dataIndex: "instituteName",
    align: "center",
  },
  {
    title: "Level of College",
    dataIndex: "levelOfCollege",
    align: "center",
  },
  {
    title: "Location",
    dataIndex: "location",
    align: "center",
  },

  {
    title: "Institute URL",
    dataIndex: "universityUrl",
    align: "center",
  },

  {
    title: "contact Person Name",
    dataIndex: "contactPersonName",
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const StartCounselling = ({
  reload,
  record,
  isStartCounsellingModalVisible,
  setIsStartCounsellingModalVisible,
}) => {
  const collegeFinderState = useSelector((state) => state.CollegeFinderReducer);
  const [collegeRecord, setCollegeRecord] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("Select");
  const [selectedDegree, setSelectedDegree] = useState("Select");
  const [selectedCourse, setSelectedCourse] = useState("Select");
  const [selectedIntake, setSelectedIntake] = useState("Select");
  const [isInstituteDetailsModelVisible, setIsInstituteDetailsModelVisible] =
    useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_ALL_COUNTRY_REQUEST" });
  }, []);
  const data = collegeFinderState.allCollege?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      image: dataObj.image ? (
        <Image
          src={dataObj.image}
          style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
        />
      ) : (
        "N/A"
      ),
      instituteName: dataObj.instituteName ?? "N/A",
      levelOfCollege: dataObj.levelOfCollege?.name ?? "N/A",
      location: dataObj.location ?? "N/A",
      universityUrl: dataObj.universityUrl ?? "N/A",
      contactPersonName: dataObj.contactPersonName ?? "N/A",
      contact: dataObj.contact ?? "N/A",
      actions: (
        <Space>
          <Tooltip title="Institute Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsInstituteDetailsModelVisible(true);
                  setCollegeRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });

  const [form] = Form.useForm();
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);

  return (
    <Drawer
      title="Start Counselling"
      open={isStartCounsellingModalVisible}
      onClose={() => {
        setIsStartCounsellingModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div
        className="drawerStyle"
        style={{ display: "flex", gap: "4rem", flexDirection: "column" }}
      >
        <Card className="clientCard" style={{ width: "100%" }}>
          <div className="flexColumnWithoutStyle" style={{ width: "100%" }}>
            <div className="flexRowSpaceBetween">
              Lead Name:
              <span>
                {record.firstName ?? ""} {record.lastName ?? ""}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Purpose:
              <span>
                {record.purpose?.purposeName
                  ? record.purpose?.purposeName
                  : record?.leadResponse?.purposeName
                  ? record?.leadResponse?.purposeName
                  : "N/A"}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Date:
              <span>
                {record?.counsellingBooking !== null
                  ? formattedDate(record?.counsellingBooking?.bookingDate)
                  : record?.appointmentDate !== null
                  ? formattedDate(record?.appointmentDate)
                  : "N/A"}{" "}
                {record?.counsellingBooking?.bookingDay ?? ""}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
            <div className="flexRowSpaceBetween">
              Time:
              <span>
                {record.counsellingBooking?.startTime
                  ? record.counsellingBooking?.startTime
                  : "N/A"}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Interested Country:
              <span>
                {record?.country?.name ? record?.country?.name : "N/A"}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Counsellor:
              <span>
                {record?.counsellingBooking?.consultant?.fullName
                  ? record?.counsellingBooking?.consultant?.fullName
                  : record?.assignedCounsellor !== null
                  ? record?.assignedCounsellor?.fullName
                  : "N/A"}
              </span>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

            <div className="flexRowSpaceBetween">
              Status:
              {record?.leadStatus === "BOOK_AN_APPOINTMENT" ? (
                <Tag color={record?.leadStatusColorCode}>IN PROGRESS</Tag>
              ) : (
                <Tag color={record?.leadStatusColorCode}>
                  {record?.leadStatus}
                </Tag>
              )}
            </div>
          </div>
        </Card>
        <div className="flexCenter">
          <div className="flexRowWithoutStyle" style={{ gap: "2rem" }}>
            {record.appointmentStatus !== "COMPLETED" ? (
              <>
                <Button
                  // className="buttonwithoutAnimation"
                  style={{
                    display: "inline-block",
                    backgroundColor: "green",
                    color: "white",
                    borderRadius: "0.4rem",
                  }}
                  onClick={() => {
                    dispatch({
                      type: "CHANGE_START_COUNSELLING_STATUS_REQUEST",
                      payload: { id: record?.id, status: "COMPLETED" },
                    });
                    setIsStartCounsellingModalVisible(false);
                  }}
                >
                  Complete
                </Button>
                <Button
                  type="danger"
                  // className="buttonwithoutAnimation"
                  style={{ display: "inline-block", borderRadius: "0.4rem" }}
                  onClick={() => {
                    dispatch({
                      type: "CHANGE_START_COUNSELLING_STATUS_REQUEST",
                      payload: { id: record?.id, status: "CANCELLED" },
                    });
                    setIsStartCounsellingModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : null}
            {record?.leadStatus !== "WIN" && (
              <Button
                type="primary"
                // className="buttonwithoutAnimation"
                style={{ display: "inline-block", borderRadius: "0.4rem" }}
                onClick={() => {
                  setIsAddVisasModalVisible(true);
                }}
              >
                Create Case
              </Button>
            )}
          </div>
        </div>

        <div className="DrawerStyle">
          <Form layout="vertical" onFinish={() => {}} colon={true} form={form}>
            <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
              <Form.Item
                label="Country"
                name={"country"}
                style={{ width: "100%" }}
                labelAlign="left"
              >
                <Select>
                  <Option value="Select">
                    <div
                      onClick={() => {
                        setSelectedCountry("Select");
                      }}
                    >
                      Select
                    </div>
                  </Option>
                  {collegeFinderState.countryFilter?.map((dataObj, index) => {
                    return (
                      <Option key={index} value={dataObj.filterName}>
                        <div
                          onClick={() => {
                            setSelectedCountry(dataObj.filterName);
                            dispatch({
                              type: "FETCH_ALL_DEGREE_REQUEST",
                              payload: dataObj.filterName,
                            });
                          }}
                        >
                          {dataObj.filterName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Degree"
                name={"degree"}
                style={{ width: "100%" }}
              >
                <Select>
                  <Option value="Select">
                    <div
                      onClick={() => {
                        setSelectedDegree("Select");
                      }}
                    >
                      Select
                    </div>
                  </Option>
                  {collegeFinderState.degreeFilter?.map((dataObj, index) => {
                    return (
                      <Option key={index} value={dataObj.filterName}>
                        <div
                          onClick={() => {
                            setSelectedDegree(dataObj.filterName);
                            dispatch({
                              type: "FETCH_ALL_COURSE_REQUEST",
                              payload: dataObj.filterName,
                            });
                          }}
                        >
                          {dataObj.filterName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Course"
                name={"course"}
                style={{ width: "100%" }}
              >
                <Select>
                  <Option value="Select">
                    <div
                      onClick={() => {
                        setSelectedCourse("Select");
                      }}
                    >
                      Select
                    </div>
                  </Option>
                  {collegeFinderState.courseFilter?.map((dataObj, index) => {
                    return (
                      <Option key={index} value={dataObj.filterName}>
                        <div
                          onClick={() => {
                            setSelectedCourse(dataObj.filterName);
                            dispatch({
                              type: "FETCH_ALL_INTAKE_REQUEST",
                              payload: dataObj.filterName,
                            });
                          }}
                        >
                          {dataObj.filterName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Intake"
                name={"intake"}
                style={{ width: "100%" }}
              >
                <Select>
                  <Option value="Select">
                    <div
                      onClick={() => {
                        setSelectedIntake("Select");
                      }}
                    >
                      Select
                    </div>
                  </Option>
                  {collegeFinderState.intakeFilter?.map((dataObj, index) => {
                    return (
                      <Option key={index} value={dataObj.filterName}>
                        <div
                          onClick={() => {
                            setSelectedIntake(dataObj.filterName);
                            dispatch({
                              type: "FETCH_ALL_COLLEGE_REQUEST",
                              payload: {
                                selectedCountry,
                                selectedDegree,
                                selectedCourse,
                                selectedIntake: dataObj.filterName,
                              },
                            });
                          }}
                        >
                          {dataObj.filterName}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  dispatch({
                    type: "FETCH_ALL_COLLEGE_REQUEST",
                    payload: {
                      selectedCountry,
                      selectedDegree,
                      selectedCourse,
                      selectedIntake,
                    },
                  });
                }}
              >
                FIND OPTIONS
              </Button>
            </div>
          </Form>
          <div style={{ marginTop: "4rem" }}>
            {collegeFinderState.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table bordered columns={columns} dataSource={data} />
            )}
          </div>
        </div>
      </div>

      <CollegeFinderInstituteDescription
        fromCounselling={true}
        recordData={record}
        record={collegeRecord}
        isInstituteDetailsModelVisible={isInstituteDetailsModelVisible}
        setIsInstituteDetailsModelVisible={setIsInstituteDetailsModelVisible}
        setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
      />
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromCounselling={true}
          recordData={record}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModalVisible}
        />
      )}
    </Drawer>
  );
};

export default StartCounselling;
