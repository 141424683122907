import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { UploadOutlined } from "@ant-design/icons";
const { Option } = Select;

const AddEducationVisa = ({
  country,
  countryList,
  isAddEducationVisaModalVisible,
  setIsAddEducationVisaModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDocument, setSelectedDocument] = useState(null);

  const [fileList, setFileList] = useState([]);

  const beforeUpload = (file) => {
    // You can implement file type validation or any other custom logic here
    // const isImage = file.type.startsWith("image/");
    // if (!isImage) {
    //   message.error("You can only upload image files!");
    // }
    return true;
  };

  const addEducationVisaSubmitHandler = (values) => {
    const formData = new FormData();
    selectedDocument && formData.append("document", selectedDocument);
    formData.append("subClass", values?.subClass);
    formData.append("country", values?.country);
    formData.append("description", values?.description);
    formData.append("visaCategory", "EDUCATION");

    dispatch({
      type: "ADD_EDUCATION_VISA_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Success!Education visa subclass added");
        setIsAddEducationVisaModalVisible(false);
        form.resetFields();
        setSelectedDocument(null);
      },
    });
  };
  return (
    <Modal
      title="Add Education Visa"
      open={isAddEducationVisaModalVisible}
      onCancel={() => {
        setIsAddEducationVisaModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addEducationVisaSubmitHandler}
        colon={true}
        form={form}
        initialValues={{ country: country }}
      >
        <Form.Item
          label="Country"
          name={"country"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select country name!",
            },
          ]}
        >
          <Select placeholder="Country name">
            {countryList?.map((country) => (
              <Select.Option value={country.countryName}>
                {country.countryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visa class"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa class name!",
            },
          ]}
        >
          <Input placeholder="Visa class name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name={"description"}
          style={{ width: "100%" }}
        >
          <ReactQuill
            style={{
              background: "white",
              marginBottom: "2rem",
              height: "150px",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
          />
        </Form.Item>
        <Form.Item
          label="Add New Attachment"
          name="document"
          style={{ paddingTop: "1rem" }}
        >
          <Input
            onChange={(e) => {
              setSelectedDocument(e.target.files[0]);
            }}
            placeholder="Upload New Attachment"
            type="file"
          />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Education Visa
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEducationVisa;
