import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  Avatar,
  Modal,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { formattedDate } from "../../HelperFunction";
import AddQuotation from "./AddQuotation";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import {
  AiOutlineCheckCircle,
  AiOutlineEye,
  AiOutlineFilePdf,
  AiOutlineMail,
} from "react-icons/ai";
import QuotationDetails from "./QuotationDetails";

import download from "downloadjs";
import FileSaver, { saveAs } from "file-saver";
import PDFFile from "./PDF/PDFFile";
import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";
import SendComposedMail from "../Mailbox/SendComposedMail";
import PDFviewer from "./PDF/pdfViewer";
import UpdateQuotation from "./UpdateQuotation";

const { confirm } = Modal;

const columns = [
  {
    title: "Quote Id",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    align: "center",
  },
  {
    title: "Nature of Quote",
    dataIndex: "natureOfQuote",
    align: "center",
  },
  {
    title: "Total",
    dataIndex: "total",
    align: "center",
  },
  {
    title: "Quote Date",
    dataIndex: "quoteDate",
    align: "center",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    align: "center",
  },
  {
    title: "Quote Status",
    dataIndex: "quoteStatus",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const Quotation = () => {
  const store = useSelector((state) => {
    return {
      quotationState: state.QuotationReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });

  const [isAddQuotationModalVisible, setIsAddQuotationModalVisible] =
    useState(false);
  const [isUpdateQuotationModalVisible, setIsUpdateQuotationModalVisible] =
    useState(false);
  const [isQuotationDetailsModelVisible, setIsQuotationDetailsModelVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isPDFviewerModalVisible, setIsPDFviewerModalVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [quotationType, setQuotationType] = useState("allQuotation");
  const [quotationSettingsData, setQuotationSettingsData] = useState({
    // quotationPrefix: "",

    quoteExpiryDateAfter: "",
  });

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: quotationSuccessFunction,
    });
    dispatch({ type: "FETCH_PROFILE_REQUEST" });
  }, []);
  const quotationSuccessFunction = (response) => {
    setQuotationSettingsData((previousData) => {
      return {
        ...previousData,
        // quotationPrefix: response?.data?.prefix?.leadPrefix,
        quoteExpiryDateAfter: response?.data?.expiryDates?.quoteExpiryDateAfter,
      };
    });
  };
  useEffect(() => {
    if (quotationType === "activeQuotation") {
      dispatch({
        type: "FETCH_ACTIVE_QUOTATION_REQUEST",
      });
    } else {
      dispatch({
        type: "FETCH_QUOTATION_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [quotationType, store.branchState?.branchRecord]);
  //if (quotationType === "allQuotation") {
  const data = store.quotationState.quotations?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: dataObj.id,
      customer: dataObj?.customerId?.fullName ? (
        <Tooltip title="Institute Details">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setRecord(dataObj);
                setIsQuotationDetailsModelVisible(true);
              }}
            >
              {dataObj?.customerId?.fullName}
            </div>
          </a>
        </Tooltip>
      ) : (
        "N/A"
      ),
      natureOfQuote: dataObj.natureOfProject,
      total: dataObj.totalAmount ?? "N/A",
      quoteDate: dataObj.addedDateTime
        ? formattedDate(dataObj.addedDateTime)
        : null,

      expiryDate: dataObj.quoteExpiryDate
        ? formattedDate(dataObj.quoteExpiryDate)
        : "N/A",
      quoteStatus:
        dataObj?.quoteStatus === "QUOTE_DRAFT" ? (
          <p className="orangeTag">QUOTE_DRAFT</p>
        ) : dataObj?.quoteStatus === "QUOTE_SENT" ? (
          <p className="blueTag">QUOTE_SENT</p>
        ) : dataObj?.quoteStatus === "WIN" ? (
          <p className="greenTag">WIN</p>
        ) : dataObj?.quoteStatus === "LOSE" ? (
          <p className="redTag">LOSE</p>
        ) : (
          "N/A"
        ),
      actions: (
        <Space size="middle">
          {dataObj.quoteStatus === "QUOTE_SENT" &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
              "CHANGE_STATUS"
            ) && (
              <Tooltip title="Approve quotation">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      confirm({
                        title: "Do you Want to apporove this quotation?",
                        icon: <ExclamationCircleFilled />,
                        // content: 'Some descriptions',
                        onOk() {
                          dispatch({
                            type: "APPROVE_QUOTATION_REQUEST",
                            payload: {
                              quoteId: dataObj.id,
                            },
                          });
                        },
                        onCancel() {
                          console.log("Cancel");
                        },
                      });
                      // setIsUpdateQuotationModalVisible(true);
                      // setRecord(dataObj);
                    }}
                  >
                    <AiOutlineCheckCircle />
                  </div>
                </a>
              </Tooltip>
            )}
          {store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
            "UPDATE"
          ) && (
            <Tooltip title="Update quotation">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setRecord(dataObj);
                    setIsUpdateQuotationModalVisible(true);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
          )}
          <Tooltip title="Quotation Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsQuotationDetailsModelVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          {dataObj?.customerId?.email &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
              "SEND_EMAIL"
            ) && (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsSendComposedEmailModalVisible(true);
                    }}
                  >
                    <AiOutlineMail />
                  </div>
                </a>
              </Tooltip>
            )}
          {/* <Tooltip title="Download PDF">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  confirm({
                    title: "Do you want to download now?",
                    icon: <ExclamationCircleFilled />,
                    okText: "Download",
                    cancelText: "Cancel",
                    onOk() {
                      dispatch({
                        type: "DOWNLOAD_QUOTATION_REQUEST",
                        payload: {
                          quotationId: dataObj.id,
                          isPdfDownloadFn: true,
                          pdfDownloadFn: pdfDownloadFn,
                        },
                      });
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              >
                <AiOutlineFilePdf />
              </div>
            </a>
          </Tooltip> */}
          <Tooltip title="Download PDF">
            <a>
              <PDFDownloadLink
                document={
                  <PDFFile
                    record={dataObj}
                    companyProfile={store.profileState?.profile}
                  />
                }
                fileName="quotation"
              >
                <div className="bordered">
                  <AiOutlineFilePdf />
                </div>
              </PDFDownloadLink>
            </a>
          </Tooltip>
          {/* <Tooltip title="Preview PDF">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsPDFviewerModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip> */}
          {/* <Tooltip title="Delete quotation">
            <Popconfirm
              title="Are you sure to delete this quotation?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_QUOTATION_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip> */}
          {/* <Tooltip title="Toggle quotation status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  const actionStatus =
                    dataObj.status === "INACTIVE" ? "active" : "inactive";
                  dispatch({
                    type: "TOGGLE_QUOTATION_STATUS_REQUEST",
                    payload: { id: dataObj.id, actionStatus },
                  });
                }}
              >
                <MdOutlineDisabledVisible />
              </div>
            </a>
          </Tooltip> */}
        </Space>
      ),
    };
  });
  //   } else {
  //     data = quotationState.activeQuotation?.data?.map((dataObj) => {
  //       return {
  //     key: dataObj.id,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //     : dataObj.,
  //         : dataObj.? (
  //           <Tag color="#00ff00aa">YES</Tag>
  //         ) : (
  //           <Tag color="#ff0000aa">NO</Tag>
  //         ),
  //         :
  //           dataObj.=== "ACTIVE" ? (
  //             <p className="greenTag">{dataObj.}</p>
  //           ) : (
  //             <p className="redTag">{dataObj.}</p>
  //           ),
  //         actions: (
  //           <Space size="middle">
  //             <Tooltip title="Update quotation">
  //               <a>
  //                 <div
  //                   className="bordered"
  //                   onClick={() => {
  //                     setIsUpdateQuotationModalVisible(true);
  //                     setRecord(dataObj);
  //                   }}
  //                 >
  //                   <EditOutlined />
  //                 </div>
  //               </a>
  //             </Tooltip>
  //             <Tooltip title="Delete quotation">
  //               <Popconfirm
  //                 title="Are you sure to delete this quotation?"
  //                 onConfirm={() => {
  //                   dispatch({
  //                     type: "DELETE_QUOTATION_REQUEST",
  //                     payload: { id: dataObj.id },
  //                   });
  //                 }}
  //                 onCancel={() => {}}
  //                 okText="Yes"
  //                 cancelText="No"
  //               >
  //                 <a>
  //                   <div className="bordered">
  //                     <DeleteOutlined />
  //                   </div>
  //                 </a>{" "}
  //               </Popconfirm>
  //             </Tooltip>
  //             <Tooltip title="Toggle quotation status">
  //               <a>
  //                 <div
  //                   className="bordered"
  //                   onClick={() => {
  //                     const actionStatus =
  //                       dataObj.=== "INACTIVE" ? "active" : "inactive";
  //                     dispatch({
  //                       type: "TOGGLE_QUOTATION_STATUS_REQUEST",
  //                       payload: { id: dataObj.id, actionStatus },
  //                     });
  //                   }}
  //                 >
  //                   <MdOutlineDisabledVisible />
  //                 </div>
  //               </a>
  //             </Tooltip>
  //           </Space>
  //         ),
  //       };
  //     });
  //   }

  const pdfDownloadFn = (response) => {
    console.log("quotation pdf", response?.data);
    console.log("check length", response.data?.length);
    const header = response?.data.slice(0, 8).toString();
    if (!header.startsWith("%PDF-")) {
      console.log("Invalid byte array: Not a valid PDF format");
    } else {
      console.log("Valid byte array");
    }

    // const byteCharacters = window.atob(response?.data);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteNumbers], { type: "application/pdf" });
    // console.log(blob, "blob");
    // saveAs(blob, "quotation.pdf");
    // let hexString = "";
    // for (let i = 0; i < response?.data?.length; i++) {
    //   const hex = response?.data[i].toString(16);
    //   hexString += (hex.length === 1 ? "0" : "") + hex;
    // }

    // const byteArray = [...response?.data];
    // const arrayBuffer = new ArrayBuffer(byteArray.length);
    // const uint8Array = new Uint8Array(arrayBuffer);

    // for (let i = 0; i < byteArray.length; i++) {
    //   uint8Array[i] = byteArray[i];
    // }
    // reader.readAsArrayBuffer(file);
    // reader.readAsArrayBuffer(file);
    // const uint8Array = new Uint8Array(response?.data);
    // var arrBuffer = base64ToArrayBuffer(response.data);
    // const blob = new Blob([arrBuffer], { type: "application/pdf" });
    const byteArray = response.data;
    const blob = new Blob([byteArray], { type: "application/pdf" });
    console.log(blob, "blob");

    const pdfUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = "filename.pdf"; // Set the desired filename

    downloadLink.click();
    // var reader = new FileReader();
    // let file = reader.readAsArrayBuffer(blob);
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = "file.pdf";
    // link.click();
    // window.open(link);
    // saveAs(blob, "quotation.pdf");

    // let xhr = new XMLHttpRequest();
    // xhr.open(
    //   "GET",
    //   `${process.env.REACT_APP_TEST_URL}v1/api/counselling/get-pdf/1`,
    //   true
    // );
    // xhr.setRequestHeader("Authorization", "Bearer "+)
    // xhr.responseType = "arraybuffer";
    // xhr.onload = function (e) {
    //   var blob = new File([this.response], { type: "application/pdf" });
    //   var link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = "Report_" + new Date() + ".pdf";
    //   link.click();
    // };
    // xhr.send();
  };

  // function base64ToArrayBuffer(data) {
  //   var binaryString = window.atob(data);
  //   var binaryLen = binaryString.length;
  //   var bytes = new Uint8Array(binaryLen);
  //   for (var i = 0; i < binaryLen; i++) {
  //     var ascii = binaryString.charCodeAt(i);
  //     bytes[i] = ascii;
  //   }
  //   return bytes;
  // }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
            "ADD"
          ) && (
            <button
              className="button"
              onClick={() => {
                setIsAddQuotationModalVisible(true);
              }}
            >
              <span>Add Quotation</span>
            </button>
          )}
          {/* <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Quotation</div>
                  </div>
                ),
                value: "allQuotation",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Quotation</div>
                  </div>
                ),
                value: "activeQuotation",
              },
            ]}
            onChange={(value) => {
              setQuotationType(value);
              console.log(value);
            }}
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store.quotationState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {store.quotationState?.quotations?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store.quotationState?.quotations?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddQuotationModalVisible && (
        <AddQuotation
          quotationSettingsData={quotationSettingsData}
          isAddQuotationModalVisible={isAddQuotationModalVisible}
          setIsAddQuotationModalVisible={setIsAddQuotationModalVisible}
        />
      )}
      {isQuotationDetailsModelVisible && (
        <QuotationDetails
          record={record}
          isQuotationDetailsModelVisible={isQuotationDetailsModelVisible}
          setIsQuotationDetailsModelVisible={setIsQuotationDetailsModelVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.customerId?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isPDFviewerModalVisible && (
        <PDFviewer
          record={record}
          companyProfile={store.profileState?.profile}
          isPDFviewerModalVisible={isPDFviewerModalVisible}
          setIsPDFviewerModalVisible={setIsPDFviewerModalVisible}
        />
      )}
      {isUpdateQuotationModalVisible && (
        <UpdateQuotation
          record={record}
          isUpdateQuotationModalVisible={isUpdateQuotationModalVisible}
          setIsUpdateQuotationModalVisible={setIsUpdateQuotationModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Quotation;
