import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  date,
  dateAndTime,
  formattedDate,
  getTimeZone,
  scrollToTopFormError,
  YYYYMMDD,
} from "../../HelperFunction";
import AddLeadSource from "../Leads/LeadSource/AddLeadSource";
import AddCountry from "../Leads/Country/AddCountry";
import { AiOutlinePlus } from "react-icons/ai";
import AddConsultant from "../Leads/Consultant/AddConsultant";
import moment from "moment/moment";
import AddEmployee from "../EmployeeManager/AddEmployee";
import AddContacts from "../Contacts/AddContacts";
import Purpose from "../Leads/Purpose/Purpose";
import { useNavigate } from "react-router-dom";
import AddSalesRep from "../Visas/AddSalesRep";
import axios from "axios";
import { getCookie } from "../../Helpers/FrontendHelper";
const { Option } = Select;
const { TextArea } = Input;

const AddAppointment = ({
  counsellorRecord,
  appointmentReminderData,
  navigateAppointmentList,
  fromSiderDemo,
  fromClientsDetails,
  leadRecord,
  isAddAppointmentModalVisible,
  setIsAddAppointmentModelVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      addAppointmentState: state.LeadReducer,
    };
  });
  const [isContactType, setIsContactType] = useState(true);
  const navigate = useNavigate();
  const [isAddLeadSourceModalVisible, setIsAddLeadSourceModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);

  const [isAddSalesRepModalVisible, setIsAddSalesRepModalVisible] =
    useState(false);
  const [isCouncelling, setIsCouncelling] = useState(
    leadRecord !== undefined
      ? true
      : counsellorRecord !== undefined
      ? true
      : false
  );

  const [counsellorId, setCounsellorId] = useState(
    counsellorRecord !== undefined ? counsellorRecord?.id : null
  );
  const [counsellorName, setCounsellorName] = useState(
    counsellorRecord !== undefined
      ? `${counsellorRecord?.firstName} ${counsellorRecord?.lastName}`
      : null
  );
  const [appointmentDate, setAppointmentDate] = useState();
  const [sessionTime, setSessionTime] = useState();
  const [record, setRecord] = useState({
    isContactType: true,
    mobile:
      leadRecord !== undefined && leadRecord?.customerResponse?.mobile
        ? leadRecord?.customerResponse?.mobile
        : leadRecord?.mobile
        ? leadRecord?.mobile
        : null,
    email:
      leadRecord !== undefined && leadRecord?.customerResponse?.email
        ? leadRecord?.customerResponse?.email
        : leadRecord?.email
        ? leadRecord?.email
        : null,
    lastName:
      leadRecord !== undefined ? leadRecord?.customerResponse?.lastName : "",
    firstName:
      leadRecord !== undefined ? leadRecord?.customerResponse?.firstName : "",
    customer:
      leadRecord !== undefined ? leadRecord?.customerResponse?.id : null,
    customerName:
      leadRecord !== undefined
        ? `${leadRecord?.customerResponse?.fullName}
    ${
      leadRecord?.customerResponse?.email !== null &&
      leadRecord?.customerResponse?.email !== ""
        ? `(${leadRecord?.customerResponse?.email})`
        : leadRecord?.customerResponse?.mobile !== null &&
          leadRecord?.customerResponse?.mobile !== ""
        ? `(${leadRecord?.customerResponse?.mobile ?? ""})`
        : ""
    }`
        : ``,
    country: leadRecord?.countryId,
    countryName: leadRecord?.countryName,
    leadSourceId: leadRecord?.leadSourceId,
    leadSourceName: leadRecord?.leadSourceName,
    purposeId: leadRecord?.purposeId,
    purposeName: leadRecord?.purposeName,

    countryOfResidence:
      leadRecord !== undefined
        ? leadRecord?.customerResponse?.countryOfResidence
        : "",

    salesRepresentativeName:
      leadRecord?.salesRepName !== undefined ? leadRecord?.salesRepName : "",
    salesRepresentativeId:
      leadRecord?.salesRepId !== undefined ? leadRecord?.salesRepId : "",
    updateWeightage:
      leadRecord?.weightage !== undefined ? leadRecord?.weightage : "",
  });
  useEffect(() => {
    if (isAddAppointmentModalVisible === true) {
      dispatch({
        type: "FETCH_LEADS_DROPDOWN_REQUEST",
        payload: {
          fromInstituteVisaCase: true,
          visaSuccessFunction: appointmentSuccessFunction,
        },
      });
      dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
    }
    // setRecord({
    //   isContactType: true,
    //   mobile: "",
    //   email: "",
    //   lastName: "",
    //   firstName: "",
    //   customer: null,
    //   customerName: ``,
    //   countryOfResidence: "",
    // });
  }, [isAddAppointmentModalVisible]);
  useEffect(() => {
    if (counsellorId && appointmentDate && sessionTime && isCouncelling) {
      dispatch({
        type: "FETCH_TIMING_DROPDOWN_REQUEST",
        payload: {
          counsellorId,
          sessionTime,
          values: {
            appointmentDate: appointmentDate,
            timeOffset: getTimeZone(),
          },
        },
      });
    }
  }, [counsellorId, appointmentDate, sessionTime, isCouncelling]);
  const appointmentSuccessFunction = (response) => {
    setRecord((previousData) => {
      return {
        ...previousData,
        countryOfResidence: leadRecord?.customerResponse?.countryOfResidence
          ? leadRecord?.customerResponse?.countryOfResidence
          : response?.data?.defaultCountry?.name,
      };
    });
  };
  const addAppointmentSubmitHandler = (value) => {
    const reminderDateAndTime = dateAndTime(
      value.reminderDate,
      value.reminderTime
    );
    const bookingDate =
      value.appointmentDate && isCouncelling
        ? date(value.appointmentDate)
        : null;
    const bookingDay = isCouncelling
      ? moment(value.appointmentDate).format("dddd").toUpperCase()
      : null;
    const appointmentDateTime = dateAndTime(
      value.appointmentDate,
      value.appointmentTime
    );

    const formData = {
      ...value,
      branchId: localStorage.getItem("branchId"),
      leadId: leadRecord !== undefined ? leadRecord?.id : null,
      email: record?.email ? record?.email : null,
      phoneNumber: record?.mobile === "" ? null : record?.mobile,
      reminderDateAndTime,
      assignCounsellor: isCouncelling,
      appointmentDate: date(value.appointmentDate),
      consultantId: counsellorId,
      counsellor: counsellorId,
      bookingDate,
      bookingDay: bookingDay,
      appointmentDateTime,
      country: record?.country ? record.country : null,
      leadSourceId: record?.leadSourceId ? record?.leadSourceId : null,
      purposeId: record?.purposeId ? record?.purposeId : null,
      sendByEmail: value.sendByEmail ? value.sendByEmail : false,
      salesRepresentativeId: record?.salesRepresentativeId
        ? record?.salesRepresentativeId
        : null,
      updateWeightage: record?.updateWeightage ? record?.updateWeightage : null,

      // mobileNumber: value.mobile,
      // purpose: "",
    };
    dispatch({
      type: "ADD_APPOINTMENTS_REQUEST",
      payload: formData,
      payload2: {
        fromClientsDetails: fromClientsDetails ? true : false,
        contactRecordID: leadRecord?.customerResponse?.id,
        successFunction: successFunction,
      },
    });
    dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
    setRecord(null);
    form.resetFields();
    setIsAddAppointmentModelVisible(false);

    dispatch({ type: "INITIALIZE_TIMING_DROPDOWN" });
    if (fromSiderDemo) {
      navigate("/appointments");
    }
    if (navigateAppointmentList) {
      navigate("/appointments");
    }
  };
  const successFunction = () => {
    dispatch({
      type: "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST",
      payload: leadRecord?.customerResponse?.id,
    });
  };

  return (
    <Drawer
      title="Add Appointment"
      placement="right"
      open={isAddAppointmentModalVisible}
      onClose={() => {
        dispatch({ type: "INITIALIZE_TIMING_DROPDOWN" });
        setIsAddAppointmentModelVisible(false);
        dispatch({ type: "CLEAR_AVAILABLE_DATE_AND_TIME" });
        setRecord(null);
        form.resetFields();
      }}
      width={1163}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addAppointmentSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["ContactType"],
            value: record?.isContactType,
          },
          {
            name: ["firstName"],
            value: record?.firstName,
          },
          {
            name: ["customer"],
            value: record?.customer ?? "",
          },
          {
            name: ["lastName"],
            value: record?.lastName,
          },
          {
            name: ["email"],
            value: record?.email,
          },
          {
            name: ["mobile"],
            value: record?.mobile,
          },
          {
            name: ["countryOfResidence"],
            value: record?.countryOfResidence,
          },
          {
            name: ["country"],
            value: record?.countryName,
          },
          {
            name: ["leadSourceId"],
            value: record?.leadSourceName,
          },
          {
            name: ["counsellor"],
            value: counsellorName,
          },
          {
            name: ["purposeId"],
            value: record?.purposeName,
          },
          {
            name: ["bookingStatus"],
            value: isCouncelling,
          },
          {
            name: ["salesRepresentativeId"],
            value: record?.salesRepresentativeName,
          },
          {
            name: ["updateWeightage"],
            value: record?.updateWeightage,
          },
        ]}
        initialValues={{
          bookingStatus: false,
          reminderDate: moment().add(
            appointmentReminderData?.appointmentReminder,
            "days"
          ),
          reminderTime: moment(),
        }}
      >
        <Form.Item
          name={"ContactType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select client type",
            },
          ]}
        >
          <Radio.Group
            defaultValue={true}
            onChange={(e) => {
              setIsContactType(e.target.value);
              setRecord((previousData) => {
                return {
                  ...previousData,
                  isContactType: e.target.value,
                  mobile: "",
                  email: "",
                  lastName: "",
                  firstName: "",
                  customer: null,
                  customerName: "",
                  countryOfResidence: "",
                  purposeName: "",
                  purposeId: null,
                  leadSourceId: null,
                  leadSourceName: "",
                  country: null,
                  countryName: "",
                };
              });
              // if (e.target.value === false) {
              //   setRecord(null);
              // }
            }}
          >
            <Radio value={true}>Existing Contact</Radio>
            <Radio value={false}>New Contact</Radio>
          </Radio.Group>
        </Form.Item>
        {record?.isContactType ? (
          <div className="flexRowWithoutStyle" style={{ gap: "0.3rem" }}>
            <Form.Item
              label="Contact"
              name={"customer"}
              style={{ width: "100%" }}
            >
              <Select
                showSearch
                placeholder="Select a Contact"
                optionFilterProp="children"
                onSearch={(e) => {}}
                filterOption={(input, option) => {
                  return (option?.children?.props?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {store.addAppointmentState?.leadsDropdown?.contacts?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={() => {
                            setRecord((previousData) => {
                              return {
                                ...previousData,
                                customer: dataObj.id,
                                customerName: `${dataObj.firstName} ${dataObj.lastName}`,
                                firstName: dataObj.firstName ?? "",
                                lastName: dataObj.lastName ?? "",
                                mobile: dataObj?.phoneNumber,
                                email: dataObj?.email,
                                countryOfResidence: dataObj?.countryOfResidence,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {`${dataObj.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } 
                            ${
                              dataObj.email !== null && dataObj.email !== ""
                                ? `(${dataObj.email})`
                                : dataObj.phoneNumber !== null &&
                                  dataObj.phoneNumber !== ""
                                ? `(${dataObj.phoneNumber ?? ""})`
                                : ""
                            }
                            `}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddContactVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        ) : null}

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="First Name"
            name={"firstName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              placeholder="First Name"
              onChange={(e) => {
                setRecord((previousData) => {
                  return {
                    ...previousData,
                    firstName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name={"lastName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              placeholder="Last Name"
              onChange={(e) => {
                setRecord((previousData) => {
                  return {
                    ...previousData,
                    lastName: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mobile Number"
            name={"mobile"}
            style={{ width: "100%" }}
            rules={[
              {
                pattern: /^[\d]{9,11}$/,
                message: "Mobile number should contain 9 to 11 numbers",
              },
              {
                validator: async (_, value) => {
                  const token = getCookie("accessToken");
                  const response = await axios.get(
                    // `${
                    //   process.env.REACT_APP_TEST_URL
                    // }v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                    //   "branchId"
                    // )}?mobile=${value}`,
                    `v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                      "branchId"
                    )}?mobile=${value}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                      },
                    }
                  );
                  if (
                    response?.data === false ||
                    value === "" ||
                    value === null ||
                    value === undefined
                  ) {
                    return Promise.resolve();
                  } else if (
                    response?.data === true &&
                    isContactType === false
                  ) {
                    return Promise.reject(
                      new Error("Entered mobile number already exists")
                    );
                  }
                },
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <Input
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => {
                setRecord((previousData) => {
                  return {
                    ...previousData,
                    mobile: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            style={{ width: "100%" }}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email",
              },
              {
                validator: async (_, value) => {
                  const token = getCookie("accessToken");
                  const response = await axios.get(
                    // `${
                    //   process.env.REACT_APP_TEST_URL
                    // }v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                    //   "branchId"
                    // )}?email=${value}`,
                    `v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                      "branchId"
                    )}?email=${value}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                      },
                    }
                  );
                  if (
                    response?.data === false ||
                    value === "" ||
                    value === null ||
                    value === undefined
                  ) {
                    return Promise.resolve();
                  } else if (
                    response?.data === true &&
                    isContactType === false
                  ) {
                    return Promise.reject(
                      new Error("Entered email already exists")
                    );
                  }
                },
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <Input
              type="email"
              placeholder="e.g. name@domainname.com"
              onChange={(e) => {
                setRecord((previousData) => {
                  return {
                    ...previousData,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Lead source"
              name={"leadSourceId"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select lead source!",
                },
              ]}
            >
              <Select>
                {store.addAppointmentState.leadsDropdown?.leadSource?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={() => {
                            setRecord((previousData) => {
                              return {
                                ...previousData,
                                leadSourceId: dataObj.id,
                                leadSourceName: dataObj.leadSource,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.leadSource}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddLeadSourceModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Purpose"
              name={"purposeId"}
              style={{ width: "95%" }}
              rules={[
                {
                  required: true,
                  message: "Please select your purpose!",
                },
              ]}
            >
              <Select
              // defaultValue={
              //   store.leadState.leadsDropdown?.purposes?.[0].id
              // }
              >
                {store.addAppointmentState.leadsDropdown?.purposes?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.id}>
                        <div
                          onClick={() => {
                            setRecord((previousData) => {
                              return {
                                ...previousData,
                                purposeId: dataObj.id,
                                purposeName: dataObj.purposeName,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.purposeName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddPurposeModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label={`Country to ${
                record?.purposeName === "MIGRATION" ? "Migration" : "Study"
              }`}
              name={"country"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter country!",
                },
              ]}
            >
              <Select
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) => {
                  return option?.children?.props?.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {store.addAppointmentState.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setRecord((previousData) => {
                              return {
                                ...previousData,
                                country: dataObj.id,
                                countryName: dataObj.countryName,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Country of Residence"
              name={"countryOfResidence"}
              style={{ width: "100%" }}
            >
              <Select
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) => {
                  return (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {store.addAppointmentState?.leadsDropdown?.countries?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id} value={dataObj.countryName}>
                        <div
                          onClick={() => {
                            setRecord((previousData) => {
                              return {
                                ...previousData,
                                countryOfResidence: dataObj.countryName,
                              };
                            });
                          }}
                          className={"selectOptionDiv"}
                        >
                          {dataObj.countryName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddCountryModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
        </div>
        <Form.Item
          label="Want to confirm appointment now?"
          name={"bookingStatus"}
          style={{ width: "100%" }}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select a option!",
          //   },
          // ]}
        >
          <Radio.Group
            onChange={(e) => {
              setIsCouncelling(e.target.value);
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {isCouncelling === false ? (
          <>
            <Form.Item
              label="Appointment Date"
              name={"appointmentDate"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter appointment date!",
                },
              ]}
            >
              <DatePicker
                disabledDate={(cd) => {
                  const d = new Date();
                  return (
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 0;
                        }
                      ) ||
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 1;
                        }
                      ) ||
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 2;
                        }
                      ) ||
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 3;
                        }
                      ) ||
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 4;
                        }
                      ) ||
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 5;
                        }
                      ) ||
                    cd.day() ===
                      store.timingDropdownState?.workingDaysOfCounsellor?.find(
                        (data) => {
                          return data === 6;
                        }
                      ) ||
                    cd < moment().subtract(1, "days")
                  );
                }}
              />
            </Form.Item>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", width: "100%" }}
            >
              <Form.Item
                label="Counsellor"
                name={"counsellor"}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select counsellor!",
                //   },
                // ]}
              >
                <Select>
                  {store.addAppointmentState?.leadsDropdown?.consultantList?.map(
                    (dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              setCounsellorId(dataObj.id);
                              setCounsellorName(
                                `${dataObj.firstName} ${dataObj.lastName}`
                              );
                              dispatch({
                                type: "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST",
                                payload: { consultantId: dataObj.id },
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.firstName} {dataObj.lastName}
                          </div>
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
              <a
                className="plusButton"
                onClick={() => {
                  setIsAddConsultantModalVisible(true);
                }}
              >
                <AiOutlinePlus className="iconColor" />
              </a>
            </div>
          </>
        ) : (
          <>
            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.3rem", width: "100%" }}
              >
                <Form.Item
                  label="Counsellor"
                  name={"counsellor"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select counsellor!",
                    },
                  ]}
                >
                  <Select>
                    {store.addAppointmentState?.leadsDropdown?.consultantList?.map(
                      (dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={() => {
                                setCounsellorId(dataObj.id);
                                setCounsellorName(
                                  `${dataObj.firstName} ${dataObj.lastName}`
                                );
                                dispatch({
                                  type: "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST",
                                  payload: { consultantId: dataObj.id },
                                });
                              }}
                              className={"selectOptionDiv"}
                            >
                              {dataObj.firstName} {dataObj.lastName}
                            </div>
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddConsultantModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </div>
              <Form.Item
                label="Booking Date"
                name={"appointmentDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please pick a booking date!",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  disabledDate={(cd) => {
                    const d = new Date();
                    return (
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 0;
                          }
                        ) ||
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 1;
                          }
                        ) ||
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 2;
                          }
                        ) ||
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 3;
                          }
                        ) ||
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 4;
                          }
                        ) ||
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 5;
                          }
                        ) ||
                      cd.day() ===
                        store.timingDropdownState?.workingDaysOfCounsellor?.find(
                          (data) => {
                            return data === 6;
                          }
                        ) ||
                      cd < moment().subtract(1, "days")
                    );
                  }}
                  onChange={(e) => {
                    setAppointmentDate(YYYYMMDD(e));
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Session Time"
              name={"sessionTime"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a session time!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setSessionTime(e);
                }}
              >
                <Option value={"THIRTY_MINUTES"}>30 Minutes</Option>
                <Option value={"ONE_HOUR"}>1 Hour</Option>
                <Option value={"TWO_HOUR"}>2 Hour</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Available Time Slots"
              name={"startTime"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please pick a your time slot!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {}}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {store.timingDropdownState &&
                  store.timingDropdownState.timingDropdown.map(
                    (dataObj, index) => {
                      return (
                        <div key={index}>
                          <Radio value={dataObj.startTime}>
                            {dataObj.startTime} - {dataObj.endTime}
                          </Radio>
                        </div>
                      );
                    }
                  )}
              </Radio.Group>
            </Form.Item>
          </>
        )}

        {/* <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Appointment Date"
            name={"appointmentDate"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick appointment date!",
            //   },
            // ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Appointment Time"
            name={"appointmentTime"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please pick a appointment time!",
            //   },
            // ]}
          >
            <TimePicker />
          </Form.Item>
        </div> */}
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "0.3rem", width: "100%" }}
          >
            <Form.Item
              label="Sales Reps"
              name={"salesRepresentativeId"}
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select Sales Reps",
              //   },
              // ]}
            >
              <Select>
                {store.addAppointmentState?.leadsDropdown?.salesRepresentativeResponse?.map(
                  (dataObj) => {
                    return (
                      <Option key={dataObj.id}>
                        <div
                          onClick={() => {
                            setRecord((previouData) => {
                              return {
                                ...previouData,
                                salesRepresentativeId: dataObj.id,
                                salesRepresentativeName: dataObj.salesRepName,
                              };
                            });
                            // setSalesRepresentativeId(dataObj.id);
                            // setSalesRepresentativeName(dataObj.salesRepName);
                          }}
                        >
                          {dataObj.salesRepName}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <a
              className="plusButton"
              onClick={() => {
                setIsAddSalesRepModalVisible(true);
              }}
            >
              <AiOutlinePlus className="iconColor" />
            </a>
          </div>
          <Form.Item
            label="Weightage"
            name={"updateWeightage"}
            style={{ width: "100%" }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select weightage",
            //   },
            // ]}
          >
            <Select>
              {store.addAppointmentState?.leadsDropdown?.weightage?.map(
                (dataObj, index) => {
                  return (
                    <Option key={dataObj}>
                      <div
                        onClick={(e) => {
                          setRecord((previouData) => {
                            return {
                              ...previouData,
                              updateWeightage: dataObj,
                            };
                          });
                        }}
                      >
                        {dataObj}
                      </div>
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Reminder Date"
            name={"reminderDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick reminder date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Reminder Time"
            name={"reminderTime"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a reminder time!",
              },
            ]}
          >
            <TimePicker />
          </Form.Item>
        </div>
        <Form.Item label="Note" name={"note"} style={{ width: "100%" }}>
          <TextArea placeholder="Write Something" />
        </Form.Item>
        {record.email !== null && record.email !== "" && (
          <Form.Item name={"sendByEmail"} valuePropName="checked">
            <Checkbox>Send by Email</Checkbox>
          </Form.Item>
        )}
        <Form.Item
          name="appointmentSubmit"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  (getFieldValue("email") === undefined ||
                    getFieldValue("email") === "" ||
                    getFieldValue("email") === null) &&
                  (getFieldValue("mobile") === undefined ||
                    getFieldValue("mobile") === "" ||
                    getFieldValue("mobile") === null)
                ) {
                  return Promise.reject(
                    new Error("Please enter either email or mobile number!")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Button
            name="appointmentSubmit"
            block
            type="primary"
            htmlType="submit"
          >
            Add Appointment
          </Button>
        </Form.Item>
      </Form>
      {isAddLeadSourceModalVisible && (
        <AddLeadSource
          isAddLeadSourceModalVisible={isAddLeadSourceModalVisible}
          setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
        />
      )}
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddConsultantModalVisible && (
        <AddEmployee
          AddEmployeeTitle="Add Counsellor"
          isConsultantFrmConsultant={true}
          isAddEmployeeModalVisible={isAddConsultantModalVisible}
          setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
        />
      )}
      {isAddContactVisible && (
        <AddContacts
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
        />
      )}
      {isAddPurposeModalVisible && (
        <Purpose
          isAddPurposeModalVisible={isAddPurposeModalVisible}
          setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
        />
      )}
      {isAddSalesRepModalVisible && (
        <AddSalesRep
          isAddSalesRepModalVisible={isAddSalesRepModalVisible}
          setIsAddSalesRepModalVisible={setIsAddSalesRepModalVisible}
        />
      )}
    </Drawer>
  );
};

export default AddAppointment;
