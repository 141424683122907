import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
const { Option } = Select;

const UpdateMigrationVisa = ({
  countryList,
  record,
  isUpdateMigrationVisaModalVisible,
  setIsUpdateMigrationVisaModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDocument, setSelectedDocument] = useState(null);

  console.log("record record", record);
  const updateMigrationVisaSubmitHandler = (values) => {
    // const formData = {
    //   ...values,
    // };
    const formData = new FormData();

    selectedDocument && formData.append("document", selectedDocument);
    formData.append("subClass", values?.subClass);
    formData.append("country", values?.country);
    formData.append("description", values?.description);
    formData.append("visaCategory", "MIGRATION");

    dispatch({
      type: "UPDATE_MIGRATION_VISA_REQUEST",
      payload: { id: record.id, formData },
      isSuccessFn: true,
      successFunction: () => {
        message.success("Success! migration visa subclass updated");
        form.resetFields();
        setIsUpdateMigrationVisaModalVisible(false);
      },
    });
  };
  return (
    <Modal
      title="Update Migration Visa Class"
      open={isUpdateMigrationVisaModalVisible}
      onCancel={() => {
        setIsUpdateMigrationVisaModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={updateMigrationVisaSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          description: record?.description,
        }}
        fields={[
          {
            name: ["subClass"],
            value: record?.subClass,
          },
          {
            name: ["country"],
            value: record?.country,
          },
        ]}
      >
        <Form.Item
          label="Country"
          name={"country"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select country name!",
            },
          ]}
        >
          <Select placeholder="Country name">
            {countryList?.map((country) => (
              <Select.Option value={country.countryName}>
                {country.countryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visa class"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa class name!",
            },
          ]}
        >
          <Input placeholder="Visa class name" />
        </Form.Item>
        <Form.Item
          label="Description"
          name={"description"}
          style={{ width: "100%" }}
        >
          <ReactQuill
            style={{
              background: "white",
              marginBottom: "2rem",
              height: "150px",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
          />
        </Form.Item>
        <Form.Item
          label="Add New Attachment"
          name="document"
          style={{ paddingTop: "1rem" }}
        >
          <Input
            onChange={(e) => {
              setSelectedDocument(e.target.files[0]);
            }}
            placeholder="Upload New Attachment"
            type="file"
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Migration Visa Class
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateMigrationVisa;
