import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { from, of } from "rxjs";
import { setCookie } from "../Helpers/FrontendHelper";
import Api from "../Helpers/BaseUrlProvider";
import moment from "moment";

const api = new Api();
// Register email
export const registerEmailEpic = (action$) =>
  action$.pipe(
    ofType("REGISTER_EMAIL_REQUEST"),
    mergeMap((action) =>
      from(api.registerEmail(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "REGISTER_EMAIL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "REGISTER_EMAIL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const otpVerificationEpic = (action$) =>
  action$.pipe(
    ofType("OTP_VERIFICATION_REQUEST"),
    mergeMap((action) =>
      from(api.otpVerification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          setCookie("accessToken", response.data.accessToken);

          return of({
            type: "OTP_VERIFICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "OTP_VERIFICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// resend otp
export const resendOtpEpic = (action$) =>
  action$.pipe(
    ofType("RESEND_OTP_REQUEST"),
    mergeMap((action) =>
      from(api.resendOtp(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "RESEND_OTP_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "RESEND_OTP_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// forgot password
// export const forgotPasswordResendOtpEpic = (action$) =>
// action$.pipe(
//   ofType("FORGOT_PASSWORD_RESEND_OTP_REQUEST"),
//   mergeMap((action) =>
//     from(api.forgotPasswordResendOtp(action.payload)).pipe(
//       mergeMap((response) => {
//         console.warn("response", response);
//         return of({
//           type: "FORGOT_PASSWORD_RESEND_OTP_REQUEST_SUCCESS",
//           payload: response.data,
//         });
//       }),
//       catchError((err) => {
//         console.warn(err);
//         return of({
//           type: "FORGOT_PASSWORD_RESEND_OTP_REQUEST_FAILURE",
//           payload: err,
//         });
//       })
//     )
//   )
// );

// forgot password
export const forgotPasswordOtpEpic = (action$) =>
  action$.pipe(
    ofType("FORGOT_PASSWORD_CODE_REQUEST"),
    mergeMap((action) =>
      from(api.forgotPasswordOtp(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FORGOT_PASSWORD_CODE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FORGOT_PASSWORD_CODE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const forgotPasswordUsingCompanyDomainOrURLEpic = (action$) =>
  action$.pipe(
    ofType("FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST"),
    mergeMap((action) =>
      from(api.forgotPasswordUsingCompanyDomainOrURL(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//forgot password otp verification
export const forgotPasswordOtpverificationEpic = (action$) =>
  action$.pipe(
    ofType("FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST"),
    mergeMap((action) =>
      from(api.forgotPasswordOtpverification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response from otp verification", response.data);
          setCookie("accessToken", response.data.accessToken);

          return of({
            type: "FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const forgotPasswordOtpVerificationByCompanyUrlEpic = (action$) =>
  action$.pipe(
    ofType("FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST"),
    mergeMap((action) =>
      from(api.forgotPasswordOtpVerificationByCompanyUrl(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          setCookie("accessToken", response.data.accessToken);

          return of({
            type: "FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
// password changed
export const changePasswordEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(api.changePassword(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_PASSWORD_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_PASSWORD_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const signupEpic = (action$) =>
  action$.pipe(
    ofType("SIGN_UP_REQUEST"),
    mergeMap((action) =>
      from(api.signUpApi(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SIGN_UP_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SIGN_UP_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const authenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("USER_AUTHENTICATION_REQUEST"),
    mergeMap((action) =>
      from(api.authenticateUser(action.payload)).pipe(
        mergeMap((response) => {
          setCookie("accessToken", response.data.token);
          console.warn("response", response);
          return of({
            type: "USER_AUTHENTICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "USER_AUTHENTICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const companyAuthenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("COMPANY_AUTHENTICATION_REQUEST"),
    mergeMap((action) =>
      from(api.companyAuthenticateUser(action.payload)).pipe(
        mergeMap((response) => {
          // setCookie("accessToken", response.data.token);
          console.warn("response", response);
          return of({
            type: "COMPANY_AUTHENTICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "COMPANY_AUTHENTICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const companyEmailValidateEpic = (action$) =>
  action$.pipe(
    ofType("COMPANY_EMAIL_VALIDATION_REQUEST"),
    mergeMap((action) =>
      from(api.companyEmailValidate(action.payload)).pipe(
        mergeMap((response) => {
          // setCookie("accessToken", response.data.token);
          console.warn("response", response);
          return of({
            type: "COMPANY_EMAIL_VALIDATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "COMPANY_EMAIL_VALIDATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const companyEmailAuthenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("COMPANY_EMAIL_AUTHENTICATION_REQUEST"),
    mergeMap((action) =>
      from(api.companyEmailAuthenticateUser(action.payload)).pipe(
        mergeMap((response) => {
          setCookie("accessToken", response.data.token);
          action.isSuccessFn && action.successFunction(response);
          localStorage.setItem(
            "branchId",
            response?.data?.clientInformation?.clientId
          );
          return of({
            type: "COMPANY_EMAIL_AUTHENTICATION_REQUEST_SUCCESS",
            payload: response.data,
            // payload: {
            //   ...response.data,
            //   isFromBranchLogin: action.payload?.isFromBranchLogin ?? false,
            // },
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "COMPANY_EMAIL_AUTHENTICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const companyDomainAuthenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("COMPANY_DOMAIN_AUTHENTICATION_REQUEST"),
    mergeMap((action) =>
      from(api.companyDomainAuthenticateUser(action.payload)).pipe(
        mergeMap((response) => {
          setCookie("accessToken", response.data.token);
          action.isSuccessFn && action.successFunction(response);
          localStorage.setItem(
            "branchId",
            response?.data?.clientInformation?.clientId
          );
          return of({
            type: "COMPANY_DOMAIN_AUTHENTICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "COMPANY_DOMAIN_AUTHENTICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//contacts

export const addContactEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CONTACT_REQUEST"),
    mergeMap((action) =>
      from(api.addContact(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response.data);
          return of({
            type: "ADD_CONTACT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CONTACT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const uploadContactImageEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_CONTACT_IMAGE_REQUEST"),
    mergeMap((action) =>
      from(api.uploadContactImage(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPLOAD_CONTACT_IMAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_CONTACT_IMAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchContactEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CONTACTS_REQUEST",
      "ADD_CONTACT_REQUEST_SUCCESS",
      "UPDATE_CONTACT_REQUEST_SUCCESS",
      "DELETE_CONTACTS_REQUEST_SUCCESS",
      "CHANGE_CONTACTS_STATUS_REQUEST_SUCCESS",
      "UPLOAD_CONTACT_IMAGE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchContact(action.payload)).pipe(
        mergeMap((response) => {
          return of({
            type: "FETCH_CONTACTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONTACTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveContactEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_CONTACTS_REQUEST",
      "ADD_CONTACT_REQUEST_SUCCESS",
      "UPDATE_CONTACT_REQUEST_SUCCESS",
      "DELETE_CONTACTS_REQUEST_SUCCESS",
      "CHANGE_CONTACTS_STATUS_REQUEST_SUCCESS",
      "UPLOAD_CONTACT_IMAGE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveContact(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CONTACTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CONTACTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//change contact status
export const changeContactStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_CONTACTS_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeContactStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_CONTACTS_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_CONTACTS_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const updateContactEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CONTACT_REQUEST"),
    mergeMap((action) =>
      from(api.updateContact(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response.data);
          return of({
            type: "UPDATE_CONTACT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CONTACT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteContactEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_CONTACTS_REQUEST"),
    mergeMap((action) =>
      from(api.deleteContact(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_CONTACTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_CONTACTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const AddCampaignEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.AddCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CAMPAIGN_REQUEST_SUCCESS",
            payload: {
              branchId: action.payload.branchId,
              isHeadBranch: action.payload.isHeadBranch,
            },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//Lead
// fetch all leads

export const fetchLeadsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_LEADS_REQUEST",
      "ADD_LEAD_REQUEST_SUCCESS",
      "ADD_LEAD_REQUEST_FAILURE",
      "DELETE_LEAD_REQUEST_SUCCESS",
      "DELETE_LEAD_REQUEST_FAILURE",
      "UPDATE_LEAD_REQUEST_SUCCESS",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchLeads(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchNotAssignedLeadsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_NOT_ASSIGNED_LEADS_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "UPDATE_LEAD_REQUEST_SUCCESS",
      "DELETE_LEAD_REQUEST_SUCCESS",
      "ADD_LEAD_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchNotAssignedLeads(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_NOT_ASSIGNED_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_NOT_ASSIGNED_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchInProgressLeadsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_IN_PROGRESS_LEADS_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "UPDATE_LEAD_REQUEST_SUCCESS",
      "DELETE_LEAD_REQUEST_SUCCESS",
      "ADD_LEAD_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchInProgressLeads(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_IN_PROGRESS_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_IN_PROGRESS_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchCompletedLeadsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COMPLETED_LEADS_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "UPDATE_LEAD_REQUEST_SUCCESS",
      "DELETE_LEAD_REQUEST_SUCCESS",
      "ADD_LEAD_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCompletedLeads(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPLETED_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPLETED_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchLeadByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_LEAD_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchLeadById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "FETCH_LEAD_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEAD_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// fetch lead dropdown
export const fetchLeadsDropdownEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_LEADS_DROPDOWN_REQUEST",
      "ADD_LEAD_REQUEST_SUCCESS",
      "DELETE_LEAD_REQUEST_SUCCESS",
      "ADD_CONTACT_REQUEST_SUCCESS",
      "ADD_PURPOSE_REQUEST_SUCCESS",
      "ADD_ABOUT_US_REQUEST_SUCCESS",
      "ADD_LEAD_SOURCE_REQUEST_SUCCESS",
      "ADD_PURPOSE_REQUEST_SUCCESS",
      "ADD_LEAD_STATUS_REQUEST_SUCCESS",
      "UPDATE_CONTACT_REQUEST_SUCCESS",
      "ADD_COUNTRY_REQUEST_SUCCESS",
      "ADD_CONSULTANT_REQUEST_SUCCESS",
      "ADD_REFERRAL_REQUEST_SUCCESS",
      "ADD_CAMPAIGN_REQUEST_SUCCESS",
      "ADD_LEVEL_OF_COLLEGE_REQUEST_SUCCESS",
      "ADD_STATE_REQUEST_SUCCESS",
      "ADD_SALES_REP_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchLeadsDropdown(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          action?.payload?.fromInstituteVisaCase &&
            action?.payload?.visaSuccessFunction(response);
          return of({
            type: "FETCH_LEADS_DROPDOWN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEADS_DROPDOWN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// add leads
export const addLeadsEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEAD_REQUEST"),
    mergeMap((action) =>
      from(api.addLeads(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload2?.fromClientsDetails &&
            action?.payload2?.successFunction(response);
          return of({
            type: "ADD_LEAD_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_LEAD_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update lead
export const updateLeadEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_LEAD_REQUEST"),
    mergeMap((action) =>
      from(api.updateLead(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_LEAD_REQUEST_SUCCESS",
            // payload: response?.data?.id,

            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_LEAD_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// delete lead
export const deleteLeadEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_LEAD_REQUEST"),
    mergeMap((action) =>
      from(api.deleteLead(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_LEAD_REQUEST_SUCCESS",
            // payload: response?.data?.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_LEAD_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update lead status
export const updateLeadStatusEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEAD_STATUS_UPDATE_REQUEST"),
    mergeMap((action) =>
      from(api.updateLeadStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.isReload && action.payload?.reloadFn(response);
          return of({
            type: "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
            // payload: response?.data?.id,
            toActivityLogById: true,
            payload: { leadId: action?.payload?.id },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//add aboutUs
export const addAboutUsEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ABOUT_US_REQUEST"),
    mergeMap((action) =>
      from(api.addAboutUs(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_ABOUT_US_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_ABOUT_US_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// add lead source
export const addLeadSourceEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEAD_SOURCE_REQUEST"),
    mergeMap((action) =>
      from(api.addLeadSource(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_LEAD_SOURCE_REQUEST_SUCCESS",
            payload: {
              branchId: action.payload.branchId,
              isHeadBranch: action.payload.isHeadBranch,
            },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_LEAD_SOURCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add country
export const addCountryEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COUNTRY_REQUEST"),
    mergeMap((action) =>
      from(api.addCountry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response);
          return of({
            type: "ADD_COUNTRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COUNTRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//upload country image
export const uploadCountryImageEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_COUNTRY_IMAGE_REQUEST"),
    mergeMap((action) =>
      from(api.uploadCountryImage(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPLOAD_COUNTRY_IMAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_COUNTRY_IMAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//change Country status
export const changeCountryStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_COUNTRY_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeCountryStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_COUNTRY_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_COUNTRY_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
// lead status
export const addLeadStatusEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEAD_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.addLeadStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_LEAD_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_LEAD_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// purpose
export const addPurposeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_PURPOSE_REQUEST"),
    mergeMap((action) =>
      from(api.addPurpose(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_PURPOSE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_PURPOSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// add employee
export const addConsultantEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CONSULTANT_REQUEST"),
    mergeMap((action) =>
      from(api.addConsultant(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          of({ type: "HELOO" });
          return of({
            type: "ADD_CONSULTANT_REQUEST_SUCCESS",
            payload: response.data,
            payload2: { id: response.data.id, values: action.payload.payload2 },
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CONSULTANT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addScheduleEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CONSULTANT_REQUEST_SUCCESS", "UPDATE_EMPLOYEE_REQUEST_SUCCESS"),
    mergeMap((action) =>
      from(api.addSchedule(action.payload2)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_SCHEDULE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SCHEDULE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//get consultant
export const fetchEmployeesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_EMPLOYEE_REQUEST",
      "ADD_EMPLOYEE_REQUEST_SUCCESS",
      "ADD_EMPLOYEE_REQUEST_FAILURE",
      "UPDATE_EMPLOYEE_REQUEST_SUCCESS",
      "UPDATE_EMPLOYEE_REQUEST_FAILURE",
      "TOGGLE_EMPLOYEE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_EMPLOYEE_STATUS_REQUEST_FAILURE",
      "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_FAILURE",
      "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_FAILURE",
      "ADD_SCHEDULE_REQUEST_SUCCESS",
      "ASSIGN_DEPARTMENT_REQUEST_SUCCESS",
      "ADD_PERMISSION_OF_EMPLOYEE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchEmployees(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_EMPLOYEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_EMPLOYEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch active employee
export const fetchActiveEmployeeEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_EMPLOYEE_REQUEST",
      "ADD_EMPLOYEE_REQUEST_SUCCESS",
      "ADD_EMPLOYEE_REQUEST_FAILURE",
      "UPDATE_EMPLOYEE_REQUEST_SUCCESS",
      "UPDATE_EMPLOYEE_REQUEST_FAILURE",
      "TOGGLE_EMPLOYEE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_EMPLOYEE_STATUS_REQUEST_FAILURE",
      "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_FAILURE",
      "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_FAILURE",
      "ADD_SCHEDULE_REQUEST_SUCCESS",
      "ASSIGN_DEPARTMENT_REQUEST_SUCCESS",
      "ADD_PERMISSION_OF_EMPLOYEE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveEmployee(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_EMPLOYEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_EMPLOYEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update employee
export const updateEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(api.updateEmployee(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_EMPLOYEE_REQUEST_SUCCESS",
            payload: response.data,
            payload2: {
              id: action.payload.id,
              values: action.payload.payload2,
            },
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_EMPLOYEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//activate employee
export const employeeActivateConfirmationEpic = (action$) =>
  action$.pipe(
    ofType("EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST"),
    mergeMap((action) =>
      from(api.employeeActivateConfirmation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const employeeDeactivateConfirmationEpic = (action$) =>
  action$.pipe(
    ofType("EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST"),
    mergeMap((action) =>
      from(api.employeeDeactivateConfirmation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const assignDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.assignDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ASSIGN_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ASSIGN_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const AddPermissionOfEmployeeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_PERMISSION_OF_EMPLOYEE_REQUEST"),
    mergeMap((action) =>
      from(api.AddPermissionOfEmployee(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_PERMISSION_OF_EMPLOYEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_PERMISSION_OF_EMPLOYEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const AddPermissionOfDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_PERMISSION_OF_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.AddPermissionOfDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_PERMISSION_OF_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_PERMISSION_OF_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addReferralEpic = (action$) =>
  action$.pipe(
    ofType("ADD_REFERRAL_REQUEST"),
    mergeMap((action) =>
      from(api.addReferral(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_REFERRAL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_REFERRAL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// companies
// fetch companies
export const fetchCompaniesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COMPANIES_REQUEST",
      "ADD_COMPANY_REQUEST_SUCCESS",
      "ADD_COMPANY_REQUEST_FAILURE",
      "DELETE_COMPANY_REQUEST_SUCCESS",
      "DELETE_COMPANY_REQUEST_FAILURE",
      "UPDATE_COMPANY_REQUEST_SUCCESS",
      "UPDATE_COMPANY_REQUEST_FAILURE",
      "TOGGLE_COMPANY_STATUS_REQUEST_SUCCESS",
      "TOGGLE_COMPANY_STATUS_REQUEST_FAILURE",
      "ADD_CASE_PACKAGE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCompanies(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPANIES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPANIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCompanyByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COMPANY_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCompanyByIdApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_COMPANY_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPANY_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveCompaniesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_COMPANIES_REQUEST",
      "ADD_COMPANY_REQUEST_SUCCESS",
      "ADD_COMPANY_REQUEST_FAILURE",
      "DELETE_COMPANY_REQUEST_SUCCESS",
      "DELETE_COMPANY_REQUEST_FAILURE",
      "UPDATE_COMPANY_REQUEST_SUCCESS",
      "UPDATE_COMPANY_REQUEST_FAILURE",
      "TOGGLE_COMPANY_STATUS_REQUEST_SUCCESS",
      "TOGGLE_COMPANY_STATUS_REQUEST_FAILURE",
      "ADD_CASE_PACKAGE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveCompanies(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_COMPANIES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_COMPANIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//toggle company status
// export const toggleCompanyStatusEpic = (action$) =>
//   action$.pipe(
//     ofType("ROGGLE_COMPANY_STATUS_REQUEST"),
//     mergeMap((action) =>
//       from(api.toggleCompanyStatus(action.payload)).pipe(
//         mergeMap((response) => {
//           console.warn("response", response);
//           return of({
//             type: "ROGGLE_COMPANY_STATUS_REQUEST_SUCCESS",
//             payload: response.data,
//           });
//         }),
//         catchError((err) => {
//           console.warn(err);
//           return of({
//             type: "ROGGLE_COMPANY_STATUS_REQUEST_FAILURE",
//             payload: err,
//           });
//         })
//       )
//     )
//   );
export const updateCompaniesEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COMPANY_REQUEST"),
    mergeMap((action) =>
      from(api.updateCompanies(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_COMPANY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_COMPANY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateCompanyPackageEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COMPANY_PACKAGE_REQUEST"),
    mergeMap((action) =>
      from(api.updateCompanyPackageApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_COMPANY_PACKAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_COMPANY_PACKAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updatePackageActiveEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PACKAGE_ACTIVE_REQUEST"),
    mergeMap((action) =>
      from(api.updatePackageActiveApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_PACKAGE_ACTIVE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_PACKAGE_ACTIVE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updatePackageDeactivateEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PACKAGE_DEACTIVATE_REQUEST"),
    mergeMap((action) =>
      from(api.updatePackageDeactiveApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_PACKAGE_DEACTIVATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_PACKAGE_DEACTIVATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteCompaniesEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_COMPANY_REQUEST"),
    mergeMap((action) =>
      from(api.deleteCompanies(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_COMPANY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_COMPANY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const changeCompanyStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_COMPANY_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeCompanyStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_COMPANY_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_COMPANY_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addCompanyEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COMPANY_REQUEST"),
    mergeMap((action) =>
      from(api.addCompany(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_COMPANY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COMPANY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// institute
// fetch institutes
export const fetchInstituteEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_INSTITUTES_REQUEST",
      "ADD_INSTITUTE_REQUEST_SUCCESS",
      "UPDATE_INSTITUTE_REQUEST_SUCCESS",
      "TOGGLE_INSTITUTE_STATUS_REQUEST_SUCCESS",
      "DELETE_INSTITUTE_REQUEST_SUCCESS",
      "UPLOAD_INSTITUTE_IMAGE_REQUEST_SUCCESS",
      "ADD_CASE_PACKAGE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchInstitute(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_INSTITUTES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_INSTITUTES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// fetch active institutes
export const fetchActiveInstituteEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_INSTITUTES_REQUEST",
      "ADD_INSTITUTE_REQUEST_SUCCESS",
      "ADD_INSTITUTE_REQUEST_FAILURE",
      "UPLOAD_INSTITUTE_DOCUMENT_REQUEST_SUCCESS",
      "UPLOAD_INSTITUTE_IMAGE_REQUEST_SUCCESS",
      "UPDATE_INSTITUTE_REQUEST_SUCCESS",
      "UPDATE_INSTITUTE_REQUEST_FAILURE",
      "TOGGLE_INSTITUTE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_INSTITUTE_STATUS_REQUEST_FAILURE",
      "DELETE_INSTITUTE_REQUEST_SUCCESS",
      "DELETE_INSTITUTE_REQUEST_FAILURE",
      "ADD_CASE_PACKAGE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveInstitute(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_INSTITUTES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_INSTITUTES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchInstituteByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_INSTITUTE_BY_ID"),
    mergeMap((action) =>
      from(api.fetchInstituteByIdApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_INSTITUTE_BY_ID_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_INSTITUTE_BY_ID_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const AddInstituteEpic = (action$) =>
  action$.pipe(
    ofType("ADD_INSTITUTE_REQUEST"),
    mergeMap((action) =>
      from(api.AddInstitute(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response);
          return of({
            type: "ADD_INSTITUTE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_INSTITUTE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const uploadInstituteImageEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_INSTITUTE_IMAGE_REQUEST"),
    mergeMap((action) =>
      from(api.uploadInstituteImage(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPLOAD_INSTITUTE_IMAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_INSTITUTE_IMAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const uploadInstituteDocumentEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_INSTITUTE_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.uploadInstituteDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPLOAD_INSTITUTE_DOCUMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_INSTITUTE_DOCUMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const uploadCourseDocumentEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_COURSE_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.uploadCourseDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPLOAD_COURSE_DOCUMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_COURSE_DOCUMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const deleteInstituteDocumentsEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_INSTITUTE_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteInstituteDocuments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_INSTITUTE_DOCUMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_INSTITUTE_DOCUMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//add level of college
export const addLevelOfCollegeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEVEL_OF_COLLEGE_REQUEST"),
    mergeMap((action) =>
      from(api.addLevelOfCollege(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_LEVEL_OF_COLLEGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_LEVEL_OF_COLLEGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update institute
export const UpdateInstituteEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_INSTITUTE_REQUEST"),
    mergeMap((action) =>
      from(api.UpdateInstitute(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_INSTITUTE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_INSTITUTE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//toggle company status
export const toggleCompanyStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_INSTITUTE_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.toggleCompanyStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_INSTITUTE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_INSTITUTE_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// delete istitute
export const deleteInstitueEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_INSTITUTE_REQUEST"),
    mergeMap((action) =>
      from(api.deleteInstitue(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_INSTITUTE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_INSTITUTE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//courses
// fetch all courses
export const fetchCoursesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COURSES_REQUEST",
      "ADD_COURSE_REQUEST_SUCCESS",
      "ADD_COURSE_REQUEST_FAILURE",
      "TOGGLE_COURSE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_COURSE_STATUS_REQUEST_FAILURE",
      "UPDATE_COURSE_REQUEST_SUCCESS",
      "UPDATE_COURSE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchCourses(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          console.warn("response", response);
          return of({
            type: "FETCH_COURSES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCourseByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COURSES_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCourseByIdApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_COURSES_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSES_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// fetch active courses
export const fetchActiveCoursesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_COURSES_REQUEST",
      "ADD_COURSE_REQUEST_SUCCESS",
      "ADD_COURSE_REQUEST_FAILURE",
      "TOGGLE_COURSE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_COURSE_STATUS_REQUEST_FAILURE",
      "UPDATE_COURSE_REQUEST_SUCCESS",
      "UPDATE_COURSE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveCourses(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_ACTIVE_COURSES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_COURSES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//toggle course
export const toggleCourseStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_COURSE_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.toggleCourseStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_COURSE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_COURSE_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//course
export const AddCourseEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COURSE_REQUEST"),
    mergeMap((action) =>
      from(api.AddCourse(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response);

          return of({
            type: "ADD_COURSE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COURSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch courses document checklist
export const fetchCourcesDocumentChecklistEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COURSES_DOCUMENTS_REQUEST",
      // "ADD_COURSES_DOCUMENT_CHECKLIST_REQUEST_SUCCESS",
      "UPDATE_COURSES_DOCUMENT_CHECKLIST_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCourcesDocumentChecklist(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COURSES_DOCUMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSES_DOCUMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add courses document checklist
export const addCoursesDocumentChecklistEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COURSES_DOCUMENT_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.addCoursesDocumentChecklist(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction();
          return of({
            type: "ADD_COURSES_DOCUMENT_CHECKLIST_REQUEST_SUCCESS",
            payload: {
              id: action.payload?.id,
              type: action.payload?.type,
            },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COURSES_DOCUMENT_CHECKLIST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update courses document checklist
export const updateCoursesDocumentChecklistEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COURSES_DOCUMENT_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.updateCoursesDocumentChecklist(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_COURSES_DOCUMENT_CHECKLIST_REQUEST_SUCCESS",
            payload: {
              id: action.payload?.courseIdtoFetch,
              type: action.payload?.type,
            },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_COURSES_DOCUMENT_CHECKLIST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//UPDATE COURSE
export const updateCourseEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COURSE_REQUEST"),
    mergeMap((action) =>
      from(api.updateCourse(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_COURSE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_COURSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addUpdateCourseCommissionEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COMMISSION_REQUEST"),
    mergeMap((action) =>
      from(api.addUpdateCommissionApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_COMMISSION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COMMISSION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// update course
// export const UpdateCourseEpic = (action$) =>
// action$.pipe(
//   ofType("UPDATE_COURSE_REQUEST"),
//   mergeMap((action) =>
//     from(
//       API.post(`/institute/add-courses/${action.payload.id}`, action.payload)
//     ).pipe(
//       mergeMap((response) => {
//         console.warn("response", response);
//         return of({
//           type: "UPDATE_COURSE_REQUEST_SUCCESS",
//           payload: response.data,
//         });
//       }),
//       catchError((err) => {
//         console.warn(err);
//         return of({
//           type: "UPDATE_COURSE_REQUEST_FAILURE",
//           payload: err,
//         });
//       })
//     )
//   )
// );

//profile
export const FetchProfileEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_PROFILE_REQUEST",
      "UPDATE_PROFILE_REQUEST_SUCCESS",
      "UPDATE_PROFILE_REQUEST_FAILURE",
      "CHANGE_PROFILE_IMAGE_REQUEST_SUCCESS",
      "CHANGE_COMPANY_LOGO_REQUEST_SUCCESS",
      "SET_DEFAULT_CURRENCY_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.FetchProfile()).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.isSuccessFn && action.successFunction(response);

          return of({
            type: "FETCH_PROFILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PROFILE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//update profile

export const updateProfileEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PROFILE_REQUEST"),
    mergeMap((action) =>
      from(api.updateProfile(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_PROFILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_PROFILE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCounsellingEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COUNSELLING_REQUEST",
      "ADD_COUNSELLING_REQUEST_SUCCESS",
      "ADD_COUNSELLING_REQUEST_FAILURE",
      "UPDATE_COUNSELLING_REQUEST_SUCCESS",
      "UPDATE_COUNSELLING_REQUEST_FAILURE",
      "DELETE_COUNSELLING_REQUEST_SUCCESS",
      "DELETE_COUNSELLING_REQUEST_FAILURE",
      "TOGGLE_COUNSELLING_STATUS_REQUEST_SUCCESS",
      "TOGGLE_COUNSELLING_STATUS_REQUEST_FAILURE"
      // "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      // "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch active counselling
export const fetchActiveCounsellingEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_COUNSELLING_REQUEST",
      "ADD_COUNSELLING_REQUEST_SUCCESS",
      "ADD_COUNSELLING_REQUEST_FAILURE",
      "UPDATE_COUNSELLING_REQUEST_SUCCESS",
      "UPDATE_COUNSELLING_REQUEST_FAILURE",
      "DELETE_COUNSELLING_REQUEST_SUCCESS",
      "DELETE_COUNSELLING_REQUEST_FAILURE",
      "TOGGLE_COUNSELLING_STATUS_REQUEST_SUCCESS",
      "TOGGLE_COUNSELLING_STATUS_REQUEST_FAILURE"
      // "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      // "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addCounsellingEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COUNSELLING_REQUEST"),
    mergeMap((action) =>
      from(api.addCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//delete counselling
export const deleteCounsellingEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_COUNSELLING_REQUEST"),
    mergeMap((action) =>
      from(api.deleteCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update counselling
export const updateCounsellingEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COUNSELLING_REQUEST"),
    mergeMap((action) =>
      from(api.updateCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//change counselling status
export const changeCounsellingStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_COUNSELLING_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeCounsellingStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_COUNSELLING_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_COUNSELLING_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add state
export const addStateEpic = (action$) =>
  action$.pipe(
    ofType("ADD_STATE_REQUEST"),
    mergeMap((action) =>
      from(api.addState(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_STATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_STATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//appointment
//fetch appointment
export const fetchAppointmentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_APPOINTMENTS_REQUEST",
      "ADD_APPOINTMENTS_REQUEST_SUCCESS",
      "ADD_APPOINTMENTS_REQUEST_FAILURE",
      "UPDATE_APPOINTMENTS_REQUEST_SUCCESS",
      "UPDATE_APPOINTMENTS_REQUEST_FAILURE",
      "CHANGE_APPOINTMENT_STATUS_REQUEST_SUCCESS",
      "CHANGE_APPOINTMENT_STATUS_REQUEST_FAILURE",
      "DELETE_APPOINTMENT_REQUEST_SUCCESS",
      "DELETE_APPOINTMENT_REQUEST_FAILURE",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE",
      "CHANGE_START_COUNSELLING_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAppointments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchAppointmentByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_APPOINTMENT_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAppointmentById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_APPOINTMENT_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENT_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//pending appointments
export const fetchPendingAppointmentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_PENDING_APPOINTMENTS_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "DELETE_APPOINTMENT_REQUEST_SUCCESS",
      "UPDATE_APPOINTMENTS_REQUEST_SUCCESS",
      "ADD_APPOINTMENTS_REQUEST_SUCCESS",
      "CHANGE_START_COUNSELLING_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchPendingAppointments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PENDING_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PENDING_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//confirmed appointments
export const fetchConfirmedAppointmentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CONFIRMED_APPOINTMENTS_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "DELETE_APPOINTMENT_REQUEST_SUCCESS",
      "UPDATE_APPOINTMENTS_REQUEST_SUCCESS",
      "ADD_APPOINTMENTS_REQUEST_SUCCESS",
      "CHANGE_START_COUNSELLING_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchConfirmedAppointments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CONFIRMED_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONFIRMED_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//completed appointments
export const fetchCompletedAppointmentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COMPLETED_APPOINTMENTS_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "DELETE_APPOINTMENT_REQUEST_SUCCESS",
      "UPDATE_APPOINTMENTS_REQUEST_SUCCESS",
      "ADD_APPOINTMENTS_REQUEST_SUCCESS",
      "CHANGE_START_COUNSELLING_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCompletedAppointments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPLETED_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPLETED_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveAppointmentEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_APPOINTMENTS_REQUEST",
      "ADD_APPOINTMENTS_REQUEST_SUCCESS",
      "ADD_APPOINTMENTS_REQUEST_FAILURE",
      "UPDATE_APPOINTMENT_REQUEST_SUCCESS",
      "UPDATE_APPOINTMENT_REQUEST_FAILURE",
      "CHANGE_APPOINTMENT_STATUS_REQUEST_SUCCESS",
      "CHANGE_APPOINTMENT_STATUS_REQUEST_FAILURE",
      "DELETE_APPOINTMENT_REQUEST_SUCCESS",
      "DELETE_APPOINTMENT_REQUEST_FAILURE",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add appointment
export const addAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_APPOINTMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.addAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload2?.fromClientsDetails &&
            action?.payload2?.successFunction(response);
          return of({
            type: "ADD_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update Appointments epic
export const updateAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_APPOINTMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.updateAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response?.data?.id,

            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//change appointment status
export const changeAppointmentStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_APPOINTMENT_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeAppointmentStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_APPOINTMENT_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_APPOINTMENT_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
// //delete appointment
export const deleteAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_APPOINTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_APPOINTMENT_REQUEST_SUCCESS",
            payload: response?.data?.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_APPOINTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
// timing dropdown
export const timingScheduleDropdownEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_TIMING_DROPDOWN_REQUEST"),
    mergeMap((action) =>
      from(api.timingScheduleDropdown(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TIMING_DROPDOWN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TIMING_DROPDOWN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//Head agent
//fetch head agent
export const headAgentEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_HEADAGENT_REQUEST",
      "UPDATE_HEADAGENT_REQUEST_SUCCESS",
      "UPDATE_HEADAGENT_REQUEST_FAILURE",
      "ADD_HEADAGENT_REQUEST_SUCCESS",
      "ADD_HEADAGENT_REQUEST_FAILURE",
      "DELETE_HEADAGENT_REQUEST_SUCCESS",
      "DELETE_HEADAGENT_REQUEST_FAILURE",
      "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.headAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//FETCH ACTIVE HEADAGENT
export const fetchActiveHeadAgentEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_HEADAGENT_REQUEST",
      "UPDATE_HEADAGENT_REQUEST_SUCCESS",
      "UPDATE_HEADAGENT_REQUEST_FAILURE",
      "ADD_HEADAGENT_REQUEST_SUCCESS",
      "ADD_HEADAGENT_REQUEST_FAILURE",
      "DELETE_HEADAGENT_REQUEST_SUCCESS",
      "DELETE_HEADAGENT_REQUEST_FAILURE",
      "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveHeadAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//ADD HEAD AGENT
export const addHeadAgentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_HEADAGENT_REQUEST"),
    mergeMap((action) =>
      from(api.addHeadAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update head agent
export const updateHeadAgentEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_HEADAGENT_REQUEST"),
    mergeMap((action) =>
      from(api.updateHeadAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteHeadAgentEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_HEADAGENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteHeadAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//CHANGE HEADAGENT DISABLED STATUS
export const toggleHeadAgentDisabledEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.toggleHeadAgentDisabled(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_HEADAGENT_DISABLE_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//sub agent
export const fetchSubAgentEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_SUBAGENT_REQUEST",
      "ADD_SUBAGENT_REQUEST_SUCCESS",
      "UPDATE_SUBAGENT_REQUEST_SUCCESS",
      "TOGGLE_SUBAGENT_STATUS_REQUEST_SUCCESS",
      "DELETE_SUBAGENT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchSubAgent()).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch active subagent
export const fetchActiveSubAgentEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_SUBAGENT_REQUEST",
      "ADD_SUBAGENT_REQUEST_SUCCESS",
      "UPDATE_SUBAGENT_REQUEST_SUCCESS",
      "TOGGLE_SUBAGENT_STATUS_REQUEST_SUCCESS",
      "DELETE_SUBAGENT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveSubAgent()).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add subagent
export const addSubAgentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUBAGENT_REQUEST"),
    mergeMap((action) =>
      from(api.addSubAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update subagent
export const updateSubAgentEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SUBAGENT_REQUEST"),
    mergeMap((action) =>
      from(api.updateSubAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const toggleSubAgentStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_SUBAGENT_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.toggleSubAgentStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_SUBAGENT_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_SUBAGENT_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const deleteSubagentEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_SUBAGENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteSubagent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchSupportTicketEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_SUPPORT_TICKET_REQUEST",
      "ADD_SUPPORT_TICKET_REQUEST_SUCCESS",
      "ADD_SUPPORT_TICKET_REQUEST_FAILURE",
      "UPDATE_SUPPORT_TICKET_REQUEST_SUCCESS",
      "UPDATE_SUPPORT_TICKET_REQUEST_FAILURE",
      "TOGGLE_SUPPORT_TICKET_STATUS_REQUEST_SUCCESS",
      "TOGGLE_SUPPORT_TICKET_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchSupportTicket(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_SUPPORT_TICKET_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SUPPORT_TICKET_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveSupportCategoryEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_SUPPORT_CATEGORY_REQUEST",
      "ADD_SUPPORT_CATEGORY_REQUEST_SUCCESS",
      "ADD_SUPPORT_CATEGORY_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveSupportCategory(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_SUPPORT_CATEGORY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_SUPPORT_CATEGORY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//support
export const fetchSupportEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_SUPPORT_REQUEST",
      "ADD_SUPPORT_REQUEST_SUCCESS",
      "ADD_SUPPORT_REQUEST_FAILURE",
      "UPDATE_SUPPORT_TICKET_REQUEST_SUCCESS",
      "UPDATE_SUPPORT_TICKET_REQUEST_FAILURE",
      "TOGGLE_SUPPORT_TICKET_STATUS_REQUEST_SUCCESS",
      "TOGGLE_SUPPORT_TICKET_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchSupport(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_SUPPORT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SUPPORT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//add support category
export const addSupportCategoryEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUPPORT_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(api.addSupportCategory(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_SUPPORT_CATEGORY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SUPPORT_CATEGORY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//add support
export const addSupportEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SUPPORT_REQUEST"),
    mergeMap((action) =>
      from(api.addSupport(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_SUPPORT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SUPPORT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const replyToSupportTicketEpic = (action$) =>
  action$.pipe(
    ofType("REPLY_TO_TICKET_REQUEST"),
    mergeMap((action) =>
      from(api.replyToSupportTicket(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "REPLY_TO_TICKET_REQUEST_SUCCESS",
            payload: { ...response.data, id: action.payload.id },
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "REPLY_TO_TICKET_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchTicketByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_TICKET_BY_ID_REQUEST",
      "REPLY_TO_TICKET_REQUEST_SUCCESS",
      "REPLY_TO_TICKET_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchTicketById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TICKET_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TICKET_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch roles
export const fetchAllRolesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ROLES_REQUEST",
      "ADD_ROLES_REQUEST_SUCCESS",
      "ADD_ROLES_REQUEST_FAILURE",
      "UPDATE_ROLES_REQUEST_SUCCESS",
      "UPDATE_ROLES_REQUEST_FAILURE",
      "TOGGLE_ROLES_STATUS_REQUEST_SUCCESS",
      "TOGGLE_ROLES_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchAllRoles(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ROLES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ROLES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const updateRoleEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ROLE_REQUEST"),
    mergeMap((action) =>
      from(api.updateRole(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_ROLE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_ROLE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch active roles
export const fetchActiveRolesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_ROLES_REQUEST",
      // "ADD_ROLES_REQUEST_SUCCESS",
      // "ADD_ROLES_REQUEST_FAILURE",
      "UPDATE_ROLE_REQUEST_SUCCESS",
      "UPDATE_ROLE_REQUEST_FAILURE",
      "UPDATE_PERMISSION_REQUEST_SUCCESS",
      "UPDATE_PERMISSION_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveRoles(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_ROLES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_ROLES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addRoleEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ROLES_REQUEST"),
    mergeMap((action) =>
      from(api.addRole(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_ROLES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_ROLES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchPermissionsEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PERMISSIONS_REQUEST"),
    mergeMap((action) =>
      from(api.fetchPermissions(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PERMISSIONS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PERMISSIONS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//UPDATE PERMISSION
export const updatePermissionEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PERMISSION_REQUEST"),
    mergeMap((action) =>
      from(api.updatePermission(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_PERMISSION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_PERMISSION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//departments
export const fetchDepartmentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_DEPARTMENT_REQUEST",
      "ADD_DEPARTMENT_REQUEST_SUCCESS",
      "ADD_DEPARTMENT_REQUEST_FAILURE",
      "UPDATE_DEPARTMENT_REQUEST_SUCCESS",
      "UPDATE_DEPARTMENT_REQUEST_FAILURE",
      "ACTIVATE_DEPARTMENT_REQUEST_SUCCESS",
      "ACTIVATE_DEPARTMENT_REQUEST_FAILURE",
      "DEACTIVATE_DEPARTMENT_REQUEST_SUCCESS",
      "DEACTIVATE_DEPARTMENT_REQUEST_FAILURE",
      "ASSIGN_ROLES_REQUEST_SUCCESS",
      "ASSIGN_ROLES_REQUEST_FAILURE",
      "ADD_PERMISSION_OF_DEPARTMENT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchDepartments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//fetch active department
export const fetchActiveDepartmentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_DEPARTMENT_REQUEST",
      "ADD_DEPARTMENT_REQUEST_SUCCESS",
      "UPDATE_DEPARTMENT_REQUEST_SUCCESS",
      "ACTIVATE_DEPARTMENT_REQUEST_SUCCESS",
      "DEACTIVATE_DEPARTMENT_REQUEST_SUCCESS",
      "ADD_PERMISSION_OF_DEPARTMENT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveDepartments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add department
export const addDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.addDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.updateDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const activateDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.activateDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ACTIVATE_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ACTIVATE_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deactivateDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_DEPARTMENT_REQUEST"),
    mergeMap((action) =>
      from(api.deactivateDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DEACTIVATE_DEPARTMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DEACTIVATE_DEPARTMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//assign roles to department
export const assignRolesToDepartmentEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_ROLES_REQUEST"),
    mergeMap((action) =>
      from(api.assignRolesToDepartment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ASSIGN_ROLES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ASSIGN_ROLES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//consultancy
//fetch constutancy
export const fetchConsultancyEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CONSULTANCY_REQUEST",
      "ADD_CONSULTACY_REQUEST_SUCCESS",
      "ADD_CONSULTACY_REQUEST_FAILURE",
      "UPDATE_CONSULTACY_REQUEST_SUCCESS",
      "UPDATE_CONSULTACY_REQUEST_FAILURE",
      "TOGGLE_CONSULTACY_STATUS_REQUEST_SUCCESS",
      "TOGGLE_CONSULTACY_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchConsultancy(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CONSULTANCY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONSULTANCY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//ACTIVE CONSULTANCY
export const fetchActiveConsultancyEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_CONSULTANCY_REQUEST",
      "ADD_CONSULTANCY_REQUEST_SUCCESS",
      "ADD_CONSULTANCY_REQUEST_FAILURE",
      "UPDATE_CONSULTANCY_REQUEST_SUCCESS",
      "UPDATE_CONSULTANCY_REQUEST_FAILURE",
      "TOGGLE_CONSULTANCY_STATUS_REQUEST_SUCCESS",
      "TOGGLE_CONSULTANCY_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveConsultancy(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CONSULTANCY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CONSULTANCY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addConsultancyEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CONSULTANCY_REQUEST"),
    mergeMap((action) =>
      from(api.addConsultancy(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CONSULTANCY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CONSULTANCY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//tasks
//fetch tasks
export const fetchAssignedToTaskEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ASSIGNED_TO_TASK_REQUEST",
      "ADD_TASK_REQUEST_SUCCESS",
      "ADD_TASK_REQUEST_FAILURE",
      "UPDATE_TASK_REQUEST_SUCCESS",
      "UPDATE_TASK_REQUEST_FAILURE",
      "TOGGLE_TASK_STATUS_REQUEST_SUCCESS",
      "TOGGLE_TASK_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchAssignedToTask(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ASSIGNED_TO_TASK_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ASSIGNED_TO_TASK_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAssignedByTaskEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ASSIGNED_BY_TASK_REQUEST",
      "ADD_TASK_REQUEST_SUCCESS",
      "UPDATE_TASK_REQUEST_SUCCESS",
      "TOGGLE_TASK_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAssignedByTask(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ASSIGNED_BY_TASK_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ASSIGNED_BY_TASK_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addtaskEpic = (action$) =>
  action$.pipe(
    ofType("ADD_TASK_REQUEST"),
    mergeMap((action) =>
      from(api.addtask(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_TASK_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_TASK_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateTaskEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_TASK_REQUEST"),
    mergeMap((action) =>
      from(api.updateTask(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_TASK_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_TASK_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//drive or resources
export const fetchPublicDriveEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_PUBLIC_DRIVE_REQUEST",
      "ADD_NEW_FOLDER_REQUEST_SUCCESS",
      "RENAME_FOLDER_REQUEST_SUCCESS",
      "DELETE_FOLDER_REQUEST_SUCCESS",
      "FILE_UPLOAD_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchPublicDrive(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PUBLIC_DRIVE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PUBLIC_DRIVE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchPrivateDriveEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_PRIVATE_DRIVE_REQUEST",
      "ADD_NEW_FOLDER_REQUEST_SUCCESS",
      "RENAME_FOLDER_REQUEST_SUCCESS",
      "DELETE_FOLDER_REQUEST_SUCCESS",
      "FILE_UPLOAD_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchPrivateDrive(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);

          return of({
            type: "FETCH_PRIVATE_DRIVE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PRIVATE_DRIVE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchResourceByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PUBLICBY_ID_DRIVE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchResourceById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PUBLICBY_ID_DRIVE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchPrivateResourceByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRIVATEBY_ID_DRIVE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchPrivateResourceById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PRIVATEBY_ID_DRIVE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PRIVATEBY_ID_DRIVE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const AddNewFolderEpic = (action$) =>
  action$.pipe(
    ofType("ADD_NEW_FOLDER_REQUEST"),
    mergeMap((action) =>
      from(api.AddNewFolder(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_NEW_FOLDER_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_NEW_FOLDER_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const renameFolderEpic = (action$) =>
  action$.pipe(
    ofType("RENAME_FOLDER_REQUEST"),
    mergeMap((action) =>
      from(api.renameFolder(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "RENAME_FOLDER_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "RENAME_FOLDER_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteFolderEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_FOLDER_REQUEST"),
    mergeMap((action) =>
      from(api.deleteFolder(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_FOLDER_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_FOLDER_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fileUploadEpic = (action$) =>
  action$.pipe(
    ofType("FILE_UPLOAD_REQUEST"),
    mergeMap((action) =>
      from(api.fileUpload(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FILE_UPLOAD_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FILE_UPLOAD_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchClientsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CLIENT_REQUEST",
      "ADD_CLIENT_REQUEST_SUCCESS",
      "UPDATE_CLIENT_REQUEST_SUCCESS",
      "TOGGLE_CLIENT_STATUS_REQUEST_SUCCESS",
      "DELETE_CLIENT_REQUEST_SUCCESS",
      "CHANGE_CLIENT_STATUS_REQUEST_SUCCESS",
      "ADD_CLIENT_PHOTO_REQUEST_SUCCESS",
      "UPDATE_CUSTOMER_INFO_REQUEST_SUCCESS",
      "UPDATE_CUSTOMER_COURSE_REQUEST_SUCCESS",
      "ADD_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
      "ADD_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
      "DELETE_EXPERIENCE_REQUEST_SUCCESS",
      "DELETE_QUALIFICATION_REQUEST_SUCCESS",
      "DELETE_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
      "EDIT_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
      "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchClients(action.payloadPage)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CLIENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CLIENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addClientEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CLIENT_REQUEST"),
    mergeMap((action) =>
      from(api.addClient(action.payload)).pipe(
        mergeMap((response) => {
          action.payload.photoPayloadFn(response.data);
          console.warn("response from photo", response);
          return of({
            type: "ADD_CLIENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CLIENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addCustomerPhotoEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CLIENT_PHOTO_REQUEST"),
    mergeMap((action) =>
      from(api.addCustomerPhoto(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CLIENT_PHOTO_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CLIENT_PHOTO_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchVisaEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_VISA_CASES_REQUEST",
      "ADD_VISA_CASE_REQUEST_SUCCESS",
      "UPDATE_VISA_CASE_REQUEST_SUCCESS",
      "TOGGLE_VISA_CASE_STATUS_REQUEST_SUCCESS",
      "UPDATE_CASE_STATUS_REQUEST_SUCCESS",
      "UPLOAD_CASE_DOCUMENTS_REQUEST_SUCCESS",
      "FETCH_FILTER_VISAS_REQUEST",
      "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
      "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
      "ASSIGN_AGENTS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchVisa(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_VISA_CASES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_VISA_CASES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchInProgressFilterVisaEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST",
      // "ADD_VISA_CASE_REQUEST_SUCCESS",
      // "ADD_VISA_CASE_REQUEST_FAILURE",
      // "UPDATE_VISA_CASE_REQUEST_SUCCESS",
      // "UPDATE_VISA_CASE_REQUEST_FAILURE",
      // "TOGGLE_VISA_CASE_STATUS_REQUEST_SUCCESS",
      // "TOGGLE_VISA_CASE_STATUS_REQUEST_FAILURE",
      // "UPDATE_CASE_STATUS_REQUEST_SUCCESS",
      // "UPDATE_CASE_STATUS_REQUEST_FAILURE",
      // "UPLOAD_CASE_DOCUMENTS_REQUEST_SUCCESS",
      "ASSIGN_AGENTS_REQUEST_SUCCESS",
      "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
      "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
      "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchInProgressFilter(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchNotAssignedFilterVisaEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST",
      // "ADD_VISA_CASE_REQUEST_SUCCESS",
      // "ADD_VISA_CASE_REQUEST_FAILURE",
      // "UPDATE_VISA_CASE_REQUEST_SUCCESS",
      // "UPDATE_VISA_CASE_REQUEST_FAILURE",
      // "TOGGLE_VISA_CASE_STATUS_REQUEST_SUCCESS",
      // "TOGGLE_VISA_CASE_STATUS_REQUEST_FAILURE",
      // "UPDATE_CASE_STATUS_REQUEST_SUCCESS",
      // "UPDATE_CASE_STATUS_REQUEST_FAILURE",
      // "UPLOAD_CASE_DOCUMENTS_REQUEST_SUCCESS",
      "ASSIGN_AGENTS_REQUEST_SUCCESS",
      "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
      "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
      "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchNotAssignedFilterVisa(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchCompletedFilterVisaEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COMPLETED_FILTER_VISAS_REQUEST",
      // "ADD_VISA_CASE_REQUEST_SUCCESS",
      // "ADD_VISA_CASE_REQUEST_FAILURE",
      // "UPDATE_VISA_CASE_REQUEST_SUCCESS",
      // "UPDATE_VISA_CASE_REQUEST_FAILURE",
      // "TOGGLE_VISA_CASE_STATUS_REQUEST_SUCCESS",
      // "TOGGLE_VISA_CASE_STATUS_REQUEST_FAILURE",
      // "UPDATE_CASE_STATUS_REQUEST_SUCCESS",
      // "UPDATE_CASE_STATUS_REQUEST_FAILURE",
      // "UPLOAD_CASE_DOCUMENTS_REQUEST_SUCCESS",
      "ASSIGN_AGENTS_REQUEST_SUCCESS",
      "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
      "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
      "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCompletedFilterVisa(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPLETED_FILTER_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPLETED_FILTER_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch workflow and document Checklist by id
export const fetchCountryWorkflowByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCountryWorkflowById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action?.successFunction(response);
          !action?.isSuccessFn && action.payload2(response);
          return of({
            type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchWorkflowByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_WORKFLOW_BY_ID_REQUEST",
      "ADD_WORKFLOW_STATUS_REQUEST_SUCCESS",
      "UPDATE_WORKFLOW_STATUS_REQUEST_SUCCESS",
      "CHANGE_STATUS_AS_FIRST_REQUEST_SUCCESS",
      "CHANGE_STATUS_AS_FINAL_REQUEST_SUCCESS",
      "CHANGE_STATUS_AS_CANCELLED_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchWorkflowById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response);
          return of({
            type: "FETCH_WORKFLOW_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_WORKFLOW_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchWorkflowStatusByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST",
      "CHANGE_STATUS_AS_FIRST_REQUEST_SUCCESS",
      "CHANGE_STATUS_AS_FINAL_REQUEST_SUCCESS",
      "CHANGE_STATUS_AS_CANCELLED_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchWorkflowStatusById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.fromVisaCases &&
            action?.payload?.responseFirstnodeSelectFn(response);
          return of({
            type: "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addEditInstituteAgreementDate = (action$) =>
  action$.pipe(
    ofType("ADD_AGREEMENTDATE_REQUEST"),
    mergeMap((action) =>
      from(api.addEditAgreementApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_AGREEMENTDATE_REQUEST_SUCCESS",
            payload: action.payload,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_AGREEMENTDATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//add workflow status
export const addWorkflowStatusEpic = (action$) =>
  action$.pipe(
    ofType("ADD_WORKFLOW_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.addWorkflowStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_WORKFLOW_STATUS_REQUEST_SUCCESS",
            payload: { id: action.payload?.id },
            payload2: action.payload2,
            payload3: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_WORKFLOW_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//update workflow status
export const updateWorkflowStatusEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_WORKFLOW_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.updateWorkflowStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_WORKFLOW_STATUS_REQUEST_SUCCESS",
            payload: { id: action.payload?.fetchId },
            payload2: action.payload2,
            payload3: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_WORKFLOW_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//change workflow status as first
export const changeWorkflowStatusAsFirstEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_STATUS_AS_FIRST_REQUEST"),
    mergeMap((action) =>
      from(api.changeWorkflowStatusAsFirst(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_STATUS_AS_FIRST_REQUEST_SUCCESS",
            payload: { id: action?.payload?.workflowId },
            payload2: action.payload2,
            payload3: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_STATUS_AS_FIRST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//change workflow status as final
export const changeWorkflowStatusAsFinalEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_STATUS_AS_FINAL_REQUEST"),
    mergeMap((action) =>
      from(api.changeWorkflowStatusAsFinal(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_STATUS_AS_FINAL_REQUEST_SUCCESS",
            payload: { id: action?.payload?.workflowId },
            payload2: action.payload2,
            payload3: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_STATUS_AS_FINAL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const changeWorkflowStatusAsCancelledEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_STATUS_AS_CANCELLED_REQUEST"),
    mergeMap((action) =>
      from(api.changeWorkflowStatusAsCancelled(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_STATUS_AS_CANCELLED_REQUEST_SUCCESS",
            payload: { id: action?.payload?.workflowId },
            payload2: action.payload2,
            payload3: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_STATUS_AS_CANCELLED_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// fetch case dropdown
export const fetchCasesDropdownEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CASES_DROPDOWN_REQUEST",
      "ADD_VISA_CASE_REQUEST_SUCCESS",
      "UPDATE_VISA_CASE_REQUEST_SUCCESS",
      "TOGGLE_VISA_CASE_STATUS_REQUEST_SUCCESS",
      "ADD_VISA_SUBCATEGORY_REQUEST_SUCCESS",
      "ADD_SALES_REP_REQUEST_SUCCESS",
      "ADD_INSTITUTE_REQUEST_SUCCESS",
      "ADD_DEGREE_REQUEST_SUCCESS",
      "ADD_COURSE_REQUEST_SUCCESS",
      "ADD_CASE_PACKAGE_REQUEST_SUCCESS",
      "ADD_INTAKE_REQUEST_SUCCESS",
      "ADD_ORDER_STATUS_REQUEST_SUCCESS",
      "ADD_LEAD_TYPE_REQUEST_SUCCESS",
      "ADD_CONSULTANT_REQUEST_SUCCESS",
      "ADD_HEADAGENT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCasesDropdown(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.fromInstituteVisaCase &&
            action?.payload?.visaSuccessFunction(response);
          return of({
            type: "FETCH_CASES_DROPDOWN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CASES_DROPDOWN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchCaseLogByWorkflowDtoEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCaseLogByWorkflowDto(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CASE_LOG_BY_WORKFLOWDTO_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchWorkflowStatusesByWorkflowIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchWorkflowStatusesByWorkflowId(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_WORKFLOW_STATUSES_BY_WORKFLOW_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update case workflow status by workflow id also update case log
export const updateCaseStatusByWorkflowIdEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CASE_STATUS_CASE_LOG_REQUEST"),
    mergeMap((action) =>
      from(api.updateCaseStatusByWorkflowId(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isFetchCaseLogFN &&
            action.payload?.fetchCaseLogFN(response);
          return of({
            type: "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateCaseDataByWorkflowIdEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CLIENT_CASE_REQUEST"),
    mergeMap((action) =>
      from(api.updateCaseDataByWorkflowIdApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction();
          return of({
            type: "UPDATE_CLIENT_CASE_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CLIENT_CASE_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addCaseAsignToEmailEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ASIGNTO_EMAIL_REQUEST"),
    mergeMap((action) =>
      from(api.addCaseAsignToEmailApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction();
          return of({
            type: "ADD_ASIGNTO_EMAIL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_ASIGNTO_EMAIL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update visa outcome status
export const updateVisaOutcomeStatusEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_VISA_OUTCOME_REQUEST"),
    mergeMap((action) =>
      from(api.updateVisaOutcomeStatus(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_VISA_OUTCOME_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//upload case documents in document checklist in cases visa
export const uploadCaseDocumentsEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_CASE_DOCUMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.uploadCaseDocuments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload2(response);
          return of({
            type: "UPLOAD_CASE_DOCUMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_CASE_DOCUMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//upload image fullpath for uplad case documents
export const uploadURLfromUploadResponseEpic = (action$) =>
  action$.pipe(
    ofType("UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST"),
    mergeMap((action) =>
      from(api.uploadURLfromUploadResponse(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.isSuccessFn && action?.payload?.successFn(response);
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addVisaSubCategoryEpic = (action$) =>
  action$.pipe(
    ofType("ADD_VISA_SUBCATEGORY_REQUEST"),
    mergeMap((action) =>
      from(api.addVisaSubCategory(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_VISA_SUBCATEGORY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_VISA_SUBCATEGORY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addSalesRepNameEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SALES_REP_REQUEST"),
    mergeMap((action) =>
      from(api.addSalesRepName(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_SALES_REP_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SALES_REP_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const AddDegreeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DEGREE_REQUEST"),
    mergeMap((action) =>
      from(api.AddDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          // action.payload2(response);
          return of({
            type: "ADD_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//fetch active case package
export const fetchActiveCasePackageEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_CASE_PACKAGE_REQUEST",
      "ADD_CASE_PACKAGE_REQUEST_SUCCESS"
    ),

    mergeMap((action) =>
      from(api.fetchActiveCasePackage(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addCasePackageEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CASE_PACKAGE_REQUEST"),
    mergeMap((action) =>
      from(api.addCasePackage(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_CASE_PACKAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CASE_PACKAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//courses by institute and degree
export const fetchCoursesByInstituteAndDegreeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCoursesByInstituteAndDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCoursesListByInstituteAndDegreeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COURSESLIST_BY_INSTITUTE_AND_DEGREE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCoursesListByInstituteAndDegree(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_COURSESLIST_BY_INSTITUTE_AND_DEGREE_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSESLIST_BY_INSTITUTE_AND_DEGREE_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//update case status
export const updateCaseStatusEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CASE_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.updateCaseStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_CASE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CASE_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const AddIntakeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_INTAKE_REQUEST"),
    mergeMap((action) =>
      from(api.AddIntake(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_INTAKE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_INTAKE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addLeadTypeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEAD_TYPE_REQUEST"),
    mergeMap((action) =>
      from(api.addLeadType(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_LEAD_TYPE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_LEAD_TYPE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addOrderStatusEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ORDER_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.addOrderStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_ORDER_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_ORDER_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add visa cases
export const addVisaCaseEpic = (action$) =>
  action$.pipe(
    ofType("ADD_VISA_CASE_REQUEST"),
    mergeMap((action) =>
      from(api.addVisaCase(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload2?.fromClientsDetails &&
            action?.payload2?.successFunction(response);
          return of({
            type: "ADD_VISA_CASE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_VISA_CASE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCountryEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_COUNTRY_REQUEST",
      "ADD_COUNTRY_REQUEST_SUCCESS",
      "ADD_COUNTRY_REQUEST_FAILURE",
      "UPDATE_COUNTRY_REQUEST_SUCCESS",
      "UPDATE_COUNTRY_REQUEST_FAILURE",
      "CHANGE_COUNTRY_STATUS_REQUEST_SUCCESS",
      "CHANGE_COUNTRY_STATUS_REQUEST_FAILURE",
      "MARK_COUNTRY_AS_DEFAULT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCountry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COUNTRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COUNTRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveCountriesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_COUNTRY_REQUEST",
      "ADD_COUNTRY_REQUEST_SUCCESS",
      "ADD_COUNTRY_REQUEST_FAILURE",
      "UPDATE_COUNTRY_REQUEST_SUCCESS",
      "UPDATE_COUNTRY_REQUEST_FAILURE",
      "CHANGE_COUNTRY_STATUS_REQUEST_SUCCESS",
      "CHANGE_COUNTRY_STATUS_REQUEST_FAILURE",
      "MARK_COUNTRY_AS_DEFAULT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveCountries(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_COUNTRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_COUNTRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const markCountryAsDefaultEpic = (action$) =>
  action$.pipe(
    ofType("MARK_COUNTRY_AS_DEFAULT_REQUEST"),
    mergeMap((action) =>
      from(api.markCountryAsDefault(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "MARK_COUNTRY_AS_DEFAULT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "MARK_COUNTRY_AS_DEFAULT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addMigrationWorkflowEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MIGRATION_WORKFLOW_REQUEST"),
    mergeMap((action) =>
      from(api.addMigrationWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_MIGRATION_WORKFLOW_REQUEST_SUCCESS",
            payload: { id: action.payload.id },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_MIGRATION_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchMigrationWorkflowEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_MIGRATION_WORKFLOW_REQUEST",
      "ADD_MIGRATION_WORKFLOW_REQUEST_SUCCESS",
      "ADD_MIGRATION_WORKFLOW_REQUEST_FAILURE",
      "TOGGLE_MIGRATION_WORKFLOW_STATUS_REQUEST_SUCCESS",
      "TOGGLE_MIGRATION_WORKFLOW_STATUS_REQUEST_FAILURE",
      "SET_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
      "SET_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
      "DELETE_MIGRATION_WORKFLOW_REQUEST_SUCCESS", //not wrong hai in case you wonder migration hudaima wrong hoina
      "DELETE_MIGRATION_WORKFLOW_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchMigrationWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_MIGRATION_WORKFLOW_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MIGRATION_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteMigrationWorkflowEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_MIGRATION_WORKFLOW_REQUEST"),
    mergeMap((action) =>
      from(api.deleteMigrationWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isReloadFn && action.payload?.reloadFn(response);
          return of({
            type: "DELETE_MIGRATION_WORKFLOW_REQUEST_SUCCESS",
            payload2: response.data,
            payload: { id: action.payload.countryId },
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_MIGRATION_WORKFLOW_REQUEST_FAILURE",
            payload: { id: action.payload.countryId },
            payload2: err,
          });
        })
      )
    )
  );

export const updateMigrationWorkflowEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_MIGRATION_WORKFLOW_REQUEST"),
    mergeMap((action) =>
      from(api.updateMigrationWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isReloadFn && action.payload?.reloadFn(response);
          return of({
            type: "UPDATE_MIGRATION_WORKFLOW_REQUEST_SUCCESS",
            payload: { id: action.payload.countryId },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_MIGRATION_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const setWorkflowAsDefaultEpic = (action$) =>
  action$.pipe(
    ofType("SET_WORKFLOW_AS_DEFAULT_REQUEST"),
    mergeMap((action) =>
      from(api.setWorkflowAsDefault(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SET_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
            payload: { id: action.payload.countryId },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SET_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
            payload: { id: action.payload.countryId },
            payload2: err,
          });
        })
      )
    )
  );

export const setEducationWorkflowAsDefaultEpic = (action$) =>
  action$.pipe(
    ofType("SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST"),
    mergeMap((action) =>
      from(api.setEducationWorkflowAsDefault(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
            payload: { id: action.payload.countryId },

            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
            payload: { id: action.payload.countryId },

            payload2: err,
          });
        })
      )
    )
  );
export const setOthersWorkflowAsDefaultEpic = (action$) =>
  action$.pipe(
    ofType("SET_OTHERS_WORKFLOW_AS_DEFAULT_REQUEST"),
    mergeMap((action) =>
      from(api.setOthersWorkflowAsDefault(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isReloadFn && action.payload?.reloadFn(response);
          return of({
            type: "SET_OTHERS_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SET_OTHERS_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add document
export const addDocumentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DOCUMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.addDocument(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction();
          console.warn("response", response);
          return of({
            type: "ADD_DOCUMENTS_REQUEST_SUCCESS",
            payload: { id: action.payload.workflowId },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_DOCUMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch documents
export const fetchDocumentsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_DOCUMENTS_REQUEST",
      "ADD_DOCUMENTS_REQUEST_SUCCESS",

      "UPDATE_DOCUMENT_REQUEST_SUCCESS",
      "UPDATE_DOCUMENT_REQUEST_FAILURE",

      "DELETE_DOCUMENT_REQUEST_SUCCESS",
      "DELETE_DOCUMENT_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchDocuments(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action?.successFunction();
          return of({
            type: "FETCH_DOCUMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DOCUMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateDocumentEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.updateDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_DOCUMENT_REQUEST_SUCCESS",
            payload: { id: action.payload.workflowId },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_DOCUMENT_REQUEST_FAILURE",
            payload: { id: action.payload.workflowId },
            payload2: err,
          });
        })
      )
    )
  );

//delete documents
export const deleteDocumentEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_DOCUMENT_REQUEST_SUCCESS",
            payload: { id: action.payload.workflowId },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_DOCUMENT_REQUEST_FAILURE",
            payload: { id: action.payload.workflowId },
            payload2: err,
          });
        })
      )
    )
  );

export const fetchEducationWorkflowEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_EDUCATION_WORKFLOW_REQUEST",
      "ADD_EDUCATION_WORKFLOW_REQUEST_SUCCESS",
      "TOGGLE_EDUCATION_WORKFLOW_STATUS_REQUEST_SUCCESS",
      "TOGGLE_EDUCATION_WORKFLOW_STATUS_REQUEST_FAILURE",
      "SET_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
      "SET_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
      "DELETE_EDUCATION_WORKFLOW_REQUEST_SUCCESS",
      "DELETE_EDUCATION_WORKFLOW_REQUEST_FAILURE",
      "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
      "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
      "DELETE_MIGRATION_WORKFLOW_REQUEST_SUCCESS", //not wrong hai in case you wonder migration hudaima wrong hoina
      "DELETE_MIGRATION_WORKFLOW_REQUEST_FAILURE",
      "UPDATE_MIGRATION_WORKFLOW_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchEducationWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_EDUCATION_WORKFLOW_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_EDUCATION_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchOthersWorkflowEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_OTHERS_WORKFLOW_REQUEST"
      // "ADD_EDUCATION_WORKFLOW_REQUEST_SUCCESS",
      // "TOGGLE_EDUCATION_WORKFLOW_STATUS_REQUEST_SUCCESS",
      // "TOGGLE_EDUCATION_WORKFLOW_STATUS_REQUEST_FAILURE",
      // "SET_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
      // "SET_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
      // "DELETE_EDUCATION_WORKFLOW_REQUEST_SUCCESS",
      // "DELETE_EDUCATION_WORKFLOW_REQUEST_FAILURE",
      // "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST_FAILURE",
      // "SET_EDUCATION_WORKFLOW_AS_DEFAULT_REQUEST_SUCCESS",
      // "DELETE_MIGRATION_WORKFLOW_REQUEST_SUCCESS", //not wrong hai in case you wonder migration hudaima wrong hoina
      // "DELETE_MIGRATION_WORKFLOW_REQUEST_FAILURE",
      // "UPDATE_MIGRATION_WORKFLOW_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchOthersWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_OTHERS_WORKFLOW_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_OTHERS_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addEducationWorkflowEpic = (action$) =>
  action$.pipe(
    ofType("ADD_EDUCATION_WORKFLOW_REQUEST"),
    mergeMap((action) =>
      from(api.addEducationWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_EDUCATION_WORKFLOW_REQUEST_SUCCESS",
            payload: { id: action?.payload?.id },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_EDUCATION_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addOthersWorkflowEpic = (action$) =>
  action$.pipe(
    ofType("ADD_OTHERS_WORKFLOW_REQUEST"),
    mergeMap((action) =>
      from(api.addOthersWorkflow(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isReloadFn && action.payload?.reloadFn(response);
          return of({
            type: "ADD_OTHERS_WORKFLOW_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_OTHERS_WORKFLOW_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchDocumentTypesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_DOCUMENT_TYPES_REQUEST",
      "ADD_DOCUMENT_TYPE_REQUEST_SUCCESS",
      "ADD_DOCUMENT_TYPE_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchDocumentTypes(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_DOCUMENT_TYPES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DOCUMENT_TYPES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addDocumentTypeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DOCUMENT_TYPE_REQUEST"),
    mergeMap((action) =>
      from(api.addDocumentType(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_DOCUMENT_TYPE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_DOCUMENT_TYPE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addClientQualificationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CLIENT_QUALIFICATION_REQUEST"),
    mergeMap((action) =>
      from(api.addClientQualification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CLIENT_QUALIFICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CLIENT_QUALIFICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//upload document client add document
export const addClientDocumentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CLIENT_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.addClientDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CLIENT_DOCUMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CLIENT_DOCUMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveClientsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_CLIENT_REQUEST",
      "ADD_CLIENT_REQUEST_SUCCESS",
      "ADD_CLIENT_REQUEST_FAILURE",
      "UPDATE_CLIENT_REQUEST_SUCCESS",
      "UPDATE_CLIENT_REQUEST_FAILURE",
      "TOGGLE_CLIENT_STATUS_REQUEST_SUCCESS",
      "TOGGLE_CLIENT_STATUS_REQUEST_FAILURE",
      "DELETE_CLIENT_REQUEST_SUCCESS",
      "DELETE_CLIENT_REQUEST_FAILURE",
      "CHANGE_CLIENT_STATUS_REQUEST_SUCCESS",
      "ADD_CLIENT_PHOTO_REQUEST_SUCCESS",
      "UPDATE_CUSTOMER_INFO_REQUEST_SUCCESS",
      "UPDATE_CUSTOMER_COURSE_REQUEST_SUCCESS",
      "ADD_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
      "ADD_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
      "DELETE_EXPERIENCE_REQUEST_SUCCESS",
      "DELETE_QUALIFICATION_REQUEST_SUCCESS",
      "DELETE_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
      "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS",
      "EDIT_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveClients(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CLIENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CLIENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const deleteClientEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_CLIENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteClient(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_CLIENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_CLIENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchPackageEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PACKAGE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchPackage(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PACKAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PACKAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch dashboard date request
export const fetchDashboardDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DASHBOARD_DATA_REQUEST"),
    mergeMap((action) =>
      from(api.fetchDashboardData(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_DASHBOARD_DATA_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DASHBOARD_DATA_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchDashboardDataListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DASHBOARD_DATA_LIST_REQUEST"),
    mergeMap((action) =>
      from(api.fetchDashboardDataList(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_DASHBOARD_DATA_LIST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DASHBOARD_DATA_LIST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//purchase subscription epic
export const purchaseSubscriptionEpic = (action$) =>
  action$.pipe(
    ofType("PURCHASE_SUBSCRIPTION_REQUEST"),
    mergeMap((action) =>
      from(api.purchaseSubscription(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "PURCHASE_SUBSCRIPTION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update workflow status priority
export const updateWorkflowStatusPriorityEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_WORKFLOW_STATUS_PRIORITY_REQUEST"),
    mergeMap((action) =>
      from(api.updateWorkflowStatusPriority(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_WORKFLOW_STATUS_PRIORITY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_WORKFLOW_STATUS_PRIORITY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//change start counselling status
export const changeStartCounsellingEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_START_COUNSELLING_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeStartCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isReloadFn && action?.reloadFn(response);
          return of({
            type: "CHANGE_START_COUNSELLING_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_START_COUNSELLING_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//fetch lead customer cases and lead by id
export const fetchCustomerLeadByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST"
      // "DELETE_LEAD_REQUEST_SUCCESS",
      // "UPDATE_LEAD_REQUEST_SUCCESS"
      // "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCustomerLeadById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchCustomerAppointmentByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST"
      // "UPDATE_APPOINTMENTS_REQUEST_SUCCESS"
      // "DELETE_APPOINTMENT_REQUEST_SUCCESS",
      // "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCustomerAppointmentById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCustomerCasesByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CASE_CUSTOMER_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCustomerCasesById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CASE_CUSTOMER_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CASE_CUSTOMER_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//college finder
export const fetchAllCountryEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ALL_COUNTRY_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAllCountry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ALL_COUNTRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_COUNTRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAllDegreeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ALL_DEGREE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAllDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ALL_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAllCourseEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ALL_COURSE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAllCourse(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ALL_COURSE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_COURSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAllIntakeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ALL_INTAKE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAllIntake(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ALL_INTAKE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_INTAKE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAllCollegeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ALL_COLLEGE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAllCollege(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_ALL_COLLEGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_COLLEGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const changeClientStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_CLIENT_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeClientStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_CLIENT_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_CLIENT_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update customer information
export const updateCustomerInformatiionEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CUSTOMER_INFO_REQUEST"),
    mergeMap((action) =>
      from(api.updateCustomerInformatiion(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_CUSTOMER_INFO_REQUEST_SUCCESS",

            payload: action.payload.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CUSTOMER_INFO_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update customer course
export const updateCustomerCourseEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CUSTOMER_COURSE_REQUEST"),
    mergeMap((action) =>
      from(api.updateCustomerCourse(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_CUSTOMER_COURSE_REQUEST_SUCCESS",
            payload: action.payload.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CUSTOMER_COURSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateCustomerQualificationEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST"),
    mergeMap((action) =>
      from(api.updateCustomerQualification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS",
            payload: action.payload.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add customer qualification
export const addCustomerQualificationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CUSTOMER_EXPERIENCE_REQUEST"),
    mergeMap((action) =>
      from(api.addCustomerQualification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
            payload: action.payload.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CUSTOMER_EXPERIENCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addCustomerDocumentEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CUSTOMER_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.addCustomerDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
            payload: action.payload.id,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_CUSTOMER_DOCUMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteCustomerQualificationEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_QUALIFICATION_REQUEST"),
    mergeMap((action) =>
      from(api.deleteCustomerQualification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_QUALIFICATION_REQUEST_SUCCESS",
            payload: action.payload.clientId,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_QUALIFICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const deleteCustomerExperienceEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_EXPERIENCE_REQUEST"),
    mergeMap((action) =>
      from(api.deleteCustomerExperience(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_EXPERIENCE_REQUEST_SUCCESS",
            payload: action.payload.clientId,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_EXPERIENCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteCustomerDocumentEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_CUSTOMER_DOCUMENT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteCustomerDocument(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
            payload: action.payload.clientId,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_CUSTOMER_DOCUMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const editCustomerExperienceEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_CUSTOMER_EXPERIENCE_REQUEST"),
    mergeMap((action) =>
      from(api.editCustomerExperience(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "EDIT_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
            payload: action.payload.clientId,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "EDIT_CUSTOMER_EXPERIENCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const editCustomerQualificationsEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_CUSTOMER_QUALIFICATIONS_REQUEST"),
    mergeMap((action) =>
      from(api.editCustomerQualifications(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS",
            payload: action.payload.clientId,
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchVisasSubclassEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_VISAS_SUBCLASS_REQUEST"),
    mergeMap((action) =>
      from(api.fetchVisaSubclass(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_VISAS_SUBCLASS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_VISAS_SUBCLASS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchEductionVisasEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_EDUCATION_VISAS_REQUEST",
      "ADD_EDUCATION_VISA_REQUEST_SUCCESS",
      "UPDATE_EDUCATION_VISAS_REQUEST_SUCCESS",
      "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchEductionVisas(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response)
          return of({
            type: "FETCH_EDUCATION_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_EDUCATION_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchEductionVisasByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_EDUCATION_VISAS_BYID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchEductionVisasByIdApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_EDUCATION_VISAS_BYID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_EDUCATION_VISAS_BYID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchMigrationVisasEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_MIGRATION_VISAS_REQUEST",
      "ADD_MIGRATION_VISA_REQUEST_SUCCESS",
      "UPDATE_MIGRATION_VISA_REQUEST_SUCCESS",
      "DELETE_MIGRATION_VISAS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchMigrationVisas(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response)
          return of({
            type: "FETCH_MIGRATION_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MIGRATION_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addEducationVisaEpic = (action$) =>
  action$.pipe(
    ofType("ADD_EDUCATION_VISA_REQUEST"),
    mergeMap((action) =>
      from(api.addEducationVisa(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_EDUCATION_VISA_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_EDUCATION_VISA_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addMigrationVisaEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MIGRATION_VISA_REQUEST"),
    mergeMap((action) =>
      from(api.addMigrationVisa(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_MIGRATION_VISA_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_MIGRATION_VISA_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateEducationVisasEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_EDUCATION_VISAS_REQUEST"),
    mergeMap((action) =>
      from(api.updateEducationVisas(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_EDUCATION_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_EDUCATION_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateMigrationVisasEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_MIGRATION_VISA_REQUEST"),
    mergeMap((action) =>
      from(api.updateEducationVisas(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction();
          return of({
            type: "UPDATE_MIGRATION_VISA_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_MIGRATION_VISA_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteMigrationVisaEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_MIGRATION_VISAS_REQUEST"),
    mergeMap((action) =>
      from(api.deleteMigrationVisa(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_MIGRATION_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_MIGRATION_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteEducationVisaEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_EDUCATION_VISAS_REQUEST"),
    mergeMap((action) =>
      from(api.deleteMigrationVisa(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_EDUCATION_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const assignAgentsEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_AGENTS_REQUEST"),
    mergeMap((action) =>
      from(api.assignAgents(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ASSIGN_AGENTS_REQUEST_SUCCESS",
            payload: "NOT_ASSIGNED",
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ASSIGN_AGENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchClientByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CLIENT_BY_ID_REQUEST",
      "UPDATE_CUSTOMER_INFO_REQUEST_SUCCESS",
      "UPDATE_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS",
      "ADD_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
      "ADD_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
      "EDIT_CUSTOMER_EXPERIENCE_REQUEST_SUCCESS",
      "EDIT_CUSTOMER_QUALIFICATIONS_REQUEST_SUCCESS",
      "DELETE_QUALIFICATION_REQUEST_SUCCESS",
      "DELETE_EXPERIENCE_REQUEST_SUCCESS",
      "DELETE_CUSTOMER_DOCUMENT_REQUEST_SUCCESS",
      "UPDATE_CUSTOMER_COURSE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchClientById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CLIENT_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CLIENT_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchClientActivityLogByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchClientActivityLogById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchMigrationVisaCasesEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST"),
    mergeMap((action) =>
      from(api.fetchMigrationVisaCases(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MIGRATION_VISA_TYPE_CASES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchEducationVisaCasesEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST"),
    mergeMap((action) =>
      from(api.fetchEducationVisaCases(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_EDUCATION_VISA_TYPE_CASES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const startCounsellingEpic = (action$) =>
  action$.pipe(
    ofType("START_COUNSELLING_REQUEST"),
    mergeMap((action) =>
      from(api.startCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.reloadAppointmentByLeadId &&
            action.payload?.reloadAppointmentByLeadFn(response);
          return of({
            type: "START_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "START_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchInstituteBasedOnLocationTypeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST"),
    mergeMap((action) =>
      from(api.fetchInstituteBasedOnLocationType(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchDegreeByInstituteFilterEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DEGREE_BY_INSTITUTE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchDegreeByInstituteFilter(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          console.warn("response", response);
          return of({
            type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addTodoEpic = (action$) =>
  action$.pipe(
    ofType("ADD_TODO_REQUEST"),
    mergeMap((action) =>
      from(api.addTodo(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_TODO_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_TODO_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchTodoEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_TODO_REQUEST",
      "ADD_TODO_REQUEST_SUCCESS",
      "CHANGE_TODO_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchTodo(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TODO_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TODO_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const changeTodoStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_TODO_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeTodoStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_TODO_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_TODO_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveConsultantsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_CONSULTANTS_REQUEST",
      "UPDATE_EMPLOYEE_REQUEST_SUCCESS",
      "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "ADD_CONSULTANT_REQUEST_SUCCESS",
      "ASSIGN_DEPARTMENT_REQUEST_SUCCESS",
      "ADD_PERMISSION_OF_EMPLOYEE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveConsultants(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CONSULTANTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CONSULTANTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//atttendence
export const fetchAttendanceEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ATTENDANCE_REQUEST",
      "CHECK_IN_REQUEST_SUCCESS",
      "CHECK_OUT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAttendance(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          // action?.payload?.isCheckinResponse && action?.payload?.checkinResponseFn(response)
          return of({
            type: "FETCH_ATTENDANCE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ATTENDANCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const checkInEpic = (action$) =>
  action$.pipe(
    ofType("CHECK_IN_REQUEST"),
    mergeMap((action) =>
      from(api.checkIn(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHECK_IN_REQUEST_SUCCESS",
            payload: { date: moment().local().format("YYYY-MM-DD") },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err, "err");

          return of({
            type: "CHECK_IN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const checkOutEpic = (action$) =>
  action$.pipe(
    ofType("CHECK_OUT_REQUEST"),
    mergeMap((action) =>
      from(api.checkOut(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHECK_OUT_REQUEST_SUCCESS",
            payload: { date: moment().local().format("YYYY-MM-DD") },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHECK_OUT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//report attendance
export const fetchReportEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_REPORT_REQUEST"),
    mergeMap((action) =>
      from(api.fetchReport(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_REPORT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_REPORT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//attandance of all employee
export const fetchAttandanceOfAllEmployeeEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST",
      "CHECK_IN_REQUEST_SUCCESS",
      "CHECK_OUT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAttandanceOfAllEmployee(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ATTENDANCE_OF_EMPLOYEES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchTodayAssignedTaskEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_TODAY_ASSIGNED_TASK_REQUEST"),
    mergeMap((action) =>
      from(api.fetchTodayAssignedTask(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TODAY_ASSIGNED_TASK_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TODAY_ASSIGNED_TASK_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchMyCounsellingEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_MY_COUNSELLING_REQUEST"),
    mergeMap((action) =>
      from(api.fetchMyCounselling(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_MY_COUNSELLING_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MY_COUNSELLING_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// export const fetchMyCasesInProgressEpic = (action$) =>
//   action$.pipe(
//     ofType(
//       "FETCH_MY_CASES_IN_PROGRESS_REQUEST",
//       "ASSIGN_AGENTS_REQUEST_SUCCESS",
//       "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
//       "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
//       "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS"
//     ),
//     mergeMap((action) =>
//       from(api.fetchMyCasesInProgress(action.payload)).pipe(
//         mergeMap((response) => {
//           console.warn("response", response);
//           return of({
//             type: "FETCH_MY_CASES_IN_PROGRESS_REQUEST_SUCCESS",
//             payload: response.data,
//           });
//         }),
//         catchError((err) => {
//           console.warn(err);
//           return of({
//             type: "FETCH_MY_CASES_IN_PROGRESS_REQUEST_FAILURE",
//             payload: err,
//           });
//         })
//       )
//     )
//   );
// export const fetchMyCasesCompletedEpic = (action$) =>
//   action$.pipe(
//     ofType(
//       "FETCH_MY_CASES_COMPLETED_REQUEST",
//       "ASSIGN_AGENTS_REQUEST_SUCCESS",
//       "UPDATE_VISA_OUTCOME_REQUEST_SUCCESS",
//       "UPDATE_CASE_STATUS_CASE_LOG_REQUEST_SUCCESS",
//       "UPLOAD_URL_FROM_UPLOAD_RESPONSE_REQUEST_SUCCESS"
//     ),
//     mergeMap((action) =>
//       from(api.fetchMyCasesCompleted(action.payload)).pipe(
//         mergeMap((response) => {
//           console.warn("response", response);
//           return of({
//             type: "FETCH_MY_CASES_COMPLETED_REQUEST_SUCCESS",
//             payload: response.data,
//           });
//         }),
//         catchError((err) => {
//           console.warn(err);
//           return of({
//             type: "FETCH_MY_CASES_COMPLETED_REQUEST_FAILURE",
//             payload: err,
//           });
//         })
//       )
//     )
//   );

export const changeProfileImageEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PROFILE_IMAGE_REQUEST"),
    mergeMap((action) =>
      from(api.changeProfileImage(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_PROFILE_IMAGE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_PROFILE_IMAGE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const changeCompanyLogoEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_COMPANY_LOGO_REQUEST"),
    mergeMap((action) =>
      from(api.changeCompanyLogo(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_COMPANY_LOGO_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_COMPANY_LOGO_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchBranchEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_BRANCH_REQUEST",
      "ADD_BRANCH_REQUEST_SUCCESS",
      "ADD_BRANCH_REQUEST_FAILURE",
      "UPDATE_BRANCH_REQUEST_SUCCESS",
      "UPDATE_BRANCH_REQUEST_FAILURE",
      "TOGGLE_BRANCH_STATUS_REQUEST_SUCCESS",
      "TOGGLE_BRANCH_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchBranch(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.isReloadFn && action?.payload?.reloadFn(response);
          return of({
            type: "FETCH_BRANCH_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_BRANCH_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// export const fetchActiveBranchEpic = (action$) =>
//   action$.pipe(
//     ofType(
//       "FETCH_ACTIVE_REQUEST",
//       "ADD__REQUEST_SUCCESS",
//       "ADD__REQUEST_FAILURE",
//       "UPDATE__REQUEST_SUCCESS",
//       "UPDATE__REQUEST_FAILURE",
//       "TOGGLE__STATUS_REQUEST_SUCCESS",
//       "TOGGLE__STATUS_REQUEST_FAILURE"
//     ),
//     mergeMap((action) =>
//       from(api.fetchActiveBranch(action.payload)).pipe(
//         mergeMap((response) => {
//           console.warn("response", response);
//           return of({
//             type: "FETCH_ACTIVE_REQUEST_SUCCESS",
//             payload: response.data,
//           });
//         }),
//         catchError((err) => {
//           console.warn(err);
//           return of({
//             type: "FETCH_ACTIVE_REQUEST_FAILURE",
//             payload: err,
//           });
//         })
//       )
//     )
//   );

export const addBranchEpic = (action$) =>
  action$.pipe(
    ofType("ADD_BRANCH_REQUEST"),
    mergeMap((action) =>
      from(api.addBranch(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_BRANCH_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_BRANCH_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateBranchEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_BRANCH_REQUEST"),
    mergeMap((action) =>
      from(api.updateBranch(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_BRANCH_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_BRANCH_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//terms and privacy policy
export const fetchPrivacyPolicyEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRIVACY_POLICY_REQUEST"),
    mergeMap((action) =>
      from(api.fetchPrivacyPolicy(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PRIVACY_POLICY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PRIVACY_POLICY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//search contacts
export const searchContactsEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CONTACTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchContacts(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CONTACTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONTACTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchActiveContactsEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_CONTACTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveContacts(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CONTACTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CONTACTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchLeadEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_LEADS_REQUEST"),
    mergeMap((action) =>
      from(api.searchLead(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchNotAssignedLeadEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_NOT_ASSIGNED_LEADS_REQUEST"),
    mergeMap((action) =>
      from(api.searchNotAssignedLead(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_NOT_ASSIGNED_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_NOT_ASSIGNED_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchInProgressLeadEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_IN_PROGRESS_LEADS_REQUEST"),
    mergeMap((action) =>
      from(api.searchInProgressLead(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_IN_PROGRESS_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_IN_PROGRESS_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchCompletedLeadEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COMPLETED_LEADS_REQUEST"),
    mergeMap((action) =>
      from(api.searchCompletedLead(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPLETED_LEADS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPLETED_LEADS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_APPOINTMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchPendingAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_PENDING_APPOINTMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchPendingAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PENDING_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PENDING_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchConfirmedAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CONFIRMED_APPOINTMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchConfirmedAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CONFIRMED_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONFIRMED_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchCompletedAppointmentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COMPLETED_APPOINTMENTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchCompletedAppointment(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPLETED_APPOINTMENTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPLETED_APPOINTMENTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchCasesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CASES_REQUEST"),
    mergeMap((action) =>
      from(api.searchCases(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_VISA_CASES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_VISA_CASES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchInProgressCasesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_IN_PROGRESS_CASES_REQUEST"),
    mergeMap((action) =>
      from(api.searchInProgressCases(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchNotAssignedCasesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_NOT_ASSIGNED_CASES_REQUEST"),
    mergeMap((action) =>
      from(api.searchNotAssignedCases(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchCompletedCasesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COMPLETED_CASES_REQUEST"),
    mergeMap((action) =>
      from(api.searchCompletedCases(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPLETED_FILTER_VISAS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPLETED_FILTER_VISAS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchClientsEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CLIENTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchClients(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CLIENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CLIENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchActiveClientsEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_CLIENTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveClients(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CLIENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CLIENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchEmployeesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_EMPLOYEES_REQUEST"),
    mergeMap((action) =>
      from(api.searchEmployees(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_EMPLOYEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_EMPLOYEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchActiveEmployeesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_EMPLOYEES_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveEmployees(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_EMPLOYEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_EMPLOYEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchActiveConsultantsEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_CONSULTANTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveConsultants(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CONSULTANTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CONSULTANTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchConsultantsEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_CONSULTANTS_REQUEST"),
    mergeMap((action) =>
      from(api.searchConsultants(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CONSULTANTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONSULTANTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchInstituesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_INSTITUTES_REQUEST"),
    mergeMap((action) =>
      from(api.searchInstitues(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_INSTITUTES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_INSTITUTES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchActiveInstitutesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_INSTITUTES_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveInstitutes(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_INSTITUTES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_INSTITUTES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchCoursesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COURSES_REQUEST"),
    mergeMap((action) =>
      from(api.searchCourses(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COURSES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchActiveCoursesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_COURSES_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveCourses(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_COURSES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_COURSES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchHeadAgentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_HEAD_AGENT_REQUEST"),
    mergeMap((action) =>
      from(api.searchHeadAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchActiveHeadAgentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_HEAD_AGENT_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveHeadAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_HEADAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_HEADAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchSubAgentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_SUB_AGENT_REQUEST"),
    mergeMap((action) =>
      from(api.searchSubAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchActiveSubAgentEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_SUB_AGENT_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveSubAgent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_SUBAGENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_SUBAGENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchCompaniesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_COMPANIES_REQUEST"),
    mergeMap((action) =>
      from(api.searchCompanies(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPANIES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPANIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchActiveCompaniesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ACTIVE_COMPANIES_REQUEST"),
    mergeMap((action) =>
      from(api.searchActiveCompanies(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_COMPANIES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_COMPANIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const searchTaskEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_TASKS_REQUEST"),
    mergeMap((action) =>
      from(api.searchTask(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ASSIGNED_TO_TASK_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ASSIGNED_TO_TASK_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//fetch email template
export const fetchLeadEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST",
      "ADD_EMAIL_TEMPLATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchLeadEmailTemplate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successPayload(response);
          return of({
            type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEAD_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQuotationEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QUOTATION_EMAIL_TEMPLATE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQuotationEmailTemplate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successPayload(response);
          return of({
            type: "FETCH_QUOTATION_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QUOTATION_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCaseEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CASE_EMAIL_TEMPLATE_REQUEST",
      "ADD_EMAIL_TEMPLATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchCaseEmailTemplate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successPayload(response);

          return of({
            type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CASE_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchAppointmentEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST",
      "ADD_EMAIL_TEMPLATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAppointmentEmailTemplate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successPayload(response);

          return of({
            type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENT_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchClientEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST",
      "ADD_EMAIL_TEMPLATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchClientEmailTemplate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successPayload(response);

          return of({
            type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CLIENT_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchServiceEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_SERVICE_EMAIL_TEMPLATE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchServiceEmailTemplateApi(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successPayload(response);

          return of({
            type: "FETCH_SERVICE_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SERVICE_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType("ADD_EMAIL_TEMPLATE_REQUEST"),
    mergeMap((action) =>
      from(api.addEmailTemplate(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_EMAIL_TEMPLATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_EMAIL_TEMPLATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateDashboardSettingsEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_DASHBOARD_SETTINGS_REQUEST"),
    mergeMap((action) =>
      from(api.updateDashboardSettings(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_DASHBOARD_SETTINGS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_DASHBOARD_SETTINGS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const importDropdownDataFromHeadOfficeEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST"),
    mergeMap((action) =>
      from(api.importDropdownDataFromHeadOffice(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update prefix
export const updatePrefixEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PREFIX_REQUEST"),
    mergeMap((action) =>
      from(api.updatePrefix(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_PREFIX_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_PREFIX_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//reminder, expiry date, working hours
export const fetchReminderExpiryWorkingHourEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST_SUCCESS",
      "ADD_OPENING_SCHEDULE_REQUEST_SUCCESS",
      "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchReminderExpiryWorkingHour(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.isSuccessFn && action.successFunction(response);

          return of({
            type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const updateReminderExpiryWorkingHourEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST"),
    mergeMap((action) =>
      from(api.updateReminderExpiryWorkingHour(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//mailbox
//inbox

export const fetchMailboxInboxEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_MAILBOX_INBOX_REQUEST", "MARK_MAIL_AS_SEEN_REQUEST_SUCCESS"),
    mergeMap((action) =>
      from(api.fetchMailboxInbox(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          if (action.type === "FETCH_MAILBOX_INBOX_REQUEST") {
            action.payload?.mailSuccessFunction(response);
          }
          return of({
            type: "FETCH_MAILBOX_INBOX_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MAILBOX_INBOX_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
//sent
export const fetchMailboxSentEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_MAILBOX_SENT_REQUEST",
      "SEND_COMPOSED_MAIL_REQUEST_SUCCESS",
      "REPLY_TO_MAIL_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchMailboxSent(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_MAILBOX_SENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MAILBOX_SENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const sendComposedEmailEpic = (action$) =>
  action$.pipe(
    ofType("SEND_COMPOSED_MAIL_REQUEST"),
    mergeMap((action) =>
      from(api.sendComposedEmail(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SEND_COMPOSED_MAIL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SEND_COMPOSED_MAIL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const replyToEmailEpic = (action$) =>
  action$.pipe(
    ofType("REPLY_TO_MAIL_REQUEST"),
    mergeMap((action) =>
      from(api.replyToEmail(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "REPLY_TO_MAIL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "REPLY_TO_MAIL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//draft
export const fetchMailboxDraftEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_MAILBOX_DRAFT_REQUEST",
      "ADD_MAIL_TO_DRAFT_REQUEST_SUCCESS",
      "DELETE_DRAFT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchMailboxDraft(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_MAILBOX_DRAFT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_MAILBOX_DRAFT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add to draft
export const addMailToDraftEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MAIL_TO_DRAFT_REQUEST"),
    mergeMap((action) =>
      from(api.addMailToDraft(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_MAIL_TO_DRAFT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_MAIL_TO_DRAFT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteDraftEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_DRAFT_REQUEST"),
    mergeMap((action) =>
      from(api.deleteDraft(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_DRAFT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_DRAFT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const markMailAsSeenEpic = (action$) =>
  action$.pipe(
    ofType("MARK_MAIL_AS_SEEN_REQUEST"),
    mergeMap((action) =>
      from(api.markMailAsSeen(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "MARK_MAIL_AS_SEEN_REQUEST_SUCCESS",
            payload: {
              page: action.payload.page,
              pageSize: action.payload.pageSize,
            },
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "MARK_MAIL_AS_SEEN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchInstituteForCoursesEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_INSTITUTE_FOR_COURSES_REQUEST"),
    mergeMap((action) =>
      from(api.searchInstituteForCourses(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SEARCH_INSTITUTE_FOR_COURSES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SEARCH_INSTITUTE_FOR_COURSES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAvailableEmailEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_AVAILABLE_EMAILS_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAvailableEmail(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_AVAILABLE_EMAILS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_AVAILABLE_EMAILS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const downloadCSVEpic = (action$) =>
  action$.pipe(
    ofType("DOWNLOAD_CSV_REQUEST"),
    mergeMap((action) =>
      from(api.downloadCSV(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.fromClient && action.CSVSuccessFunction(response);
          return of({
            type: "DOWNLOAD_CSV_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DOWNLOAD_CSV_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const downloadTaskCSVEpic = (action$) =>
  action$.pipe(
    ofType("DOWNLOAD_TASK_CSV_REQUEST"),
    mergeMap((action) =>
      from(api.downloadTaskCSV(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.fromTask && action?.CSVSuccessFunction(response);
          return of({
            type: "DOWNLOAD_TASK_CSV_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DOWNLOAD_TASK_CSV_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const downleadLeadCSVEpic = (action$) =>
  action$.pipe(
    ofType("DOWNLOAD_LEAD_CSV_REQUEST"),
    mergeMap((action) =>
      from(api.downleadLeadCSV(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.fromLead && action?.CSVSuccessFunction(response);
          return of({
            type: "DOWNLOAD_LEAD_CSV_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DOWNLOAD_LEAD_CSV_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const setDefaultCurrencyEpic = (action$) =>
  action$.pipe(
    ofType("SET_DEFAULT_CURRENCY_REQUEST"),
    mergeMap((action) =>
      from(api.setDefaultCurrency(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SET_DEFAULT_CURRENCY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SET_DEFAULT_CURRENCY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//degree

export const fetchDegreeEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_DEGREE_REQUEST",
      "ADD_DEGREE_REQUEST_SUCCESS",
      "UPDATE_DEGREE_REQUEST_SUCCESS",
      "TOGGLE_DEGREE_STATUS_REQUEST_SUCCESS",
      "DELETE_DEGREE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveDegreeEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_DEGREE_REQUEST",
      "ADD_DEGREE_REQUEST_SUCCESS",
      "UPDATE_DEGREE_REQUEST_SUCCESS",
      "TOGGLE_DEGREE_STATUS_REQUEST_SUCCESS",
      "DELETE_DEGREE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const changeDegreeStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_DEGREE_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeDegreeStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_DEGREE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_DEGREE_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateDegreeEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_DEGREE_REQUEST"),
    mergeMap((action) =>
      from(api.updateDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const deleteDegreeEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_DEGREE_REQUEST"),
    mergeMap((action) =>
      from(api.deleteDegree(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_DEGREE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_DEGREE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCampaignEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CAMPAIGN_REQUEST",
      "ADD_CAMPAIGN_REQUEST_SUCCESS",
      "ADD_CAMPAIGN_REQUEST_FAILURE",
      "UPDATE_CAMPAIGN_REQUEST_SUCCESS",
      "UPDATE_CAMPAIGN_REQUEST_FAILURE",
      "TOGGLE_CAMPAIGN_STATUS_REQUEST_SUCCESS",
      "TOGGLE_CAMPAIGN_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveCampaignEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_CAMPAIGN_REQUEST",
      "ADD_CAMPAIGN_REQUEST_SUCCESS",
      "ADD_CAMPAIGN_REQUEST_FAILURE",
      "UPDATE_CAMPAIGN_REQUEST_SUCCESS",
      "UPDATE_CAMPAIGN_REQUEST_FAILURE",
      "TOGGLE_CAMPAIGN_STATUS_REQUEST_SUCCESS",
      "TOGGLE_CAMPAIGN_STATUS_REQUEST_FAILURE"
    ),
    mergeMap((action) =>
      from(api.fetchActiveCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const changeCampaignStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_CAMPAIGN_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeCampaignStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_CAMPAIGN_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_CAMPAIGN_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateCampaignEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.updateCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addLeadFromCampaignEpic = (action$) =>
  action$.pipe(
    ofType("ADD_LEAD_FROM_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.addLeadFromCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.responseSuccessFunction(response);
          return of({
            type: "ADD_LEAD_FROM_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          action?.payload?.responseErrorFunction(err);

          return of({
            type: "ADD_LEAD_FROM_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQRcodeOfCampaignEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QR_CODE_OF_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQRcodeOfCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isSuccessFn && action.successFunction(response);
          return of({
            type: "FETCH_QR_CODE_OF_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QR_CODE_OF_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchFormDetailsOfCampaignEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.fetchFormDetailsOfCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_FORM_DETAILS_OF_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCampainFormDropdownEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCampainFormDropdown(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isSucessFn &&
            action.payload?.successFunction(response);
          return of({
            type: "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CAMPAIGN_FORM_DROPDOWN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateAutoreminderSettingsEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_AUTOREMINDER_SETTINGS_REQUEST"),
    mergeMap((action) =>
      from(api.updateAutoreminderSettings(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_AUTOREMINDER_SETTINGS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_AUTOREMINDER_SETTINGS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAutoReminderDataEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_AUTOREMINDER_DATA_REQUEST",
      "UPDATE_AUTOREMINDER_SETTINGS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAutoReminderData(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "FETCH_AUTOREMINDER_DATA_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_AUTOREMINDER_DATA_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const verifyPurchaseEpic = (action$) =>
  action$.pipe(
    ofType("VERIFY_SUBSCRIPTION_PURCHASE_REQUEST"),
    mergeMap((action) =>
      from(api.verifyPurchase(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "VERIFY_SUBSCRIPTION_PURCHASE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "VERIFY_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const changeTimezoneEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_TIMEZONE_REQUEST"),
    mergeMap((action) =>
      from(api.changeTimezone(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHANGE_TIMEZONE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_TIMEZONE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchTodayRemindersEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_TODAY_REMINDER_REQUEST"),
    mergeMap((action) =>
      from(api.fetchTodayReminders(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TODAY_REMINDER_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TODAY_REMINDER_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchNotificationEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_NOTIFICATION_REQUEST"),
    mergeMap((action) =>
      from(api.fetchNotification(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_NOTIFICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_NOTIFICATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const globalSearchFromDashboardEpic = (action$) =>
  action$.pipe(
    ofType("GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST"),
    mergeMap((action) =>
      from(api.globalSearchFromDashboard(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchLeadActivityLogByIdEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST",
      "ADD_LEAD_STATUS_UPDATE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchLeadActivityLogById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST_SUCCESS",
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const importCLientCsvFileEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_CLIENT_CSV_FILE_REQUEST"),
    mergeMap((action) =>
      from(api.importCLientCsvFile(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "IMPORT_CLIENT_CSV_FILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_CLIENT_CSV_FILE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const importLeadCsvFileEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_LEAD_CSV_FILE_REQUEST"),
    mergeMap((action) =>
      from(api.importLeadCsvFile(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "IMPORT_LEAD_CSV_FILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_LEAD_CSV_FILE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const importTaskCsvFileEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_TASK_CSV_FILE_REQUEST"),
    mergeMap((action) =>
      from(api.importTaskCsvFile(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "IMPORT_TASK_CSV_FILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_TASK_CSV_FILE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addAdditionalEmailsFrmGSEpic = (action$) =>
  action$.pipe(
    ofType("ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST"),
    mergeMap((action) =>
      from(api.addAdditionalEmailsFrmGS(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const searchInstitutesFrmAdminEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_INSTITUTE_FROM_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(api.searchInstitutesFrmAdmin(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SEARCH_INSTITUTE_FROM_ADMIN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SEARCH_INSTITUTE_FROM_ADMIN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const importInstituteFromAdminEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_INSTITUTES_FROM_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(api.importInstituteFromAdmin(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "IMPORT_INSTITUTES_FROM_ADMIN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_INSTITUTES_FROM_ADMIN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addOpeningScheduleEpic = (action$) =>
  action$.pipe(
    ofType("ADD_OPENING_SCHEDULE_REQUEST"),
    mergeMap((action) =>
      from(api.addOpeningSchedule(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_OPENING_SCHEDULE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_OPENING_SCHEDULE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const importInstitutesFromAdminEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(api.importInstitutesFromAdmin(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAppointmentsByLeadIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAppointmentsByLeadId(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addCourseWishlistForLeadIdEpic = (action$) =>
  action$.pipe(
    ofType("ADD_TO_COURSE_WISHLIST_REQUEST"),
    mergeMap((action) =>
      from(api.addCourseWishlistForLeadId(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isReload && action.payload?.reloadFn(Response);
          return of({
            type: "ADD_TO_COURSE_WISHLIST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_TO_COURSE_WISHLIST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCourseWishlistByLeadIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCourseWishlistByLeadId(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteCourseFromWishlistEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_COURSE_FROM_WISHLIST_REQUEST"),
    mergeMap((action) =>
      from(api.deleteCourseFromWishlist(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.isReload && action?.reloadFn(response);
          return of({
            type: "DELETE_COURSE_FROM_WISHLIST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_COURSE_FROM_WISHLIST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const checkForExistingEmailEpic = (action$) =>
  action$.pipe(
    ofType("CHECK_FOR_EXISTING_EMAIL_REQUEST"),
    mergeMap((action) =>
      from(api.checkForExistingEmail(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHECK_FOR_EXISTING_EMAIL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHECK_FOR_EXISTING_EMAIL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const checkForExistingMobileEpic = (action$) =>
  action$.pipe(
    ofType("CHECK_FOR_EXISTING_MOBILE_REQUEST"),
    mergeMap((action) =>
      from(api.checkForExistingMobile(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "CHECK_FOR_EXISTING_MOBILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHECK_FOR_EXISTING_MOBILE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const statusHistoryStatusUpdateEpic = (action$) =>
  action$.pipe(
    ofType("STATUS_HISTORY_ONLY_UPDATE_REQUEST"),
    mergeMap((action) =>
      from(api.statusHistoryStatusUpdate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isReloadFn && action.payload?.reloadFn(response);
          return of({
            type: "STATUS_HISTORY_ONLY_UPDATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "STATUS_HISTORY_ONLY_UPDATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteIntakeFromCourseEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_INTAKE_FROM_COURSE_REQUEST"),
    mergeMap((action) =>
      from(api.deleteIntakeFromCourse(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_INTAKE_FROM_COURSE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_INTAKE_FROM_COURSE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCourseForAnInstituteEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COURSES_FOR_INSTITUTE_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCourseForAnInstitute(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COURSES_FOR_INSTITUTE_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COURSES_FOR_INSTITUTE_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCaseByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CASE_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCaseById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CASE_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CASE_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const deleteBonusFromInstituteEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_BONUS_FROM_INSTITUTE_REQUEST"),
    mergeMap((action) =>
      from(api.deleteBonusFromInstitute(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DELETE_BONUS_FROM_INSTITUTE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_BONUS_FROM_INSTITUTE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchConsultantsEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_CONSULTANTS_REQUEST",
      "UPDATE_EMPLOYEE_REQUEST_SUCCESS",
      "EMPLOYEE_ACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "EMPLOYEE_DEACTIVATE_CONFIRMATION_REQUEST_SUCCESS",
      "ADD_CONSULTANT_REQUEST_SUCCESS",
      "ASSIGN_DEPARTMENT_REQUEST_SUCCESS",
      "ADD_PERMISSION_OF_EMPLOYEE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchConsultants(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_CONSULTANTS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONSULTANTS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addAppointmentFormFieldsForCampaignEpic = (action$) =>
  action$.pipe(
    ofType("ADD_APPOINTMENT_FORM_FIELDS_REQUEST"),
    mergeMap((action) =>
      from(api.addAppointmentFormFieldsForCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_APPOINTMENT_FORM_FIELDS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_APPOINTMENT_FORM_FIELDS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQRcodeForAppointmentFormOfCampaignEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QR_CODE_OF_APPOINTMENT_FORM_OF_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQRcodeForAppointmentFormOfCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "FETCH_QR_CODE_OF_APPOINTMENT_FORM_OF_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QR_CODE_OF_APPOINTMENT_FORM_OF_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchQRcodeForConsultantOfAppointmentFormEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQRcodeForConsultantOfAppointmentForm(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QR_CODE_OF_CONSULTANT_OF_APPOINTMENT_FORM_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAppointmentFormDetailsOfCampaignEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.fetchAppointmentFormDetailsOfCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isSuccessFn && action?.payload?.successfn(response);
          return of({
            type: "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_APPOINTMENT_FORM_DETAILS_OF_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchTimingDropdownForCampaignEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.fetchTimingDropdownForCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TIMING_DROPDOWN_FOR_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const addAppointmentFromCampaignEpic = (action$) =>
  action$.pipe(
    ofType("ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(api.addAppointmentFromCampaign(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.responseSuccessFunction(response);
          return of({
            type: "ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          action?.payload?.responseErrorFunction(err);
          return of({
            type: "ADD_APPOINTMENT_FROM_CAMPAIGN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCompanyLogoAndFooterDetailsEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCompanyLogoAndFooterDetails(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COMPANY_DETAILS_FOR_LOGO_AND_FOOTER_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchAllLeadStatusesEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ALL_LEAD_STATUSES_REQUEST",
      "SET_LEAD_STATUS_AS_DEFAULT_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAllLeadStatuses(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ALL_LEAD_STATUSES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_LEAD_STATUSES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAllEmailTemplateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ALL_EMAIL_TEMPLATE"),
    mergeMap((action) =>
      from(api.fetchAllEmailTemplateApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_ALL_EMAIL_TEMPLATE_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_EMAIL_TEMPLATE_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const setLeadStatusAsDefaultEpic = (action$) =>
  action$.pipe(
    ofType("SET_LEAD_STATUS_AS_DEFAULT_REQUEST"),
    mergeMap((action) =>
      from(api.setLeadStatusAsDefault(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "SET_LEAD_STATUS_AS_DEFAULT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "SET_LEAD_STATUS_AS_DEFAULT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchActiveBranchEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACTIVE_BRANCH_REQUEST",
      "TOGGLE_BRANCH_STATUS_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchActiveBranch(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACTIVE_BRANCH_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACTIVE_BRANCH_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const changeBranchStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_BRANCH_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.changeBranchStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "TOGGLE_BRANCH_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_BRANCH_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCounsellorForGivenDateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCounsellorForGivenDate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload?.isSuccessFun &&
            action?.payload?.successFun(response);
          return of({
            type: "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_COUNSELLOR_FOR_APPOINTMENT_DATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addCompanyDomainUrlEpic = (action$) =>
  action$.pipe(
    ofType("ADD_COMPANY_DOMAIN_URL_REQUEST"),
    mergeMap((action) =>
      from(api.addCompanyDomainUrl(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_COMPANY_DOMAIN_URL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_COMPANY_DOMAIN_URL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchCompanyDetaislsEpic = (action$) =>
  action$.pipe(
    ofType("COMPANY_DETAILS_REQUEST"),
    mergeMap((action) =>
      from(api.fetchCompanyDetaisls(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "COMPANY_DETAILS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "COMPANY_DETAILS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQuotationEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_QUOTATION_REQUEST",
      "ADD_QUOTATION_REQUEST_SUCCESS",
      "APPROVE_QUOTATION_REQUEST_SUCCESS",
      "UPDATE_QUOTATION_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchQuotation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_QUOTATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QUOTATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addQuotationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_QUOTATION_REQUEST"),
    mergeMap((action) =>
      from(api.addQuotation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_QUOTATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_QUOTATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addTimelineEpic = (action$) =>
  action$.pipe(
    ofType("ADD_TIMELINE_REQUEST"),
    mergeMap((action) =>
      from(api.addTimeline(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_TIMELINE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_TIMELINE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchTimelineEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_TIMELINE_REQUEST", "ADD_TIMELINE_REQUEST_SUCCESS"),
    mergeMap((action) =>
      from(api.fetchTimeline(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_TIMELINE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_TIMELINE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const approveQuotationEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_QUOTATION_REQUEST"),
    mergeMap((action) =>
      from(api.approveQuotation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "APPROVE_QUOTATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "APPROVE_QUOTATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQuotationByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QUOTAtION_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQuotationById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_QUOTAtION_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QUOTAtION_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const UpdateQuotationStatusEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_QUOTATION_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.updateQuoteStatusApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_QUOTATION_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_QUOTATION_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const downloadQuotationEpic = (action$) =>
  action$.pipe(
    ofType("DOWNLOAD_QUOTATION_REQUEST"),
    mergeMap((action) =>
      from(api.downloadQuotation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action.payload?.isPdfDownloadFn &&
            action.payload?.pdfDownloadFn(response);
          return of({
            type: "DOWNLOAD_QUOTATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DOWNLOAD_QUOTATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addQuotationSourceEpic = (action$) =>
  action$.pipe(
    ofType("ADD_QUOTATION_SOURCE_REQUEST"),
    mergeMap((action) =>
      from(api.addQuotationSource(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ADD_QUOTATION_SOURCE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_QUOTATION_SOURCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addServiceSourceEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SERVICE_SOURCE_REQUEST"),
    mergeMap((action) =>
      from(api.addServiceSource(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_SERVICE_SOURCE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SERVICE_SOURCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchServiceSourceEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_SERVICE_SOURCE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchServiceSource(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_SERVICE_SOURCE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SERVICE_SOURCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchProductServiceEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRODUCT_SERVICE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchProductServiceApi(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PRODUCT_SERVICE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PRODUCT_SERVICE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchProductServiceActivityLogEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_SERVICE_ACTIVITYLOG_REQUEST"),
    mergeMap((action) =>
      from(api.fetchProductServiceActivityLogApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCH_SERVICE_ACTIVITYLOG_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_SERVICE_ACTIVITYLOG_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchProductServiceByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCHH_PRODUCT_SERVICE_BY_ID"),
    mergeMap((action) =>
      from(api.fetchProductServiceByIdApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "FETCHH_PRODUCT_SERVICE_BY_ID_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCHH_PRODUCT_SERVICE_BY_ID_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const addServiceEpic = (action$) =>
  action$.pipe(
    ofType("ADD_SERVICE_REQUEST"),
    mergeMap((action) =>
      from(api.addServiceApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "ADD_SERVICE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_SERVICE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateServiceEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SERVICE_REQUEST"),
    mergeMap((action) =>
      from(api.updateServiceApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_SERVICE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn("testing service", err);
          return of({
            type: "UPDATE_SERVICE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateServiceStatusEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SERVICE_STATUS_REQUEST"),
    mergeMap((action) =>
      from(api.updateServiceStatusApi(action.payload)).pipe(
        mergeMap((response) => {
          action?.isSuccessFn && action?.successFunction(response);
          return of({
            type: "UPDATE_SERVICE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_SERVICE_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQuotationSourceEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_QUOTATION_SOURCE_REQUEST",
      "ADD_QUOTATION_SOURCE_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchQuotationSource(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_QUOTATION_SOURCE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QUOTATION_SOURCE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchQuotationByCustomerIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQuotationByCustomerId(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateQuotationEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_QUOTATION_REQUEST"),
    mergeMap((action) =>
      from(api.updateQuotation(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "UPDATE_QUOTATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_QUOTATION_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchConsultantByAppointmentDateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CONSULTANTS_BY_APPOINTMENT_DATE_REQUEST"),
    mergeMap((action) =>
      from(api.fetchConsultantByAppointmentDate(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          action?.payload.isReload && action?.payload?.reloadFn(response);
          return of({
            type: "FETCH_CONSULTANTS_BY_APPOINTMENT_DATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_CONSULTANTS_BY_APPOINTMENT_DATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchWorkingDaysOfCounsellorEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST"),
    mergeMap((action) =>
      from(api.fetchWorkingDaysOfCounsellor(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_WORKING_DAYS_OF_COUNSELLOR_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const fetchAllQuickInquiryEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ALL_QUICK_INQUIRY_REQUEST",
      "ACCEPT_QUICK_INQUIRY_REQUEST_SUCCESS",
      "DECLINE_QUICK_INQUIRY_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAllQuickInquiry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ALL_QUICK_INQUIRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ALL_QUICK_INQUIRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchQuickInquiryByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QUICK_INQUIRY_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(api.fetchQuickInquiryById(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_QUICK_INQUIRY_BY_ID_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QUICK_INQUIRY_BY_ID_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchPendingQuickInquiryEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_PENDING_QUICK_INQUIRY_REQUEST",
      "ACCEPT_QUICK_INQUIRY_REQUEST_SUCCESS",
      "DECLINE_QUICK_INQUIRY_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchPendingQuickInquiry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_PENDING_QUICK_INQUIRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_PENDING_QUICK_INQUIRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchAcceptedQuickInquiryEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST",
      "ACCEPT_QUICK_INQUIRY_REQUEST_SUCCESS",
      "DECLINE_QUICK_INQUIRY_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchAcceptedQuickInquiry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_ACCEPTED_QUICK_INQUIRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
export const fetchDeclinedQuickInquiryEpic = (action$) =>
  action$.pipe(
    ofType(
      "FETCH_DECLINED_QUICK_INQUIRY_REQUEST",
      "ACCEPT_QUICK_INQUIRY_REQUEST_SUCCESS",
      "DECLINE_QUICK_INQUIRY_REQUEST_SUCCESS"
    ),
    mergeMap((action) =>
      from(api.fetchDeclinedQuickInquiry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "FETCH_DECLINED_QUICK_INQUIRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DECLINED_QUICK_INQUIRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const acceptQuickInquiryEpic = (action$) =>
  action$.pipe(
    ofType("ACCEPT_QUICK_INQUIRY_REQUEST"),
    mergeMap((action) =>
      from(api.acceptQuickInquiry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "ACCEPT_QUICK_INQUIRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ACCEPT_QUICK_INQUIRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const declineQuickInquiryEpic = (action$) =>
  action$.pipe(
    ofType("DECLINE_QUICK_INQUIRY_REQUEST"),
    mergeMap((action) =>
      from(api.declineQuickInquiry(action.payload)).pipe(
        mergeMap((response) => {
          console.warn("response", response);
          return of({
            type: "DECLINE_QUICK_INQUIRY_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DECLINE_QUICK_INQUIRY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const documentChecklistEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DOCUMENT_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.addDocumentChecklistApi(action.payload)).pipe(
        mergeMap((response) => {
          action.isSuccessFn && action.successFunction();
          return of({
            type: "ADD_DOCUMENT_CHECKLIST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_DOCUMENT_CHECKLIST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const updateDocumentChecklistEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_DOCUMENT_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.updateDocumentChecklistApi(action.payload)).pipe(
        mergeMap((response) => {
          action.isSuccessFn && action.successFunction();
          return of({
            type: "UPDATE_DOCUMENT_CHECKLIST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_DOCUMENT_CHECKLIST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const getdocumentChecklistEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DOCUMENT_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.getDocumentChecklistApi(action.payload)).pipe(
        mergeMap((response) => {
          action.isSuccessFn && action.successFunction();
          return of({
            type: "FETCH_DOCUMENT_CHECKLIST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_DOCUMENT_CHECKLIST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const superadminGetDocumentChecklistEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_DOCUMENT_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.superadminGetDocumentChecklistApi(action.payload)).pipe(
        mergeMap((response) => {
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "IMPORT_DOCUMENT_CHECKLIST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_DOCUMENT_CHECKLIST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const superadminGetVisaSubclassEpic = (action$) =>
  action$.pipe(
    ofType("IMPORT_VISASUBCLASS_CHECKLIST_REQUEST"),
    mergeMap((action) =>
      from(api.superadminGetVisaSubclassApi(action.payload)).pipe(
        mergeMap((response) => {
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "IMPORT_VISASUBCLASS_CHECKLIST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "IMPORT_VISASUBCLASS_CHECKLIST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

export const getQoutePdfFileEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_QOUTEPDFFILE_REQUEST"),
    mergeMap((action) =>
      from(api.getQoutePdfFileApi(action.payload)).pipe(
        mergeMap((response) => {
          action.isSuccessFn && action.successFunction(response);
          return of({
            type: "FETCH_QOUTEPDFFILE_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "FETCH_QOUTEPDFFILE_FAILURE",
            payload: err,
          });
        })
      )
    )
  );
