import React from "react";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";

const AddAgreementInstitute = ({
  record,
  isAsignModelVisible,
  setIsAsignModelVisible,
  instituteId,
  isUpdate,
  fetchInstituteById,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmitAgreementDate = (values) => {
    dispatch({
      type: "ADD_AGREEMENTDATE_REQUEST",
      payload: { ...values, id: instituteId },
      isSuccessFn: true,
      successFunction: () => {
        setIsAsignModelVisible(false);
        fetchInstituteById();
      },
    });
  };

  const disabledDate = (current) => {
    const startDate = form.getFieldValue("agreementStartDate");
    return current && current < startDate;
  };

  return (
    <Modal
      title={`${isUpdate ? "Edit" : "Add"} Agreement Date`}
      open={isAsignModelVisible}
      onCancel={() => {
        setIsAsignModelVisible(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitAgreementDate}
        initialValues={{
          agreementStartDate: record?.agreementStartDate
            ? moment(record?.agreementStartDate)
            : null,
          agreementEndDate: record?.agreementExpiryDate
            ? moment(record?.agreementExpiryDate)
            : null,
        }}
      >
        <Form.Item
          label="Agreement Start Date"
          name={"agreementStartDate"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter Agreement StartDate",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item
          label="Agreement Expiry Date"
          name={"agreementEndDate"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter Agreement ExpiryDate",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            format={"DD-MM-YYYY"}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Button block type="primary" htmlType="submit">
          {`${isUpdate ? "Edit" : "Add"} Agreement Date`}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddAgreementInstitute;
