import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Card, Form, Input, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import OrganizationalSettings from "../OrganizationalSettings";
import { formattedDate, formattedDateTime } from "../../../HelperFunction";
import { useNavigate } from "react-router-dom";
const { confirm } = Modal;

const Import = ({ branchRecord, isHeadBranch }) => {
  const store = useSelector((state) => {
    return {
      generalState: state.OrganizationSettingsReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  useEffect(() => {
    fetchAPIRequest();
    dispatch({ type: "FETCH_PROFILE_REQUEST" });
  }, []);
  // useEffect(() => {
  //   window.location.reload();
  // }, [store.branchState?.branchRecord]);
  const successFunction = (response) => {
    setGeneralSettingsData((previousData) => {
      return {
        ...previousData,
        branchImported: response?.data?.branchImported,
        adminInstituteImported: response?.data?.adminInstituteImported,
      };
    });
  };
  const [generalSettingsData, setGeneralSettingsData] = useState({
    adminInstituteImported: false,
    branchImported: false,
  });

  const fetchAPIRequest = () => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  return (
    <OrganizationalSettings>
      <div>
        <h3>Import Settings</h3>
        <Card>
          {store.profileState?.isBranch === false && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <span>
                Would you like to import all dropdown data from head office?
              </span>
              {store.profileState?.profile?.department?.rolesAndPermissionList?.IMPORT_SETTINGS?.includes(
                "UPDATE"
              ) && (
                <Button
                  type="primary"
                  style={{ borderRadius: "8px" }}
                  onClick={() => {
                    confirm({
                      title: "Do you want to import now?",
                      icon: <ExclamationCircleFilled />,
                      okText: "Import",
                      cancelText: "Cancel",
                      onOk() {
                        dispatch({
                          type: "IMPORT_DROPDOWN_DATA_FROM_HEAD_OFFICE_REQUEST",
                          payload: {
                            branchId:
                              store.branchState?.branchRecord.id ??
                              localStorage.getItem("branchId"),
                          },
                        });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  Import
                </Button>
              )}
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div className="flexColumnwithoutStyle" style={{ gap: "0.4rem" }}>
              Would you like to import all institute data with associated
              courses and country from admin?
              {store.generalState?.reminderExpiryWorkingHour
                ?.adminDataLastImportedOn && (
                <div>
                  <span className="greenTag">
                    Last imported on:{" "}
                    {store.generalState?.reminderExpiryWorkingHour
                      ?.adminDataLastImportedOn
                      ? formattedDate(
                          store.generalState?.reminderExpiryWorkingHour
                            ?.adminDataLastImportedOn
                        )
                      : null}
                  </span>
                </div>
              )}
            </div>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.IMPORT_SETTINGS?.includes(
              "UPDATE"
            ) && (
              <Button
                type="primary"
                style={{ borderRadius: "8px" }}
                onClick={() => {
                  confirm({
                    title: "Do you want to import now?",
                    icon: <ExclamationCircleFilled />,
                    okText: "Import",
                    cancelText: "Cancel",
                    onOk() {
                      dispatch({
                        type: "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST",
                        payload: {
                          branchId:
                            store.branchState?.branchRecord.id ??
                            localStorage.getItem("branchId"),
                        },
                      });
                    },
                    onCancel() {},
                  });
                }}
              >
                Import
              </Button>
            )}
          </div>

          <div>
            <Button onClick={() => navigate("/institute")}>
              View Institute
            </Button>
          </div>
        </Card>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div className="flexColumnwithoutStyle" style={{ gap: "0.4rem" }}>
              Would you like to import all the document checklist data from
              admin?
              {store.generalState?.reminderExpiryWorkingHour
                ?.adminDockCheckListLastImportedOn && (
                <div>
                  <span className="greenTag">
                    Last imported on:{" "}
                    {store.generalState?.reminderExpiryWorkingHour
                      ?.adminDockCheckListLastImportedOn
                      ? formattedDate(
                          store.generalState?.reminderExpiryWorkingHour
                            ?.adminDockCheckListLastImportedOn
                        )
                      : null}
                  </span>
                </div>
              )}
            </div>
            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.IMPORT_SETTINGS?.includes(
              "UPDATE"
            ) && ( */}
            <Button
              type="primary"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                confirm({
                  title: "Do you wannt to import now?",
                  icon: <ExclamationCircleFilled />,
                  okText: "Import",
                  cancelText: "Cancel",
                  onOk() {
                    dispatch({
                      type: "IMPORT_DOCUMENT_CHECKLIST_REQUEST",
                      payload: "",
                      isSuccessFn: false,
                      successFunction: () => {
                        fetchAPIRequest();
                      },
                    });
                    // dispatch({
                    //   type: "IMPORT_INSTITUTE_DATA_FROM_ADMIN_REQUEST",
                    //   payload: {
                    //     branchId:
                    //       store.branchState?.branchRecord.id ??
                    //       localStorage.getItem("branchId"),
                    //   },
                    // });
                  },
                  onCancel() {},
                });
              }}
            >
              Import
            </Button>
            {/* )} */}
          </div>
          <div>
            <Button onClick={() => navigate("/document-checklist")}>
              Document Checklist
            </Button>
          </div>
        </Card>

        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div className="flexColumnwithoutStyle" style={{ gap: "0.4rem" }}>
              Would you like to import all Visa Subclass from admin?
              {store.generalState?.reminderExpiryWorkingHour
                ?.adminVisaSubClassLastImportedOn && (
                <div>
                  <span className="greenTag">
                    Last imported on:{" "}
                    {store.generalState?.reminderExpiryWorkingHour
                      ?.adminVisaSubClassLastImportedOn
                      ? formattedDate(
                          store.generalState?.reminderExpiryWorkingHour
                            ?.adminVisaSubClassLastImportedOn
                        )
                      : null}
                  </span>
                </div>
              )}
            </div>
            {/* {store.profileState?.profile?.department?.rolesAndPermissionList?.IMPORT_SETTINGS?.includes(
              "UPDATE"
            ) && ( */}
            <Button
              type="primary"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                confirm({
                  title: "Do you wannt to import now?",
                  icon: <ExclamationCircleFilled />,
                  okText: "Import",
                  cancelText: "Cancel",
                  onOk() {
                    dispatch({
                      type: "IMPORT_VISASUBCLASS_CHECKLIST_REQUEST",
                      payload: "",
                      isSuccessFn: false,
                      successFunction: () => {
                        fetchAPIRequest();
                      },
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              Import
            </Button>
            {/* )} */}
          </div>
          <div>
            <Button onClick={() => navigate("/education")}>
              Visa Subclass
            </Button>
          </div>
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default Import;
