import { Drawer, Steps } from "antd";
import React, { useState } from "react";
import ServiceClientInfo from "./ServiceClientInfo";
import ServicePackageInfo from "./ServicePackageInfo";
import ProductServiceInfo from "./ProductServiceInfo";

export default function AddServices({
  isAddServicesVisible,
  setIsAddServicesVisible,
  fetchProductService,
}) {
  const [current, setCurrent] = useState(0);
  const [serviceData, setServiceData] = useState({
    clientType: "EXISTING",
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    referral: "",
    countryOfResidence: "",

    natureOfProject: "",
    servicePackage: [
      {
        packageName: "",
        quantity: null,
        unitPrice: null,
        totalAmount: null,
        packageId: null,
      },
    ],
    isDiscountAllowed: false,
    discount: "",
    packageDiscountType: "",
    gstApplicable: false,
    gstAmount: "",
    associatedTimelineId: "",
    associatedTimelineName: "",
    specialNote: "",
    description: "",
    paymentTermsInMonths: "",
    paymentTermsInfo: "",
    displayPaymentTermsInfoInServiceConfirmation: true,
    serviceUpdate: "",
    displayServiceUpdateInServiceConfirmation: true,

    serviceSourceId: "",
    serviceSourceName: "",
    serviceStatus: "PENDING",
    aboutUsId: "",
    aboutUs: "",
    serviceSartDate: null,
    serviceExpiryDate: null,
    modeOfContact: "",
  });

  const forms = [
    <ServiceClientInfo
      serviceType="add"
      serviceData={serviceData}
      setServiceData={setServiceData}
      setCurrent={setCurrent}
    />,
    <ServicePackageInfo
      serviceData={serviceData}
      setServiceData={setServiceData}
      setCurrent={setCurrent}
    />,
    <ProductServiceInfo
      serviceType="add"
      serviceData={serviceData}
      setServiceData={setServiceData}
      setCurrent={setCurrent}
      setIsAddServicesVisible={setIsAddServicesVisible}
      fetchProductService={fetchProductService}
    />,
  ];
  return (
    <Drawer
      title="Add Services"
      open={isAddServicesVisible}
      onClose={() => {
        setIsAddServicesVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Step 1",
              description: "CLIENT INFORMATION",
            },
            {
              title: "Step 2",
              description: "PACKAGE INFORMATION",
            },
            {
              title: "Step 3",
              description: "SERVICE INFORMATION",
            },
          ]}
        />
      </div>
      <div className="ClientDrawerStyle"> {forms[current]}</div>
    </Drawer>
  );
}
