import { Button, Card, Tag } from "antd";
import React from "react";
import { Divider } from "antd";
import moment, { locales } from "moment";
import { useDispatch } from "react-redux";

export default function UpdateCasePreview({
  setCurrent,
  caseFormData,
  setIsUpdateCase,
  fetchAllCases,
}) {
  const dispatch = useDispatch();

  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };

  const handleUpdateCase = () => {
    dispatch({
      type: "UPDATE_CLIENT_CASE_REQUEST",
      payload: {
        id: caseFormData?.id,
        assignedToEmail: caseFormData?.assignedToEmail,
        isScholarshipAllowed: caseFormData?.isScholershipAllowed,
        scholarshipType: caseFormData?.scholarshipType ? "FLAT" : "PERCENTAGE",
        scholarShipAmount: caseFormData?.scholarShipAmount,
        leadId: localStorage.getItem("branchId"),
        deadlineDate: caseFormData.deadlineDate,
        description: caseFormData?.description,
        consultantId: caseFormData?.consultantId,
        superAgentId: caseFormData?.superAgentId,
        leadType: caseFormData?.leadType,
        customerId: caseFormData?.customerId,
        visaCategory: caseFormData?.visaType,
        visaSubClassId: caseFormData?.visaSubClassId,
        currency: caseFormData?.currency,
        priorNote: caseFormData?.priorNote,
        collegeIntake: caseFormData?.intakeId,
        instituteId: caseFormData?.instituteId,
        courseId: caseFormData?.courseId,
        degree: caseFormData?.degreeId,
        wantToPayFor: caseFormData?.wantToPayFor,
        fee: caseFormData?.fee,
        discount: caseFormData.discount,
        packageDiscountType: caseFormData?.packageDiscountType
          ? "FLAT"
          : "PERCENTAGE",
        isDiscountAllowed: caseFormData.isDiscountAllowed,
        casePackages: caseFormData?.casePackages,
        isAddPackage: caseFormData?.isAddPackage,
        totalAmount:
          caseFormData.fee +
          caseFormData?.casePackages?.reduce((a, b) => a + b?.totalPrice, 0),
        currentPayAmount: caseFormData.wantToPayFee,

        isOffShore: caseFormData.currentStatus,
        requiredWorkflowChecklist: caseFormData.workflowDocumentChecklist,
        requiredCoursesChecklist: caseFormData.coursesDocumentChecklist,
        workFlowStatusId: caseFormData?.workFlowStatusId,
        workflowId: caseFormData?.workflowId,
      },
      isSuccessFn: true,
      successFunction: () => {
        setIsUpdateCase(false);
        fetchAllCases();
      },
    });
  };
  return (
    <div>
      <Card bordered={true}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: "1rem" }}>
            <div>
              <h4>CLIENT INFORMATION:</h4>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0" }} />
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseDetails(
                  "Start Date",
                  caseFormData.startDate
                    ? moment(caseFormData.startDate)
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    : moment().utc().local().format("DD-MM-YYYY")
                )}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.leadType !== "" &&
                caseFormData.leadType !== null &&
                caseFormData.leadType !== undefined
                  ? caseDetails("Lead Type", caseFormData.leadType)
                  : caseDetails("Lead Type", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.salesRepresentativeName !== "" &&
                caseFormData.salesRepresentativeName !== null &&
                caseFormData.salesRepresentativeName !== undefined
                  ? caseDetails(
                      "Sales Representative",
                      caseFormData.salesRepresentativeName
                    )
                  : caseDetails("Sales Representative", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.commenceDate !== "" &&
                caseFormData.commenceDate !== null &&
                caseFormData.commenceDate !== undefined
                  ? caseDetails(
                      "Commence Date",
                      moment(caseFormData.commenceDate)
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    )
                  : caseDetails("Commence Date", "N/A")}
              </div>
            </div>
            {/* <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
        
        
      </div> */}
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.firstName !== "" &&
                caseFormData.firstName !== null &&
                caseFormData.firstName !== undefined &&
                caseFormData.lastName !== "" &&
                caseFormData.lastName !== null &&
                caseFormData.lastName !== undefined
                  ? caseDetails(
                      "Name",
                      `${caseFormData.firstName} ${caseFormData.lastName} ${
                        caseFormData.clientType === "EXISTING"
                          ? "(Existing)"
                          : caseFormData.clientType === "NEW"
                          ? "(New)"
                          : null
                      } `
                    )
                  : caseDetails("Name", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.mobile !== "" &&
                caseFormData.mobile !== null &&
                caseFormData.mobile !== undefined
                  ? caseDetails("Mobile Number", caseFormData.mobile)
                  : caseDetails("Mobile Number", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.email !== "" &&
                caseFormData.email !== null &&
                caseFormData.email !== undefined
                  ? caseDetails("Email", caseFormData.email)
                  : caseDetails("Email", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.dob !== "" &&
                caseFormData.dob !== null &&
                caseFormData.dob !== undefined
                  ? caseDetails(
                      "Date of Birth",
                      moment
                        .utc(moment.utc(caseFormData.dob).local())
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    )
                  : caseDetails("Date of Birth", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.phone !== "" &&
                caseFormData.phone !== null &&
                caseFormData.phone !== undefined
                  ? caseDetails("Phone Number", caseFormData.phone)
                  : caseDetails("Phone Number", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.address !== "" &&
                caseFormData.address !== null &&
                caseFormData.address !== undefined
                  ? caseDetails("Address", caseFormData.address)
                  : caseDetails("Address", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.passportNumber !== "" &&
                caseFormData.passportNumber !== null &&
                caseFormData.passportNumber !== undefined
                  ? caseDetails("Passport Number", caseFormData.passportNumber)
                  : caseDetails("Passport Number", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.maritalStatus !== "" &&
                caseFormData.maritalStatus !== null &&
                caseFormData.maritalStatus !== undefined
                  ? caseDetails("Marital Status", caseFormData.maritalStatus)
                  : caseDetails("Marital Status", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.sexStatus !== "" &&
                caseFormData.sexStatus !== null &&
                caseFormData.sexStatus !== undefined
                  ? caseDetails("Sex Status", caseFormData.sexStatus)
                  : caseDetails("Sex Status", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.countryOfResidence !== "" &&
                caseFormData.countryOfResidence !== null &&
                caseFormData.countryOfResidence !== undefined
                  ? caseDetails(
                      "Country of Residence",
                      caseFormData.countryOfResidence
                    )
                  : caseDetails("Country of Residence", "N/A")}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <h4>VISA INFORMATION:</h4>
            <Divider orientationMargin="0" style={{ margin: "0" }} />
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.visaType !== "" &&
                caseFormData.visaType !== null &&
                caseFormData.visaType !== undefined
                  ? caseDetails("Visa Type", caseFormData.visaType)
                  : caseDetails("Visa Type", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.visaSubClass !== "" &&
                caseFormData.visaSubClass !== null &&
                caseFormData.visaSubClass !== undefined
                  ? caseDetails("Visa Subclass", caseFormData.visaSubClass)
                  : caseDetails("Visa Subclass", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.currentStatus !== "" &&
                caseFormData.currentStatus !== null &&
                caseFormData.currentStatus !== undefined
                  ? caseDetails("Current Status", caseFormData.currentStatus)
                  : caseDetails("Current Status", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.country !== "" &&
                caseFormData.country !== null &&
                caseFormData.country !== undefined
                  ? caseDetails("Country", caseFormData.country)
                  : caseDetails("Country", "N/A")}
              </div>
            </div>
            {/* <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
         
        </div> */}
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.workFlow !== "" &&
                caseFormData.workFlow !== null &&
                caseFormData.workFlow !== undefined
                  ? caseDetails("Workflow", caseFormData.workFlow)
                  : caseDetails("Workflow", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.workFlowStatus !== "" &&
                caseFormData.workFlowStatus !== null &&
                caseFormData.workFlowStatus !== undefined
                  ? caseDetails("Workflow Status", caseFormData.workFlowStatus)
                  : caseDetails("Workflow Status", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.instituteName !== "" &&
                caseFormData.instituteName !== null &&
                caseFormData.instituteName !== undefined
                  ? caseDetails("Institute", caseFormData.instituteName)
                  : caseDetails("Institute", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.degreeName !== "" &&
                caseFormData.degreeName !== null &&
                caseFormData.degreeName !== undefined
                  ? caseDetails("Degree", caseFormData.degreeName)
                  : caseDetails("Degree", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.courseName !== "" &&
                caseFormData.courseName !== null &&
                caseFormData.courseName !== undefined
                  ? caseDetails("Course", caseFormData.courseName)
                  : caseDetails("Course", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.Intake !== "" &&
                caseFormData.Intake !== null &&
                caseFormData.Intake !== undefined
                  ? caseDetails("Intake", caseFormData.Intake)
                  : caseDetails("Intake", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.wantToPayFor !== "" &&
                caseFormData.wantToPayFor !== null &&
                caseFormData.wantToPayFor !== undefined
                  ? caseDetails("Want to pay for", caseFormData.wantToPayFor)
                  : caseDetails("Want to pay for", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.wantToPayFor !== "" &&
                caseFormData.wantToPayFor !== null &&
                caseFormData.wantToPayFor !== undefined
                  ? caseDetails("Paying Fee", caseFormData.wantToPayFee)
                  : caseDetails("Paying Fee", "N/A")}
              </div>
            </div>

            {caseFormData.currentStatus === "ONSHORE" && (
              <>
                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.onShoreFee !== "" &&
                    caseFormData.course?.onShoreFee !== null &&
                    caseFormData.course?.onShoreFee !== undefined
                      ? caseDetails(
                          "Onshore Fee",
                          caseFormData.course?.onShoreFee
                        )
                      : caseDetails("Onshore Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.onshoreApplicationFee !== "" &&
                    caseFormData.course?.onshoreApplicationFee !== null &&
                    caseFormData.course?.onshoreApplicationFee !== undefined
                      ? caseDetails(
                          "Onshore Application Fee",
                          caseFormData.course?.onshoreApplicationFee
                        )
                      : caseDetails("Onshore Application Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.onshoreMaterialFee !== "" &&
                    caseFormData.course?.onshoreMaterialFee !== null &&
                    caseFormData.course?.onshoreMaterialFee !== undefined
                      ? caseDetails(
                          "Onshore Material Fee",
                          caseFormData.course?.onshoreMaterialFee
                        )
                      : caseDetails("Onshore Material Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.onshoreAdditionalFee !== "" &&
                    caseFormData.course?.onshoreAdditionalFee !== null &&
                    caseFormData.course?.onshoreAdditionalFee !== undefined
                      ? caseDetails(
                          "Onshore Additional Fee",
                          caseFormData.course?.onshoreAdditionalFee
                        )
                      : caseDetails("Onshore Additional Fee", "N/A")}
                  </div>
                </div>

                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.onshoreIsDiscount === true && (
                      <>
                        {caseFormData.course?.onshoreDiscountAmount !== "" &&
                        caseFormData.course?.onshoreDiscountAmount !== null &&
                        caseFormData.course?.onshoreDiscountAmount !==
                          undefined &&
                        caseFormData.course?.onshoreDiscountType !== "" &&
                        caseFormData.course?.onshoreDiscountType !== null &&
                        caseFormData.course?.onshoreDiscountType !== undefined
                          ? caseDetails(
                              "Onshore Discount",
                              `${caseFormData.course?.onshoreDiscountAmount} ${
                                caseFormData.course?.onshoreDiscountType ===
                                "FLAT"
                                  ? "FLAT"
                                  : caseFormData.course?.onshoreDiscountType ===
                                    "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Onshore Discount", "N/A")}
                      </>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.onshoreIsScholarship === true && (
                      <>
                        {caseFormData.course?.onshoreScholarshipAmount !== "" &&
                        caseFormData.course?.onshoreScholarshipAmount !==
                          null &&
                        caseFormData.course?.onshoreScholarshipAmount !==
                          undefined &&
                        caseFormData.course?.onshoreScholarshipType !== "" &&
                        caseFormData.course?.onshoreScholarshipType !== null &&
                        caseFormData.course?.onshoreScholarshipType !==
                          undefined
                          ? caseDetails(
                              "Onshore Scholarship",
                              `${
                                caseFormData.course?.onshoreScholarshipAmount
                              } ${
                                caseFormData.course?.onshoreScholarshipType ===
                                "FLAT"
                                  ? "FLAT"
                                  : caseFormData.course
                                      ?.onshoreScholarshipType === "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Onshore Scholarship", "N/A")}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {caseFormData.currentStatus === "OFFSHORE" && (
              <>
                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.offShoreFee !== "" &&
                    caseFormData.course?.offShoreFee !== null &&
                    caseFormData.course?.offShoreFee !== undefined
                      ? caseDetails(
                          "Offshore Fee",
                          caseFormData.course?.offShoreFee
                        )
                      : caseDetails("Offshore Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.offshoreApplicationFee !== "" &&
                    caseFormData.course?.offshoreApplicationFee !== null &&
                    caseFormData.course?.offshoreApplicationFee !== undefined
                      ? caseDetails(
                          "Offshore Application Fee",
                          caseFormData.course?.offshoreApplicationFee
                        )
                      : caseDetails("Offshore Application Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.offshoreMaterialFee !== "" &&
                    caseFormData.course?.offshoreMaterialFee !== null &&
                    caseFormData.course?.offshoreMaterialFee !== undefined
                      ? caseDetails(
                          "Offshore Material Fee",
                          caseFormData.course?.offshoreMaterialFee
                        )
                      : caseDetails("Offshore Material Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.offshoreAdditionalFee !== "" &&
                    caseFormData.course?.offshoreAdditionalFee !== null &&
                    caseFormData.course?.offshoreAdditionalFee !== undefined
                      ? caseDetails(
                          "Offshore Additional Fee",
                          caseFormData.course?.offshoreAdditionalFee
                        )
                      : caseDetails("Offshore Additional Fee", "N/A")}
                  </div>
                </div>
                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.offshoreIsDiscount === true && (
                      <>
                        {caseFormData.course?.offshoreDiscountAmount !== "" &&
                        caseFormData.course?.offshoreDiscountAmount !== null &&
                        caseFormData.course?.offshoreDiscountAmount !==
                          undefined &&
                        caseFormData.course?.offshoreDiscountType !== "" &&
                        caseFormData.course?.offshoreDiscountType !== null &&
                        caseFormData.course?.offshoreDiscountType !== undefined
                          ? caseDetails(
                              "Offshore Discount",
                              `${caseFormData.course?.offshoreDiscountAmount} ${
                                caseFormData.course?.offshoreDiscountType ===
                                "FLAT"
                                  ? "FLAT"
                                  : caseFormData.course
                                      ?.offshoreDiscountType === "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Offshore Discount", "N/A")}
                      </>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    {caseFormData.course?.offshoreIsScholarship === true && (
                      <>
                        {caseFormData.course?.offshoreScholarshipAmount !==
                          "" &&
                        caseFormData.course?.offshoreScholarshipAmount !==
                          null &&
                        caseFormData.course?.offshoreScholarshipAmount !==
                          undefined &&
                        caseFormData.course?.offshoreScholarshipType !== "" &&
                        caseFormData.course?.offshoreScholarshipType !== null &&
                        caseFormData.course?.offshoreScholarshipType !==
                          undefined
                          ? caseDetails(
                              "Offshore Discount",
                              `${
                                caseFormData.course?.onshoreScholarshipAmount
                              } ${
                                caseFormData.course?.offshoreScholarshipType ===
                                "FLAT"
                                  ? "FLAT"
                                  : caseFormData.course
                                      ?.offshoreScholarshipType === "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Offshore Discount", "N/A")}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {caseFormData.fee !== "" &&
            caseFormData.fee !== null &&
            caseFormData.fee !== undefined
              ? caseDetails(
                  "Total Fee",
                  caseFormData.fee === NaN ? 0 : caseFormData.fee
                )
              : caseDetails("Total Fee", "N/A")}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <div>
              <h4>PACKAGE INFORMATION:</h4>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0" }} />
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.fee !== "" &&
                caseFormData.fee !== null &&
                caseFormData.fee !== undefined
                  ? caseDetails(
                      "Total Fee",
                      `${caseFormData.fee} ${
                        caseFormData.currency !== null &&
                        caseFormData.currency !== undefined &&
                        caseFormData.currency !== ""
                          ? caseFormData.currency
                          : ""
                      } `
                    )
                  : caseDetails("Total Fee", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.packageTotal !== "" &&
                caseFormData.packageTotal !== null &&
                caseFormData.packageTotal !== undefined
                  ? caseDetails(
                      "Package Total",
                      `${caseFormData.packageTotal} ${
                        caseFormData.currency !== null &&
                        caseFormData.currency !== undefined &&
                        caseFormData.currency !== ""
                          ? caseFormData.currency
                          : ""
                      } `
                    )
                  : caseDetails("Package Total", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.packageTotal !== "" &&
                caseFormData.packageTotal !== null &&
                caseFormData.packageTotal !== undefined &&
                caseFormData.fee !== "" &&
                caseFormData.fee !== null &&
                caseFormData.fee !== undefined
                  ? caseDetails(
                      "Grand Total",
                      `${caseFormData.fee + caseFormData.packageTotal} ${
                        caseFormData.currency !== null &&
                        caseFormData.currency !== undefined &&
                        caseFormData.currency !== ""
                          ? caseFormData.currency
                          : ""
                      } `
                    )
                  : caseDetails("Grand Total", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.deadlineDate !== "" &&
                caseFormData.deadlineDate !== null &&
                caseFormData.deadlineDate !== undefined
                  ? caseDetails(
                      "Deadline",
                      moment(caseFormData.deadlineDate)
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    )
                  : caseDetails("Deadline", "N/A")}
              </div>
            </div>

            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData.description !== "" &&
                caseFormData.description !== null &&
                caseFormData.description !== undefined
                  ? caseDetails("Description", caseFormData.description)
                  : caseDetails("Description", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {caseFormData.priorNote !== "" &&
                caseFormData.priorNote !== null &&
                caseFormData.priorNote !== undefined
                  ? caseDetails("Prior Note", caseFormData.priorNote)
                  : caseDetails("Prior Note", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData?.documentCheckList?.length > 0 &&
                  caseDetails(
                    "Workflow document checklist",
                    caseFormData?.workflowDocumentCheckList?.map((document) => {
                      if (
                        caseFormData?.documentCheckList?.includes(document.id)
                      ) {
                        return (
                          <Tag key={document.id} color="blue">
                            {document?.item}
                          </Tag>
                        );
                      }
                    })
                  )}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseFormData?.coursesChecklist?.length > 0 &&
                caseFormData?.currentStatus === "ONSHORE"
                  ? caseDetails(
                      "Course document checklist",
                      caseFormData?.course?.onShoreCheckList?.map(
                        (document) => {
                          if (
                            caseFormData?.coursesChecklist?.includes(
                              document.id
                            )
                          ) {
                            return (
                              <Tag key={document.id} color={"blue"}>
                                {document?.item}
                              </Tag>
                            );
                          }
                        }
                      )
                    )
                  : caseFormData?.coursesChecklist?.length > 0 &&
                    caseFormData?.currentStatus === "OFFSHORE"
                  ? caseDetails(
                      "Course document checklist",
                      caseFormData?.course?.offShoreCheckList?.map(
                        (document) => {
                          if (
                            caseFormData?.coursesChecklist?.includes(
                              document.id
                            )
                          ) {
                            return (
                              <Tag key={document.id} color={"blue"}>
                                {document?.item}
                              </Tag>
                            );
                          }
                        }
                      )
                    )
                  : null}
              </div>
            </div>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Button
              onClick={() => {
                setCurrent((prev) => prev - 1);
              }}
            >
              Back
            </Button>

            <Button
              name="caseSubmit"
              type="primary"
              onClick={() => {
                // setCurrent((prev) => prev + 1);
                // alert("posted");
                handleUpdateCase();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
