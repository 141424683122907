import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Steps,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import QuotationUserInfo from "./QuotationUserInfo";
import QuotationPackageInfo from "./QuotationPackageInfo";
import QuotationQuotationInfo from "./QuotationQuotationInfo";
import { date, date00 } from "../../HelperFunction";
const { Option } = Select;

const UpdateQuotation = ({
  record,
  quotationSettingsData,
  quotationRecord,
  isUpdateQuotationModalVisible,
  setIsUpdateQuotationModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  console.log("record", record);

  const [quotationData, setQuotationData] = useState({
    clientType: "EXISTING",
    customerId: record?.customerId?.id ?? null,
    customer: `${record?.customerId?.fullName ?? ""}  ${
      record?.customerId?.email !== null &&
      record?.customerId?.email !== "" &&
      record?.customerId?.email !== undefined
        ? `(${record?.customerId?.email})`
        : record?.customerId?.mobile !== null &&
          record?.customerId?.mobile !== "" &&
          record?.customerId?.mobile !== undefined
        ? `(${record?.customerId?.mobile})`
        : ""
    }`,
    firstName: record?.customerId?.firstName ?? null,
    lastName: record?.customerId?.lastName ?? null,
    mobile: record?.customerId?.mobile ?? null,
    email: record?.customerId?.email ?? null,
    dob: record?.customerId?.dob ?? null,
    phone: record?.customerId?.phone ?? null,
    address: record?.customerId?.address ?? null,
    passportNumber: record?.customerId?.passportNumber ?? null,
    maritalStatus: record?.customerId?.maritalStatus ?? null,
    sexStatus: record?.customerId?.sexStatus ?? null,
    countryOfResidence: record?.customerId?.countryOfResidence ?? null,
    referral: record?.customerId?.referral?.id ?? null,
    referralName: record?.customerId?.referral?.fullName ?? null,
    aboutUs: record?.aboutUsId?.id,
    aboutUsName: record?.aboutUsId?.name,

    natureOfProject: record?.natureOfProject,
    grossPrice: record?.grossPrice,
    isDiscountAllowed: record?.discount ? true : false,
    discount: record?.discount,
    isPercentage: record?.percentage
      ? "PERCENTAGE"
      : record?.percentage === false
      ? "FLAT"
      : null,
    gstApplicable: record?.gstApplicable,
    gstAmount: record?.gstAmount,
    totalAmount: record?.totalAmount,
    isFinanceOptions: record?.financeOptions,
    associatedTimelineId: record?.associatedTimelineId?.id,
    associatedTimelineName: record?.associatedTimelineId?.name,
    paymentTermsInMonths: record?.paymentTermsInMonths,
    description: record?.description,
    specialNote: record?.specialNote,
    paymentTermsInfo: record?.paymentTermsInfo,
    displayPaymentTermsInfoInQuoteConfirmation:
      record?.displayPaymentTermsInfoInQuoteConfirmation ?? true,
    quotesUpdate: record?.quotesUpdate,
    displayQuotesUpdateInQuoteConfirmation:
      record?.displayQuotesUpdateInQuoteConfirmation,
    quoteStatus: record?.quoteStatus,
    quoteSource: record?.quoteSource?.id,
    quoteSourceName: record?.quoteSource?.name,
    quoteExpiryDate: record?.quoteExpiryDate
      ? moment(record?.quoteExpiryDate).utc().local()
      : null,
    modeOfContact: record?.modeOfContact,
    quotationPackages: record?.quotationPackages,
    // currency:  ,
  });
  const [packages, setPackages] = useState(
    record?.quotationPackages?.length !== 0
      ? [
          ...record?.quotationPackages?.map((pkg) => {
            const tempData = record?.quotationPackagesDetails?.find(
              (data) => data?.casePackageId === pkg?.id
            );
            return {
              ...pkg,
              totalPrice: tempData?.totalAmount,
              quantity: tempData?.quantity,
            };
          }),
        ]
      : [
          {
            id: null,
            packageName: "",
            quantity: 0,
            totalPrice: 0,
            unitPrice: 0,
            hasGst: null,
            gst: null,
            isGstFlat: null,
            isGstPercentage: null,
          },
        ]
  );

  const [current, setCurrent] = useState(0);
  const quotationPackagesIds = () => {
    let ids = [];
    if (packages[0].id !== null) {
      ids = packages.map((item) => {
        if (item.id !== null) {
          return item.id;
        } else {
          return;
        }
      });
    }
    return ids;
  };

  const addQuotationSubmitHandler = (value) => {
    const formData = {
      customerId: quotationData?.customerId,
      newCustomerRequest: {
        firstName: quotationData.firstName,
        lastName: quotationData.lastName,
        email: quotationData.email,
        maritalStatus: quotationData.maritalStatus,
        dob: quotationData.dob ? date(quotationData.dob) : null,
        referralId: quotationData.referral,
        gender: quotationData.sexStatus,
        mobile: quotationData.mobile,
        passportNumber: quotationData.passportNumber,
      },
      natureOfProject: quotationData.natureOfProject,
      associatedTimelineId: quotationData.associatedTimelineId,
      grossPrice: 0,
      discount: quotationData.discount,
      isPercentage: quotationData.isPercentage === "PERCENTAGE" ? true : false,
      gstApplicable: quotationData.gstApplicable,
      gstAmount: quotationData.gstAmount,
      totalAmount: 0,
      isFinanceOptions: quotationData.isFinanceOptions,
      paymentTermsInMonths: quotationData.paymentTermsInMonths,
      description: quotationData.description,
      specialNote: quotationData.specialNote,
      paymentTermsInfo: quotationData.paymentTermsInfo,
      displayPaymentTermsInfoInQuoteConfirmation:
        quotationData.displayPaymentTermsInfoInQuoteConfirmation,
      quotesUpdate: quotationData.quotesUpdate,
      displayQuotesUpdateInQuoteConfirmation:
        quotationData.displayQuotesUpdateInQuoteConfirmation,
      quoteStatus: quotationData.quoteStatus,
      quoteSource: quotationData.quoteSource,
      aboutUsId: quotationData.aboutUs,
      quoteExpiryDate: quotationData.quoteExpiryDate
        ? date00(quotationData.quoteExpiryDate)
        : null,
      modeOfContact: quotationData.modeOfContact,
      quotationPackages: [...quotationPackagesIds()],
      quotationPackageDetailsRequests: packages?.map((pkg) => {
        return {
          packageId: pkg?.id,
          packageName: pkg?.packageName,
          quantity: pkg?.quantity,
          unitPrice: pkg?.unitPrice,
          totalAmount: pkg?.totalPrice,
        };
      }),
    };

    dispatch({
      type: "UPDATE_QUOTATION_REQUEST",
      payload: { quoteId: record.id, formData },
    });
    form.resetFields();
    setIsUpdateQuotationModalVisible(false);
  };
  const forms = [
    <QuotationUserInfo
      quotationData={quotationData}
      setQuotationData={setQuotationData}
      current={current}
      setCurrent={setCurrent}
    />,
    <QuotationPackageInfo
      quotationData={quotationData}
      setQuotationData={setQuotationData}
      current={current}
      setCurrent={setCurrent}
      packages={packages}
      setPackages={setPackages}
    />,
    <QuotationQuotationInfo
      quotationData={quotationData}
      setQuotationData={setQuotationData}
      current={current}
      setCurrent={setCurrent}
      addQuotationSubmitHandler={addQuotationSubmitHandler}
      setIsUpdateQuotationModalVisible={setIsUpdateQuotationModalVisible}
    />,
  ];
  return (
    <Drawer
      title="Update Quotation"
      open={isUpdateQuotationModalVisible}
      onClose={() => {
        setIsUpdateQuotationModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Step 1",
              description: "CLIENT INFORMATION",
            },
            {
              title: "Step 2",
              description: "PACKAGE INFORMATION",
            },
            {
              title: "Step 3",
              description: "Quotation INFORMATION",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default UpdateQuotation;
