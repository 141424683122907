import { Drawer, Steps } from "antd";
import React, { useEffect, useState } from "react";
import UpdateClientInformation from "./UpdateCases/UpdateClientInformation";
import moment from "moment";
import UpdateClientVisaInfo from "./UpdateCases/UpdateClientVisaInfo";
import UpdateClientPacakgeInfo from "./UpdateCases/UpdateClientPacakgeInfo";
import UpdateCasePreview from "./UpdateCases/UpdateCasePreview";

export default function UpdateCase({
  record,
  isUpdateCase,
  setIsUpdateCase,
  fetchAllCases,
}) {
  const [current, setCurrent] = useState(0);

  console.log("record record", record);

  const [caseFormData, setCaseFormData] = useState({
    id: record?.id,
    //client info
    assignedToEmail: record?.caseAssignedTo,
    customerId: record?.customer?.id,
    firstName: record?.customer?.firstName,
    lastName: record?.customer?.lastName,
    email: record?.customer?.email,
    mobile: record?.customer?.mobile,
    dob: record?.customer?.dob ? moment(record?.customer?.dob) : null,
    phone: record?.customer?.phone,
    address: record?.customer?.address,
    passportNumber: record?.customer?.passportNumber,
    maritalStatus: record?.customer?.maritalStatus,
    sexStatus: record?.customer?.sexStatus,
    countryOfResidence: record?.customer?.countryOfResidence,
    leadType: record?.leadType,
    salesRepresentativeId: record?.salesRepresentativeResponse?.id,
    salesRepresentative: record?.salesRepresentativeResponse?.salesRepName,

    //visa info
    visaType: record?.visaCategory,
    visaSubClassId: record?.visaSubClass?.id,
    visaSubClass: record?.visaSubClass?.subClass,
    country: record?.institute?.country?.name,
    countryId: record?.institute?.country?.id,
    currentStatus: record?.isOffShore,
    instituteId: record?.institute?.id,
    instituteName: record?.institute?.instituteName,
    degreeId: record?.courses?.degree?.id,
    degreeName: record?.courses?.degree?.name,
    courseId: record?.courses?.id,
    courseName: record?.courses?.name,
    intakeId: record?.collegeIntake?.id,
    intakeName: record?.collegeIntake?.name,
    courseFee: record?.isOffShore
      ? record?.courses?.offShoreFee
      : record?.courses?.onShoreFee,
    isScholershipAllowed: record?.isScholarshipAllowed,
    scholarShipAmount: record?.scholarShipAmount,
    scholarShipType: record?.scholarShipType === "FLAT" ? true : false,
    wantToPayFor: record?.wantToPayFor,
    fee: record?.fee,
    workflowId: record?.workFlowDto?.id,
    workflowName: record?.workFlowDto?.statusName,
    workflowStatusId: record?.workFlowStatusDto?.id,
    workflowStatusName: record?.workFlowStatusDto?.statusName,

    //package information
    isAddPackage: record?.casePackages?.length > 0 ? true : false,
    currency: record?.currency,
    casePackages:
      record?.casePackages?.length > 0
        ? record?.casePackages?.map((pkg) => {
            const data = record?.casePackagesDetailsDtoList?.find(
              (item) => item?.id === pkg?.id
            );
            return {
              packageName: pkg?.packageName,
              quantity: data?.quantity,
              totalPrice: data?.totalPrice,
              unitPrice: data?.unitPrice,
            };
          })
        : [
            {
              packageName: "",
              quantity: "",
              totalPrice: "",
              unitPrice: "",
            },
          ],
    isDiscountAllowed: record?.isDiscountAllowed,
    discount: record?.discount,
    packageDiscountType: record?.packageDiscountType,
    description: record?.description,
    priorNote: record?.priorNote,
    deadlineDate: record?.deadlineDate ? moment(record?.deadlineDate) : null,
    consultantId: record?.consultant?.map((data) => data?.id),
    superAgentId: record?.superAgent?.map((data) => data?.id),
    workflowDocumentChecklist: record?.requiredWorkflowCheckList?.map(
      (data) => data?.documentCheckListResponse?.id
    ),
    coursesDocumentChecklist: record?.requiredCoursesCheckList?.map(
      (data) => data?.courseCheckListResponse?.id
    ),
  });

  const multistepForm = [
    <UpdateClientInformation
      setCurrent={setCurrent}
      caseFormData={caseFormData}
      setCaseFormData={setCaseFormData}
    />,
    <UpdateClientVisaInfo
      setCaseFormData={setCaseFormData}
      caseFormData={caseFormData}
      setCurrent={setCurrent}
    />,
    <UpdateClientPacakgeInfo
      setCurrent={setCurrent}
      caseFormData={caseFormData}
      setCaseFormData={setCaseFormData}
    />,
    <UpdateCasePreview
      setCurrent={setCurrent}
      caseFormData={caseFormData}
      setIsUpdateCase={setIsUpdateCase}
      fetchAllCases={fetchAllCases}
    />,
  ];

  return (
    <Drawer
      title="Update Case"
      onClose={() => {
        setIsUpdateCase(false);
      }}
      open={isUpdateCase}
      footer={null}
      width={1163}
    >
      <div style={{ padding: "0 2rem" }}>
        <Steps
          current={current}
          // onChange={(e) => {
          //   setCurrent(e);
          // }}
          items={[
            {
              title: "Step 1",
              description: "CLIENT INFORMATION",
            },
            {
              title: "Step 2",
              description: "VISA INFORMATION",
            },
            {
              title: "Step 3",
              description: "PACKAGE INFORMATION",
            },
            {
              title: "Step 4",
              description: "PREVIEW AND SUBMIT",
            },
          ]}
        />
        <div>{multistepForm[current]}</div>
      </div>
    </Drawer>
  );
}
