import {
  Button,
  Card,
  Divider,
  Drawer,
  Image,
  Popconfirm,
  Radio,
  Switch,
  Tag,
  message,
} from "antd";
import React, { useEffect } from "react";
import { formattedDate, twoTagResponses } from "../../HelperFunction";
import { FcDocument } from "react-icons/fc";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ImCheckboxChecked } from "react-icons/im";
import UpdateCompanyPackage from "./UpdateCompanyPackage";
import AddCasePackage from "../Visas/AddCasePackage";
import client from "../../Assets/Company.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";

const CompanyDetails = ({
  Record,
  isCompanyDetalisModalVisible,
  setIsCompanyDetalisModalVisible,
}) => {
  const visaState = useSelector((state) => state.VisasReducer);

  const dispatch = useDispatch();

  const [current, setCurrent] = useState("companyDetail");
  const [packageObj, setPackageObj] = useState("");
  const [record, setRecord] = useState(Record);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  const companyDetailsTabList = [
    {
      key: "companyDetail",
      tab: "Company Detail",
    },
    {
      key: "packages",
      tab: "Packages & Commission",
    },
  ];

  const fetchCompanyByID = () => {
    dispatch({
      type: "FETCH_COMPANY_BY_ID_REQUEST",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {
        setRecord(res?.data);
      },
    });
  };

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  const handleUpdatePackageStatus = () => {
    packageObj?.isActive
      ? dispatch({
          type: "UPDATE_PACKAGE_DEACTIVATE_REQUEST",
          payload: packageObj?.id,
          isSuccessFn: true,
          successFunction: (res) => {
            message.success(res?.data?.message);
            fetchCompanyByID();
          },
        })
      : dispatch({
          type: "UPDATE_PACKAGE_ACTIVE_REQUEST",
          payload: packageObj?.id,
          isSuccessFn: true,
          successFunction: (res) => {
            message.success(res?.data?.message);
            fetchCompanyByID();
          },
        });
  };
  console.log("record", record);
  return (
    <Drawer
      title="Company Details"
      open={isCompanyDetalisModalVisible}
      onClose={() => {
        setIsCompanyDetalisModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          <Image
            style={{ background: "#e4edfb" }}
            src={client}
            className={"clientImage"}
          />

          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
              gap: "0.5rem",
              minHeight: "10rem",
              position: "relative",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {record?.companyName}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.4rem",
                color: "#aaa",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <span> {record?.fullName}</span>
              {record?.mobile && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {record?.mobile}
                </span>
              )}
              {record?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.address}
                </span>
              )}
              {record?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.email}
                </span>
              )}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                position: "absolute",
                bottom: 0,
              }}
            >
              {" "}
              <button
                className="emailAndMessage"
                onClick={() => {
                  // setIsSendComposedEmailModalVisible(true);
                }}
              >
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={companyDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "companyDetail" && (
            <>
              {detailFun("Company Id", `${record?.id ? record?.id : "N/A"}`)}
              {detailFun("email", `${record?.email ? record?.email : "N/A"}`)}
              {detailFun(
                "Mobile Number",
                `${record?.mobile ? record?.mobile : "N/A"}`
              )}
              {detailFun(
                "Phone Number",
                `${record?.phoneNumber ? record?.phoneNumber : "N/A"}`
              )}
              {detailFun(
                "Adress Line 1",
                `${record?.address ? record?.address : "N/A"}`
              )}
              {detailFun(
                "Adress Line 2",
                `${record?.address2 ? record?.address2 : "N/A"}`
              )}
              {detailFun(
                "Agreement Start Date",
                `${
                  record?.agreementStartDate
                    ? formattedDate(record?.agreementStartDate)
                    : "N/A"
                }`
              )}
              {detailFun(
                "Agreement Expiry Date",
                `${
                  record?.agreementExpiryDate
                    ? formattedDate(record?.agreementExpiryDate)
                    : "N/A"
                }`
              )}

              {detailFun(
                "Commission",
                `${record?.commission ? record?.commission : "N/A"}`
              )}
              {detailFun(
                "Is Direct Partner",
                `${record?.isDirectPartner ? "Yes" : "No"}`
              )}
              {detailFun(
                "Country",
                `${record?.country?.name ? record?.country?.name : "N/A"}`
              )}

              {detailFun(
                "PostCode",
                `${record?.postCode ? record?.postCode : "N/A"}`
              )}
              {detailFun("Staff", `${record?.staff ? record?.staff : "N/A"}`)}
              {detailFun("State", `${record?.state ? record?.state : "N/A"}`)}
              {detailFun(
                "suburb",
                `${record?.suburb ? record?.suburb : "N/A"}`
              )}
            </>
          )}
          {current === "packages" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1px",
                flexWrap: "wrap",
              }}
            >
              <Button
                type="primary"
                style={{ width: "100%", margin: "0 0 1rem" }}
                onClick={() => {
                  setIsAddPackageModalVisible(true);
                }}
              >
                Add Company Pacakge
              </Button>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                {record?.casePackages?.length > 0 ? (
                  record?.casePackages?.map((dataObj) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          backgroundColor: "#f5f5f560",
                          width: "100%",
                          borderRadius: "0.5rem",
                          padding: "1rem",
                          border: "1px solid #B4B4B840",
                        }}
                      >
                        <div key={dataObj?.id}>
                          {dataObj?.company?.name !== undefined && (
                            <>
                              {detailFun(
                                "Company",
                                `${
                                  dataObj?.company?.name
                                    ? dataObj?.company?.name
                                    : "N/A"
                                }`
                              )}
                            </>
                          )}
                          {dataObj?.institute?.name !== undefined && (
                            <>
                              {detailFun(
                                "Company",
                                `${
                                  dataObj?.institute?.name
                                    ? dataObj?.institute?.name
                                    : "N/A"
                                }`
                              )}
                            </>
                          )}
                          {detailFun(
                            "Package Name",
                            `${
                              dataObj?.packageName
                                ? dataObj?.packageName
                                : "N/A"
                            }`
                          )}

                          {detailFun(
                            "Unit Price",
                            `${
                              dataObj?.unitPrice
                                ? `${dataObj?.unitPrice} ${
                                    dataObj?.currency ? dataObj?.currency : ""
                                  }`
                                : "N/A"
                            }`
                          )}
                          {dataObj?.gst !== "" &&
                            dataObj?.gst !== null &&
                            dataObj?.gst !== undefined && (
                              <>
                                {detailFun(
                                  "GST/VAT",
                                  `${
                                    dataObj?.gst
                                      ? `${dataObj?.gst} ${
                                          dataObj?.isGstPercentage === true
                                            ? `% `
                                            : dataObj?.isGstFlat
                                            ? `${
                                                dataObj?.currency
                                                  ? dataObj?.currency
                                                  : ""
                                              } FLAT`
                                            : ""
                                        }`
                                      : "N/A"
                                  }`
                                )}
                              </>
                            )}
                          {dataObj?.commission !== "" &&
                            dataObj?.commission !== null &&
                            dataObj?.commission !== undefined && (
                              <>
                                {detailFun(
                                  "Commission",
                                  `${
                                    dataObj?.commission
                                      ? `${dataObj?.commission} ${
                                          dataObj?.isCommissionPercentage ===
                                          true
                                            ? `% `
                                            : dataObj?.isCommissionFlat
                                            ? `${
                                                dataObj?.currency
                                                  ? dataObj?.currency
                                                  : ""
                                              } FLAT`
                                            : ""
                                        }`
                                      : "N/A"
                                  }`
                                )}
                              </>
                            )}

                          {detailFun(
                            "Package Status",

                            dataObj?.isActive === true ? (
                              <Tag style={{ margin: "0" }} color="green">
                                Active
                              </Tag>
                            ) : (
                              <Tag style={{ margin: "0" }} color="red">
                                Inactive
                              </Tag>
                            )
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1rem",
                          }}
                        >
                          <Popconfirm
                            title="Do you wnat to change the Package status?"
                            onConfirm={handleUpdatePackageStatus}
                            // onCancel={() => setIsUpdateStatus(false)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="outline"
                              danger
                              onClick={() => {
                                setPackageObj(dataObj);
                              }}
                            >
                              Update Package Status
                            </Button>{" "}
                          </Popconfirm>

                          <Button
                            type="primary"
                            onClick={() => {
                              setPackageObj(dataObj);
                              setIsUpdateModalVisible(true);
                            }}
                          >
                            Update Package{" "}
                          </Button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No Company Packages Available.
                  </div>
                )}
              </div>
            </div>
          )}
        </Card>
      </Card>
      {isUpdateModalVisible && (
        <UpdateCompanyPackage
          fetchCompanyByID={fetchCompanyByID}
          isUpdateModalVisible={isUpdateModalVisible}
          setIsUpdateModalVisible={setIsUpdateModalVisible}
          record={packageObj}
        />
      )}

      {isAddPackageModalVisible && (
        <AddCasePackage
          from={"COMPANY"}
          typeDetail={true}
          fetchCompanyByID={fetchCompanyByID}
          companyRecord={record}
          isAddPackageModalVisible={isAddPackageModalVisible}
          setIsAddPackageModalVisible={setIsAddPackageModalVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [record?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default CompanyDetails;
