import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiFillHeart, AiOutlineEye, AiOutlineSend } from "react-icons/ai";
import CoursesDetail from "./CoursesDetail";
import { ImCompass } from "react-icons/im";
import AddVisaCases from "../Visas/AddVisas";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { DeleteOutlined } from "@ant-design/icons";
const { Option } = Select;
const columns = [
  {
    title: "Course Name",
    dataIndex: "name",
    width: 100,
    align: "center",
  },
  {
    title: "Degree",
    dataIndex: "degree",
    width: 100,
    align: "center",
  },
  {
    title: "Course Duration",
    dataIndex: "courseDuration",
    width: 100,
    align: "center",
  },

  {
    title: "Institute Name",
    dataIndex: "institute",
    width: 250,
    align: "center",
  },
  {
    title: "Onshore Fee",
    dataIndex: "onshoreFee",
    width: 250,
    align: "center",
  },
  {
    title: "Offshore Fee",
    dataIndex: "offshoreFee",
    width: 250,
    align: "center",
  },
  {
    title: "Intake/s",
    dataIndex: "intake",
    width: 250,
    align: "center",
  },

  {
    title: "Status",
    dataIndex: "isActive",
    width: 250,
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 250,
    align: "center",
  },
];

const CourseWishlist = ({
  from,
  appointmentRecord,
  //   isCourseWishlistModalVisible,
  //   setIsCourseWishlistModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      coursesState: state.CoursesReducer,
      profileState: state.ProfileReducer,
    };
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    dispatch({
      type: "FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST",
      payload: {
        leadId: appointmentRecord?.leadId,
        page,
        pageSize,
      },
    });
  }, [page, pageSize]);
  const reloadFn = () => {
    dispatch({
      type: "FETCH_COURSE_WISHLIST_FOR_LEAD_ID_REQUEST",
      payload: {
        leadId:
          from === "LEAD" ? appointmentRecord?.id : appointmentRecord?.leadId,
        page,
        pageSize,
      },
    });
  };
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isCourseDetailsModelVisible, setIsCourseDetailsModelVisible] =
    useState(false);
  const [courseRecord, setCourseRecord] = useState({});
  const [enrollType, setEnrollType] = useState("ONSHORE");
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);

  const data = store.coursesState?.courseWishlist?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      name: (
        <Tooltip title="Institute Details">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setCourseRecord(dataObj?.courses);
                setIsCourseDetailsModelVisible(true);
              }}
            >
              {dataObj?.courses?.name ?? "N/A"}
            </div>
          </a>
        </Tooltip>
      ),
      degree: dataObj?.courses?.name?.degree,
      courseDuration:
        dataObj?.courses?.courseDuration +
        " " +
        dataObj?.courses?.courseAccordingTo,
      institute: dataObj?.courses?.institute?.name,
      degree: dataObj?.courses?.degree?.name,
      onshoreFee: dataObj?.courses?.onShoreFee ?? "N/A",
      offshoreFee: dataObj?.courses?.offShoreFee ?? "N/A",
      intake: dataObj?.courses?.collegeIntakeResponseList?.map((dataObj) => {
        return (
          <Tag key={dataObj.id} color="blue">
            {dataObj.intakeSessionName}
          </Tag>
        );
      }),
      isActive: dataObj?.courses?.isActive ? (
        <p className="greenTag">ACTIVE</p>
      ) : (
        <p className="redTag">INACTIVE</p>
      ),
      actions: (
        <Space size="middle">
          <Tooltip title="Course Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCourseRecord(dataObj?.courses);
                  setIsCourseDetailsModelVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
            "ADD"
          ) && (
            <Tooltip title="Remove course from wishlist">
              <Popconfirm
                title="Are you sure to remove this course from wishlist?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_COURSE_FROM_WISHLIST_REQUEST",
                    payload: { wishListId: dataObj.id },
                    isReload: true,
                    reloadFn: reloadFn,
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <AiFillHeart color="red" />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
          )}
          {dataObj?.courses?.both &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ADD"
            ) && (
              <>
                <Tooltip title="Onshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("ONSHORE");
                        setCourseRecord(dataObj?.courses);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <AiOutlineSend />
                    </div>
                  </a>
                </Tooltip>
                <Tooltip title="Offshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("OFFSHORE");

                        setCourseRecord(dataObj?.courses);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <ImCompass />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
          {dataObj?.courses?.onshore &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ADD"
            ) && (
              <>
                <Tooltip title="Onshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("ONSHORE");

                        setCourseRecord(dataObj?.courses);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <AiOutlineSend />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
          {dataObj?.courses?.offshore &&
            store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ADD"
            ) && (
              <>
                <Tooltip title="Offshore Enroll">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setEnrollType("OFFSHORE");
                        setCourseRecord(dataObj?.courses);
                        setIsAddVisasModalVisible(true);
                      }}
                    >
                      <ImCompass />
                    </div>
                  </a>
                </Tooltip>
              </>
            )}
        </Space>
      ),
    };
  });

  return (
    // <Drawer
    //   title="Course Wishlist"
    //   open={isCourseWishlistModalVisible}
    //   onClose={() => {
    //     setIsCourseWishlistModalVisible(false);
    //   }}
    //   footer={null}
    //   width={1163}
    // >
    <div
      className="flexColumnWithoutStyle"
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
      }}
    >
      {store.coursesState?.isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <Table
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
          />

          {store.coursesState?.courseWishlist?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store.coursesState?.courseWishlist?.totalPage * 10}
            />
          ) : (
            ""
          )}
        </>
      )}
      {isCourseDetailsModelVisible && (
        <CoursesDetail
          fromWishlist={true}
          record={courseRecord}
          fromCounselling={true}
          recordData={appointmentRecord}
          instituteRecordForCountry={courseRecord?.institute}
          isCourseDetailsModelVisible={isCourseDetailsModelVisible}
          setIsCourseDetailsModelVisible={setIsCourseDetailsModelVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromCounselling={true}
          recordData={appointmentRecord}
          courseRecord={courseRecord}
          instituteRecordForCountry={courseRecord}
          enrollType={enrollType}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
    </div>

    // </Drawer>
  );
};

export default CourseWishlist;
