import { Button, Card, Form, Input, Modal, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  ExclamationCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { timeZoneOffset } from "../../../HelperFunction";
import OrganizationalSettings from "../OrganizationalSettings";
import axios from "axios";
import Cookies from "js-cookie";
import countriesAndTimezones from "countries-and-timezones";
import { setLocalStorage } from "../../../Helpers/FrontendHelper";

const { Option } = Select;
const { confirm } = Modal;

const GeneralSettings = () => {
  const store = useSelector((state) => {
    return {
      generalState: state.OrganizationSettingsReducer,
      profileState: state.ProfileReducer,
      leadState: state.LeadReducer,
      branchState: state.BranchReducer,
    };
  });

  const [countryList, setCountryList] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [defaultCountry, setDefaultCountry] = useState();
  const [defaultTimezone, setDefaultTimezone] = useState();
  const [defaultTimezoneValue, setDefaultTimezoneValue] = useState();
  const [timezoneList, setTimezoneList] = useState([]);

  useEffect(() => {
    setDefaultCountry(store.profileState.profile?.country);
    setDefaultCurrency(store.profileState.profile?.defaultCurrency);
    setDefaultTimezone(store.profileState.profile?.timezone);
    setDefaultTimezoneValue(store.profileState.profile?.timeZoneValue);
  }, [store.profileState]);
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      payload: {
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, [store.branchState?.branchRecord]);

  const profileSuccessFunction = (response) => {
    const permissionList = Object.entries(
      response?.data?.department?.rolesAndPermissionList
    )?.map((key) => {
      if (key[1] !== null) {
        return key[0];
      }
      return;
    });
    setLocalStorage("menuList", permissionList);
    setGeneralSettingsData((previousData) => {
      return {
        ...previousData,
        isBranch: response?.data?.isBranch,
      };
    });
  };
  const successFunction = (response) => {
    setGeneralSettingsData((previousData) => {
      return {
        ...previousData,
        workingHour: response?.data?.expiryDates?.workingHours,
        additionalEmails: response?.data?.additionalEmails,
        gst: response?.data?.expiryDates?.gst,
        branchImported: response?.data?.branchImported,
        adminInstituteImported: response?.data?.adminInstituteImported,
        companyDomain: response?.data?.loginCompanyUrl,
      };
    });
  };

  const [generalSettingsData, setGeneralSettingsData] = useState({
    workingHour: "",
    timezoneOffset: null,
    timezone: null,
    gmt: null,
    gst: null,
    additionalEmails: [],
    adminInstituteImported: false,
    branchImported: false,
    companyDomain: null,
    isBranch: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (countryList.length > 0 && defaultCountry) {
      const country = countryList.filter(
        (value) =>
          value.countryName?.toUpperCase() === defaultCountry?.toUpperCase()
      );
      if (country[0]?.countryShortForm) {
        const countryInfo = countriesAndTimezones.getCountry(
          country[0]?.countryShortForm
        );
        setTimezoneList(countryInfo.timezones);
      }
    }
  }, [countryList, defaultCurrency]);

  useEffect(() => {
    dispatch({
      type: "FETCH_PROFILE_REQUEST",
      isSuccessFn: true,
      successFunction: profileSuccessFunction,
    });
    form.setFieldValue({
      users: [
        {
          email: "anisasdh@gmail.com",
        },
      ],
    });

    fetchCountry();
  }, []);

  const fetchCountry = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const visaSuccessFunction = (response) => {
    setDefaultCurrency(response?.data?.defaultSelectedCurrency ?? null);
  };

  form.setFieldValue({
    users: [
      {
        id: 1,
        email: "anisasdh@gmail.com",
      },
    ],
  });

  const handleChangeCountry = (value, option) => {
    const tempValue = JSON.parse(option.key);
    setDefaultCountry(value);
    setDefaultCurrency(tempValue.currency);
    setDefaultTimezone(tempValue.timezone);
    // setDefaultTimezoneValue("");
  };
  return (
    <OrganizationalSettings>
      <div>
        <h3>General Settings</h3>
        <Card>
          <Form
            labelCol={{ span: 4 }}
            fields={[
              {
                name: ["domain"],
                value: generalSettingsData?.companyDomain,
              },
            ]}
          >
            {!generalSettingsData?.isBranch && (
              <Form.Item
                label="Domain"
                name={"domain"}
                rules={[
                  {
                    validator: async (_, value) => {
                      const response = await axios.get(
                        // `${process.env.REACT_APP_TEST_URL}v1/api/customer/validate-company-url?loginCompanyUrl=${value}`,
                        `v1/api/customer/validate-company-url?loginCompanyUrl=${value}`,
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                      if (
                        response?.data === false ||
                        value === "" ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else if (response?.data === true) {
                        return Promise.reject(
                          new Error(
                            "Entered domain already exists! Try another one."
                          )
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input
                  value={generalSettingsData?.companyDomain}
                  placeholder="companyName"
                  addonAfter=".khrouch.com"
                  onChange={(e) => {
                    setGeneralSettingsData((previousData) => {
                      return {
                        ...previousData,
                        companyDomain: e.target.value,
                      };
                    });
                  }}
                />
              </Form.Item>
            )}

            <Form.Item label="Country">
              <Select
                value={defaultCountry}
                onChange={handleChangeCountry}
                placeholder="Select Country"
              >
                {countryList?.map((country) => {
                  return (
                    <Option
                      key={JSON.stringify(country)}
                      value={country.countryName}
                    >
                      {country.countryName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Currency">
              <Input
                value={defaultCurrency}
                placeholder="Enter enter default currency"
                onChange={(e) => {
                  setDefaultCurrency(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Timezone">
              <Select
                value={defaultTimezoneValue}
                onChange={(value) => setDefaultTimezoneValue(value)}
                placeholder="Select Timezone"
              >
                {timezoneList?.map((timezone) => {
                  return <Option value={timezone}>{timezone}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Timezone" style={{ display: "none" }}>
              <Input
                readOnly
                value={defaultTimezone}
                placeholder="Enter enter default Timezone"
              />
            </Form.Item>
            <Form.Item label="Working Hours">
              <Input
                type="number"
                value={generalSettingsData.workingHour}
                placeholder="Enter Working Hour"
                onChange={(e) => {
                  setGeneralSettingsData((previousData) => {
                    return {
                      ...previousData,
                      workingHour: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="GST/TAX">
              <Input
                type="number"
                value={generalSettingsData.gst}
                placeholder="GST/TAX"
                onChange={(e) => {
                  setGeneralSettingsData((previousData) => {
                    return {
                      ...previousData,
                      gst: e.target.value,
                    };
                  });
                }}
              />
            </Form.Item>
            {/* <Form.Item label="Timezone">
              <Select
                showSearch
                // value={generalSettingsData.}
                onSearch={(e) => {
                  console.log("On search", e);
                }}
                placeholder="Enter your preferred timezone"
                filterOption={(input, option) => {
                  console.log("input and option", input, option);
                  return (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                // Option={[...timeZoneOffset]}
              >
                {timeZoneOffset.map((dataObj, index) => {
                  return (
                    <Option key={index} value={dataObj.name}>
                      <div
                        onClick={() => {
                          setGeneralSettingsData((previousData) => {
                            return {
                              ...previousData,
                              gmt: dataObj.offset,
                              timezone: dataObj.name,
                              timezoneOffset: dataObj.gmt,
                            };
                          });
                          dispatch({
                            type: "CHANGE_TIMEZONE_REQUEST",
                            payload: {
                              gmt: dataObj.offset,
                              timezone: dataObj.name,
                              offset: dataObj.gmt,
                            },
                          });
                        }}
                        className={"selectOptionDiv"}
                      >
                        {dataObj.name}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
          </Form>
          {/* <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              console.log("valuesGS", values);
              const filteredEmail = values?.users?.map((dataObj) => {
                const emails = generalSettingsData?.additionalEmails.filter(
                  (item) => {
                    if (dataObj.email !== item.email) {
                      return dataObj.email;
                    }
                  }
                );
                console.log("filtered emaila", emails);
              });
              console.log("filteredEmail", filteredEmail);

              // dispatch({
              //   type: "ADDITIONAL_EMAILS_FROM_GENERAL_SETTINGS_REQUEST",
              //   payload: { additionalEmails: values?.users },
              // });
            }}
            style={{
              maxWidth: "100%",
            }}
            autoComplete="off"
            fields={generalSettingsData.additionalEmails?.map((item, index) => {
              return { name: ["users", index, "email"], value: item.email };
            })}

            // fields={[
            //   {
            //     name: ["users", 0, "email"],
            //     value: "anksdfjaksj@saklfd.com",
            //   },
            // ]}
          >
            <div
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.4rem",
              }}
            >
              <h4>Additional Emails:</h4>
              <Form.List
                fields={[
                  {
                    name: "email",
                    value: "anksdfjaksj@saklfd.com",
                  },
                ]}
                name="users"
                initialValue={generalSettingsData.additionalEmails?.map(
                  (item, index) => {
                    return {
                      fieldKey: index,
                      isListField: true,
                      key: index,
                    };
                  }
                )}
              >
                {(fields, { add, remove }) => {
                  console.log("fieldsaddEmail", fields);

                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "100%",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <Form.Item
                            label="Additional Email"
                            labelCol={{ span: 4 }}
                            {...restField}
                            name={[name, "email"]}
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: "0",
                            }}
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder="e.g. username@gmail.com"
                            />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Additional Email
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div> */}

          {store.profileState?.profile?.department?.rolesAndPermissionList?.GENERAL_SETTINGS?.includes(
            "UPDATE"
          ) && (
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  dispatch({
                    type: "SET_DEFAULT_CURRENCY_REQUEST",
                    payload: {
                      branchId:
                        store.branchState?.branchRecord.id ??
                        localStorage.getItem("branchId"),
                      selectCurrency: defaultCurrency,
                      country: defaultCountry,
                      timezone: defaultTimezone.toString(),
                      timeZoneValue: defaultTimezoneValue,
                    },
                  });
                  dispatch({
                    type: "UPDATE_EXPIRY_REMINDER_WORKING_HOUR_REQUEST",
                    payload: {
                      branchId:
                        store.branchState?.branchRecord.id ??
                        localStorage.getItem("branchId"),
                      workingHours: generalSettingsData.workingHour,
                      gst: generalSettingsData.gst,
                    },
                  });
                  if (
                    generalSettingsData?.companyDomain &&
                    generalSettingsData?.companyDomain !==
                      store.generalState?.reminderExpiryWorkingHour
                        ?.loginCompanyUrl
                  ) {
                    dispatch({
                      type: "ADD_COMPANY_DOMAIN_URL_REQUEST",
                      payload: {
                        companyDomainUrl: generalSettingsData?.companyDomain,
                        branchId:
                          store.branchState?.branchRecord.id ??
                          localStorage.getItem("branchId"),
                      },
                    });
                  }
                }}
              >
                Save Changes
              </Button>
            </Form.Item>
          )}
          {/* </Form> */}
        </Card>
      </div>
    </OrganizationalSettings>
  );
};

export default GeneralSettings;
