import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Steps,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import QuotationUserInfo from "./QuotationUserInfo";
import QuotationPackageInfo from "./QuotationPackageInfo";
import QuotationQuotationInfo from "./QuotationQuotationInfo";
import { date, date00 } from "../../HelperFunction";
const { Option } = Select;

const AddQuotation = ({
  quotationSettingsData,
  quotationRecord,
  isAddQuotationModalVisible,
  setIsAddQuotationModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [quotationData, setQuotationData] = useState({
    clientType: "EXISTING",
    customerId: quotationRecord?.id ?? null,
    customer: `${quotationRecord?.fullName ?? ""}  ${
      quotationRecord?.email !== null &&
      quotationRecord?.email !== "" &&
      quotationRecord?.email !== undefined
        ? `(${quotationRecord?.email})`
        : quotationRecord?.mobile !== null &&
          quotationRecord?.mobile !== "" &&
          quotationRecord?.mobile !== undefined
        ? `(${quotationRecord?.mobile})`
        : ""
    }`,
    firstName: quotationRecord?.firstName ?? null,
    lastName: quotationRecord?.lastName ?? null,
    mobile: quotationRecord?.mobile ?? null,
    email: quotationRecord?.email ?? null,
    dob: quotationRecord?.dob ?? null,
    phone: quotationRecord?.phone ?? null,
    address: quotationRecord?.address ?? null,
    passportNumber: quotationRecord?.passportNumber ?? null,
    maritalStatus: quotationRecord?.maritalStatus ?? null,
    sexStatus: quotationRecord?.sexStatus ?? null,
    countryOfResidence: quotationRecord?.countryOfResidence ?? null,
    referral: quotationRecord?.referral?.id ?? null,
    referralName: quotationRecord?.referral?.fullName ?? null,
    aboutUs: null,
    aboutUsName: null,

    natureOfProject: null,
    grossPrice: null,
    isDiscountAllowed: null,
    discount: null,
    isPercentage: null,
    gstApplicable: null,
    gstAmount: null,
    totalAmount: null,
    isFinanceOptions: null,
    associatedTimelineId: null,
    associatedTimelineName: null,
    paymentTermsInMonths: null,
    description: null,
    specialNote: null,
    paymentTermsInfo: null,
    displayPaymentTermsInfoInQuoteConfirmation: true,
    quotesUpdate: null,
    displayQuotesUpdateInQuoteConfirmation: true,
    quoteStatus: "QUOTE_DRAFT",
    quoteSource: null,
    quoteSourceName: null,
    quoteExpiryDate: moment().add(
      quotationSettingsData?.quoteExpiryDateAfter,
      "days"
    ),
    modeOfContact: null,
    quotationPackages: null,
    currency: null,
  });
  const [packages, setPackages] = useState([
    {
      id: null,
      packageName: "",
      quantity: 0,
      totalPrice: 0,
      unitPrice: 0,
      hasGst: null,
      gst: null,
      isGstFlat: null,
      isGstPercentage: null,
    },
  ]);

  const [current, setCurrent] = useState(0);
  const quotationPackagesIds = () => {
    let ids = [];
    if (packages[0].id !== null) {
      ids = packages.map((item) => {
        if (item.id !== null) {
          return item.id;
        } else {
          return;
        }
      });
    }
    return ids;
  };

  const addQuotationSubmitHandler = (value) => {
    const formData = {
      customerId: quotationData?.customerId,
      newCustomerRequest: {
        firstName: quotationData.firstName,
        lastName: quotationData.lastName,
        email: quotationData.email,
        maritalStatus: quotationData.maritalStatus,
        dob: quotationData.dob ? date(quotationData.dob) : null,
        referralId: quotationData.referral,
        gender: quotationData.sexStatus,
        mobile: quotationData.mobile,
        passportNumber: quotationData.passportNumber,
      },
      natureOfProject: quotationData.natureOfProject,
      associatedTimelineId: quotationData.associatedTimelineId,
      grossPrice: 0,
      discount: quotationData?.discount ? quotationData?.discount : 0,
      isPercentage: quotationData.isPercentage === "PERCENTAGE" ? true : false,
      gstApplicable: quotationData?.gstApplicable
        ? quotationData?.gstApplicable
        : 0,
      gstAmount: quotationData?.gstAmount ? quotationData?.gstAmount : 0,
      totalAmount: 0,
      isFinanceOptions: quotationData.isFinanceOptions,
      paymentTermsInMonths: quotationData.paymentTermsInMonths,
      description: quotationData.description,
      specialNote: quotationData.specialNote,
      paymentTermsInfo: quotationData.paymentTermsInfo,
      displayPaymentTermsInfoInQuoteConfirmation:
        quotationData.displayPaymentTermsInfoInQuoteConfirmation,
      quotesUpdate: quotationData.quotesUpdate,
      displayQuotesUpdateInQuoteConfirmation:
        quotationData.displayQuotesUpdateInQuoteConfirmation,
      quoteStatus: quotationData.quoteStatus,
      quoteSource: quotationData.quoteSource,
      aboutUsId: quotationData.aboutUs,
      quoteExpiryDate: quotationData.quoteExpiryDate
        ? date00(quotationData.quoteExpiryDate)
        : null,
      modeOfContact: quotationData.modeOfContact,
      quotationPackages: [...quotationPackagesIds()],
      quotationPackageDetailsRequests:
        packages?.length > 1
          ? packages?.map((pkg) => {
              return {
                packageId: pkg?.id,
                packageName: pkg?.packageName,
                quantity: pkg?.quantity,
                unitPrice: pkg?.unitPrice,
                totalAmount: pkg?.totalPrice,
              };
            })
          : packages[0]?.id !== null
          ? packages?.map((pkg) => {
              return {
                packageId: pkg?.id,
                packageName: pkg?.packageName,
                quantity: pkg?.quantity,
                unitPrice: pkg?.unitPrice,
                totalAmount: pkg?.totalPrice,
              };
            })
          : null,
    };

    dispatch({
      type: "ADD_QUOTATION_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
    });
    form.resetFields();
    setIsAddQuotationModalVisible(false);
  };
  const forms = [
    <QuotationUserInfo
      quotationData={quotationData}
      setQuotationData={setQuotationData}
      current={current}
      setCurrent={setCurrent}
    />,
    <QuotationPackageInfo
      quotationData={quotationData}
      setQuotationData={setQuotationData}
      current={current}
      setCurrent={setCurrent}
      packages={packages}
      setPackages={setPackages}
    />,
    <QuotationQuotationInfo
      quotationData={quotationData}
      setQuotationData={setQuotationData}
      current={current}
      setCurrent={setCurrent}
      addQuotationSubmitHandler={addQuotationSubmitHandler}
      setIsAddQuotationModalVisible={setIsAddQuotationModalVisible}
    />,
  ];

  return (
    <Drawer
      title="Add Quotation"
      open={isAddQuotationModalVisible}
      onClose={() => {
        setIsAddQuotationModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Step 1",
              description: "CLIENT INFORMATION",
            },
            {
              title: "Step 2",
              description: "PACKAGE INFORMATION",
            },
            {
              title: "Step 3",
              description: "QUOTATION INFORMATION",
            },
          ]}
        />
      </div>
      {forms[current]}
    </Drawer>
  );
};

export default AddQuotation;
