import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
} from "antd";
import React, { useEffect } from "react";
import {
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  threeTagResponses,
} from "../../HelperFunction";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { FcDocument } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import SendComposedMail from "../Mailbox/SendComposedMail";
import { BsChatLeftText } from "react-icons/bs";
import StatusHistory from "./StatusHistory";
import AddVisaCases from "../Visas/AddVisas";
import AddAppointment from "../Appointments/AddAppointment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StartCounselling from "../Counsel/StartCounselling";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CiMobile3 } from "react-icons/ci";
import { MdPreview } from "react-icons/md";
import { VscDebugStart } from "react-icons/vsc";
import StatusHistoryConsellorAdd from "../Appointments/StatusHistoryConsellorAdd";
import UpdateAppointment from "../Appointments/UpdateAppointment";
import AppointmentDetails from "../Appointments/AppointmentDetails";
import CollegeFinderDrawer from "../CollegeFinder/CollegeFinderDrawer";
import CourseWishlist from "../Courses/CourseWishList";
import AddClient from "../Clients/AddClient";
import ClientDetails from "../Clients/ClientDetails";
const { TextArea } = Input;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Full Name",
    dataIndex: "firstName",
    sorter: (a, b) => {
      if (
        a?.firstName?.props?.children?.props?.children?.props?.children <
        b?.firstName?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.firstName?.props?.children?.props?.children?.props?.children >
        b?.firstName?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "mobileNumber",

    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    align: "center",
  },
  {
    title: "Appointment Date/Time",
    dataIndex: "bookingDate",

    align: "center",
  },
  {
    title: "Appointment Status",
    dataIndex: "appointmentStatus",
    align: "center",
  },
  {
    title: "Counsellor",
    dataIndex: "consultant",
    align: "center",
  },
  {
    title: "Lead Status",
    dataIndex: "leadStatus",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const LeadDetails = ({
  leadData,
  Record,
  isLeadsDetailsModelVisible,
  setIsLeadsDetailsModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      clientState: state.ClientsReducer,
    };
  });

  const [record, setRecord] = useState(Record);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isAddAppointmentModelVisible, setIsAddAppointmentModelVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [current, setCurrent] = useState("leadDetails");
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [
    isAppointmentStatusHistoryModelVisible,
    setIsAppointmentStatusHistoryModelVisible,
  ] = useState(false);
  const [isStartCounsellingModelVisible, setIsStartCounsellingModelVisible] =
    useState(false);
  const [
    isStatusHistoryCounsellorAddModelVisible,
    setIsStatusHistoryCounsellorAddModelVisible,
  ] = useState(false);
  const [isUpdateAppointmentModelVisible, setIsUpdateAppointmentModelVisible] =
    useState(false);
  const [
    isAppointmentDetailsModalVisible,
    setIsAppointmentDetailsModalVisible,
  ] = useState(false);
  const [isCollegeFinderModalVisible, setIsCollegeFinderModalVisible] =
    useState(false);
  const [isCourseWishlistModalVisible, setIsCourseWishlistModalVisible] =
    useState(false);
  const [appointmentRecord, setAppointmentRecord] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const fetchLeadDetailById = () => {
    dispatch({
      type: "FETCH_LEAD_ACTIVITY_LOG_BY_ID_REQUEST",
      payload: { leadId: record?.id },
    });
  };

  useEffect(() => {
    fetchLeadDetailById();

    dispatch({
      type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST",
      payload: { leadId: record?.id },
    });
    dispatch({
      type: "FETCH_LEAD_BY_ID_REQUEST",
      payload: record?.leadId,
      isSuccessFn: true,
      successFunction: successFunction,
    });
  }, []);

  const successFunction = (response) => {
    response.data.clientExistsId &&
      dispatch({
        type: "FETCH_CLIENT_BY_ID_REQUEST",
        payload: response.data.clientExistsId,
      });
  };

  const reloadAppointmentByLeadFn = (resposne) => {
    dispatch({
      type: "FETCH_APPOINTMENT_BY_LEAD_ID_REQUEST",
      payload: { leadId: record?.id },
    });
  };

  const appointmentDataSource =
    store.leadState?.appointmentsByLeadId?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${leadData.appointmentPerfix ?? ""}${dataObj.id}`,
        firstName: (
          <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setAppointmentRecord(dataObj);
                  setIsAppointmentDetailsModalVisible(true);
                }}
              >
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        appointmentStatus: dataObj?.appointmentStatus
          ? fourTagResponses(
              dataObj?.appointmentStatus,
              "PENDING",
              "COMPLETED",
              "CONFIRMED",
              "CANCELLED"
            )
          : "N/A",
        mobileNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <AiOutlineMail />
                <a href={`mailto:${dataObj.email}`}>{dataObj.email ?? "N/A"}</a>
              </div>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        // dataObj.mobile !== "" && dataObj.mobile !== null
        //   ? dataObj.mobile
        //   : "N/A",
        bookingDate:
          dataObj.counsellingBooking !== null &&
          dataObj.counsellingBooking?.bookingDate !== null
            ? `${dataObj.counsellingBooking?.bookingDate} ${
                dataObj.counsellingBooking?.startTime &&
                dataObj.counsellingBooking?.endTime
                  ? `${dataObj.counsellingBooking?.startTime}-${dataObj.counsellingBooking?.endTime}`
                  : ""
              }`
            : dataObj.appointmentDate
            ? formattedDate(dataObj.appointmentDate)
            : "N/A",
        bookingDay: dataObj.counsellingBooking?.bookingDay,
        sessionDuration: dataObj.counsellingBooking?.sessionTime,
        sessionTime:
          dataObj.counsellingBooking?.startTime +
          "-" +
          dataObj.counsellingBooking?.endTime,
        email: dataObj.email,
        campaign:
          dataObj?.leadResponse?.customerResponse?.campaign?.name ?? "N/A",
        consultant: dataObj.counsellor ? (
          dataObj.counsellor?.fullName
        ) : dataObj.counsellingBooking ? (
          dataObj.counsellingBooking?.consultant?.fullName
        ) : (
          <Tooltip title="Click to add counsellor">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setAppointmentRecord(dataObj);
                  setIsStatusHistoryCounsellorAddModelVisible(true);
                }}
              >
                N/A
              </div>
            </a>
          </Tooltip>
        ),
        leadStatus: dataObj.leadStatus ? (
          <>
            {dataObj.leadStatus === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatusColorCode}>BOOK AN APPOINTMENT</Tag>
            ) : (
              <Tag color={dataObj.leadStatusColorCode}>
                {dataObj.leadStatus}
              </Tag>
            )}
          </>
        ) : (
          "N/A"
        ),

        actions: (
          <Space size="middle">
            {/* {isHeadBranch && ( */}
            <>
              {dataObj.appointmentStatus === "COMPLETED" ||
              (dataObj.appointmentStatus === "CONFIRMED" &&
                store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
                  "CHANGE_STATUS"
                )) ? (
                <Tooltip
                  title={`${
                    dataObj.isSessionStarted
                      ? "View Counselling"
                      : "Start Counselling"
                  }`}
                >
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setIsStartCounsellingModelVisible(true);
                        setAppointmentRecord(dataObj);
                        dispatch({
                          type: "START_COUNSELLING_REQUEST",
                          payload: {
                            id: dataObj.id,
                            formData: {
                              confirmationText: "",
                            },
                            reloadAppointmentByLeadId: true,
                            reloadAppointmentByLeadFn:
                              reloadAppointmentByLeadFn,
                          },
                        });
                      }}
                    >
                      {dataObj.isSessionStarted ? (
                        <MdPreview />
                      ) : (
                        <VscDebugStart />
                      )}
                    </div>
                  </a>
                </Tooltip>
              ) : null}
            </>
            {/* )} */}

            {/* {isHeadBranch && ( */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAppointmentModelVisible(true);
                      setAppointmentRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* )} */}
            {/* <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAppointmentDetailsModalVisible(true);
                  setAppointmentRecord(dataObj);
                }}
              >
                <EyeOutlined />
              </div>
            </a>
          </Tooltip> */}
            {/* {isHeadBranch && (
              <Tooltip title="Delete Appointment">
                <Popconfirm
                  title="Are you sure to delete this appointment?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_APPOINTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )} */}
            {/* {isHeadBranch && (
            <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setAppointmentRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip>
          )} */}
            {/* <Tooltip title="Change appointment status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  const actionStatus = dataObj.isActive ? "inactive" : "active";
                  dispatch({
                    type: "CHANGE_APPOINTMENT_STATUS_REQUEST",
                    payload: { id: dataObj.id, actionStatus },
                  });
                }}
              >
                <MdOutlineDisabledVisible />
              </div>
            </a>
          </Tooltip> */}
          </Space>
        ),
      };
    });

  const clientDetailsTabList = [
    {
      key: "leadDetails",
      tab: <span style={{ fontSize: "1rem" }}>Dashboard</span>,
    },
    {
      key: "appointmentDetails",
      tab: (
        <Badge count={store.leadState?.appointmentsByLeadId?.totalData}>
          <span style={{ fontSize: "1rem" }}> Appointments</span>
        </Badge>
      ),
    },
    {
      key: "courseWishlist",
      tab: <span style={{ fontSize: "1rem" }}>Course Wishlist</span>,
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const statusUpdateReloadFn = (response) => {
    fetchLeadDetailById();
    dispatch({ type: "FETCH_LEAD_BY_ID_REQUEST", payload: record?.leadId });
    setTimeout(() => {
      form.resetFields();
    }, 1000);
  };
  const navigate = useNavigate();

  return (
    <Drawer
      title="Leads Dashboard"
      open={isLeadsDetailsModelVisible}
      onClose={() => {
        setIsLeadsDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {store.leadState.leadById?.customerResponse?.photo ? (
            <Image
              src={store.leadState.leadById?.customerResponse?.photo}
              className={"clientImage"}
            />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              position: "relative",
              width: "100%",
              gap: "0.5rem",
              minHeight: "10rem",
              height: "fit-content",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${store.leadState.leadById?.firstName ?? ""} ${
                store.leadState.leadById?.lastName ?? ""
              } (Lead ID: ${leadData?.leadPrefix ?? ""}${
                store.leadState.leadById?.id
              })`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {store.leadState.leadById?.mobile && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {store.leadState.leadById?.mobile
                    ? store.leadState.leadById?.mobile
                    : "N/A"}
                </span>
              )}
              {store.leadState.leadById?.customerResponse?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store.leadState.leadById?.customerResponse?.address
                    ? store.leadState.leadById?.customerResponse?.address
                    : "N/A"}
                </span>
              )}
              {store.leadState.leadById?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store.leadState.leadById?.email
                    ? store.leadState.leadById?.email
                    : "N/A"}
                </span>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {store?.leadState?.leadById?.isClientWithEmailExists ===
                  false && (
                  <Button
                    type="danger"
                    onClick={() => setIsAddClientModalVisible(true)}
                  >
                    Add Client
                  </Button>
                )}
                {store?.leadState?.leadById?.isClientWithEmailExists ===
                  true && (
                  <Button
                    type="danger"
                    onClick={() => setIsClientDetailsModelVisible(true)}
                  >
                    View Client
                  </Button>
                )}
              </div>
            </div>
            <div>
              <div>
                <span className="profileTitle">Campaign:</span>
                <span>
                  {store.leadState.leadById?.customerResponse?.campaign?.name ??
                    "N/A"}
                </span>
              </div>
            </div>
            <div>
              <span className="profileTitle">Lead Assign To:</span>
              <span>
                {store?.leadState?.leadById?.leadAssignee
                  ? store?.leadState?.leadById?.leadAssignee
                  : "N/A"}
              </span>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                // justifySelf:""
                position: "absolute",

                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
                "SEND_MESSAGE"
              ) && (
                <button
                  className="emailAndMessage"
                  onClick={() => {
                    // setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  <BsChatLeftText style={{ fontSize: "10px" }} />
                  <span>Message</span>
                </button>
              )}
              {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
                "SEND_EMAIL"
              ) && (
                <button
                  className="emailAndMessage"
                  onClick={() => {
                    setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  <AiOutlineMail />
                  <span>Email</span>
                </button>
              )}
              {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
                "ADD"
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsAddAppointmentModelVisible(true);
                  }}
                >
                  Book an Appointment
                </Button>
              )}
              {store.leadState.leadById?.leadStatus?.statusName !== "WIN" &&
                store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                  "ADD"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    Add Case
                  </Button>
                )}
              {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
                "CHANGE_STATUS"
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                  }}
                >
                  Status Update
                </Button>
              )}
              {store.profileState?.profile?.department?.rolesAndPermissionList?.COLLEGE_FINDER?.includes(
                "VIEW_ONLY"
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsCollegeFinderModalVisible(true);
                    // navigate("/collegeFinder");
                  }}
                >
                  Go To College Finder
                </Button>
              )}
              {/* <Button
                type="primary"
                onClick={() => {
                  setIsCourseWishlistModalVisible(true);
                  // navigate("/collegeFinder");
                }}
              >
                Course Wishlist
              </Button> */}
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <Tag
                color={
                  store.leadState.leadById?.leadStatus?.statusColorCode
                    ? store.leadState.leadById?.leadStatus?.statusColorCode
                    : "white"
                }
              >
                {store.leadState.leadById?.leadStatus?.statusName ===
                "BOOK_AN_APPOINTMENT"
                  ? "BOOK AN APPOINTMENT"
                  : store.leadState.leadById?.leadStatus?.statusName
                  ? store.leadState.leadById?.leadStatus?.statusName
                  : "N/A"}
              </Tag>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "leadDetails" && (
            <>
              <div
                style={{
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Purpose",
                      `${
                        store.leadState.leadById?.purposeName
                          ? store.leadState.leadById?.purposeName
                          : "N/A"
                      }`
                    )}
                    {store.leadState.leadById?.purposeName?.toUpperCase() ===
                      "MIGRATION" &&
                      detailFun(
                        "Country to Migrate",
                        `${
                          store.leadState.leadById?.countryToMigrate
                            ? store.leadState.leadById?.countryToMigrate
                            : "N/A"
                        }`
                      )}
                    {detailFun(
                      "Lead Source",
                      `${
                        store.leadState.leadById?.leadSourceName
                          ? store.leadState.leadById?.leadSourceName
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Country",
                      `${
                        store.leadState.leadById?.countryName
                          ? store.leadState.leadById?.countryName
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Lead By",
                      `${
                        store.leadState.leadById?.leadBy
                          ? store.leadState.leadById?.leadBy
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Sales Rep",
                      `${
                        store.leadState.leadById?.salesRepName
                          ? store.leadState.leadById?.salesRepName
                          : " N/A"
                      }`
                    )}
                    {detailFun(
                      "Sales Rep Email",
                      `${
                        store.leadState.leadById?.salesRepEmail
                          ? store.leadState.leadById?.salesRepEmail
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Country of Residence",
                      `${
                        store.leadState.leadById?.customerResponse
                          ?.countryOfResidence
                          ? store.leadState.leadById?.customerResponse
                              ?.countryOfResidence
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Referred By",
                      `${
                        store.leadState.leadById?.referredByFullName
                          ? store.leadState.leadById?.referredByFullName
                          : "N/A"
                      }`
                    )}

                    {detailFun(
                      "Description",
                      `${
                        store.leadState.leadById?.description
                          ? store.leadState.leadById?.description
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Added Date",
                      `${
                        store.leadState.leadById?.addedDate
                          ? formattedDateTime(
                              store.leadState.leadById?.addedDate
                            )
                          : "N/A"
                      }`
                    )}
                  </Card>
                  <Card
                    className="clientCard statusUpdateTimeline"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "70vh",
                    }}
                  >
                    {store.leadState?.leadActivityLogById?.data?.map(
                      (dataObj, index) => {
                        return (
                          <Timeline.Item key={dataObj.key}>
                            <div className="flexColumnwithoutStyle">
                              <div style={{ fontWeight: "200" }}>
                                {formattedDateTime(dataObj?.activityTime)}
                              </div>
                              <span>
                                <div style={{ fontWeight: "bold" }}>
                                  {dataObj.activity}
                                </div>
                              </span>
                            </div>
                          </Timeline.Item>
                        );
                      }
                    )}
                  </Card>
                </div>
                <Form
                  layout="vertical"
                  onFinish={(value) => {
                    dispatch({
                      type: "STATUS_HISTORY_ONLY_UPDATE_REQUEST",
                      payload: {
                        leadId: store.leadState.leadById?.id,
                        update: value?.todayUpdate,
                        isReloadFn: true,
                        reloadFn: statusUpdateReloadFn,
                      },
                    });
                  }}
                  colon={true}
                  form={form}
                >
                  <Form.Item
                    label="Today's Update"
                    name={"todayUpdate"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please write something!",
                      },
                    ]}
                  >
                    <TextArea placeholder="Write something..." />
                  </Form.Item>

                  <Form.Item>
                    <Button block type="primary" htmlType="submit">
                      Add Update
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </>
          )}
          {current === "appointmentDetails" && (
            <>
              <Table columns={columns} dataSource={appointmentDataSource} />
            </>
          )}
          {current === "courseWishlist" && (
            <>
              <CourseWishlist
                appointmentRecord={{
                  ...store?.leadState?.leadById,

                  id: record?.id,
                  leadId: store?.leadState?.leadById?.id,

                  purpose: {
                    id: store?.leadState?.leadById?.purposeId,
                    purposeName: store?.leadState?.leadById?.purposeName,
                  },
                  country: {
                    id: store?.leadState?.leadById?.countryId,
                    name: store?.leadState?.leadById?.countryName,
                  },
                  leadResponse: {
                    ...store?.leadState?.leadById,
                  },
                }}
              />
            </>
          )}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          composeMailRecord={{
            toUser: [store?.leadState?.leadById?.email],
          }}
          mailTemplateType="LEAD"
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isStatusHistoryModelVisible && (
        <StatusHistory
          type={"LEAD"}
          leadData={leadData}
          record={store?.leadState?.leadById}
          isStatusHistoryModalVisible={isStatusHistoryModelVisible}
          setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isAppointmentStatusHistoryModelVisible && (
        <StatusHistory
          type={"APPOINTMENT"}
          appointmentData={leadData}
          appointmentRecord={appointmentRecord}
          record={appointmentRecord?.leadResponse}
          isStatusHistoryModalVisible={isAppointmentStatusHistoryModelVisible}
          setIsStatusHistoryModalVisible={
            setIsAppointmentStatusHistoryModelVisible
          }
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromLead={true}
          recordData={{ leadResponse: store?.leadState?.leadById }}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}{" "}
      {isAddVisasModalVisible && (
        <AddVisaCases
          navigateCaseList={true}
          fromLead={true}
          recordData={{ leadResponse: store?.leadState?.leadById }}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
      {isAddAppointmentModelVisible && (
        <AddAppointment
          navigateAppointmentList={true}
          leadRecord={store?.leadState?.leadById}
          isAddAppointmentModalVisible={isAddAppointmentModelVisible}
          setIsAddAppointmentModelVisible={setIsAddAppointmentModelVisible}
        />
      )}
      {isStartCounsellingModelVisible && (
        <StartCounselling
          reload={true}
          // fromLeadDetails={true}
          // record={{
          //   ...appointmentRecord,

          //   id: appointmentRecord?.appointmentId,
          //   purpose: {
          //     id: appointmentRecord?.purposeId,
          //     purposeName: appointmentRecord?.purposeName,
          //   },
          //   country: {
          //     id: appointmentRecord?.countryId,
          //     name: appointmentRecord?.countryName,
          //   },
          //   leadResponse: {
          //     ...appointmentRecord,
          //   },
          // }}
          record={appointmentRecord}
          isStartCounsellingModalVisible={isStartCounsellingModelVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModelVisible}
        />
      )}
      {isStatusHistoryCounsellorAddModelVisible && (
        <StatusHistoryConsellorAdd
          type={"APPOINTMENT"}
          appointmentData={leadData}
          appointmentRecord={appointmentRecord}
          record={appointmentRecord?.leadResponse}
          isStatusHistoryModalVisible={isStatusHistoryCounsellorAddModelVisible}
          setIsStatusHistoryModalVisible={
            setIsStatusHistoryCounsellorAddModelVisible
          }
        />
      )}
      {isUpdateAppointmentModelVisible && (
        <UpdateAppointment
          record={appointmentRecord}
          isUpdateAppointmentModelVisible={isUpdateAppointmentModelVisible}
          setIsUpdateAppointmentModelVisible={
            setIsUpdateAppointmentModelVisible
          }
        />
      )}
      {isAppointmentDetailsModalVisible && (
        <AppointmentDetails
          appointmentData={leadData}
          appointmentObj={appointmentRecord}
          isAppointmentDetailsModalVisible={isAppointmentDetailsModalVisible}
          setIsAppointmentDetailsModalVisible={
            setIsAppointmentDetailsModalVisible
          }
        />
      )}
      {isCollegeFinderModalVisible && (
        <CollegeFinderDrawer
          record={{ leadResponse: store?.leadState?.leadById }}
          isCollegeFinderModalVisible={isCollegeFinderModalVisible}
          setIsCollegeFinderModalVisible={setIsCollegeFinderModalVisible}
        />
      )}
      {isAddClientModalVisible && (
        <AddClient
          record={{
            ...record,
            phoneNumber: record.mobile,
            id: record.customerResponse.id,
          }}
          isAddClientModalVisible={isAddClientModalVisible}
          setIsAddClientModalVisible={setIsAddClientModalVisible}
        />
      )}
      {isClientDetailsModelVisible && store.clientState.clientById && (
        <ClientDetails
          Record={store.clientState.clientById}
          isClientDetailsModelVisible={isClientDetailsModelVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {/* {isCourseWishlistModalVisible && (
        <CourseWishlist
          appointmentRecord={{
            ...record,

            id: record?.id,
            leadId: record?.id,

            purpose: {
              id: record?.purposeId,
              purposeName: record?.purposeName,
            },
            country: {
              id: record?.countryId,
              name: record?.countryName,
            },
            leadResponse: {
              ...record,
            },
          }}
          isCourseWishlistModalVisible={isCourseWishlistModalVisible}
          setIsCourseWishlistModalVisible={setIsCourseWishlistModalVisible}
        />
      )} */}
    </Drawer>
  );
};

export default LeadDetails;

{
  /* <Card
                className="clientCard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {record?.appointmentDate
                  ? detailFun(
                      "Appointment Date",
                      `${formattedDate(record?.appointmentDate)}`
                    )
                  : detailFun("Appointment Date", `N/A`)}
                {record?.leadStatus?.statusName !== "PENDING" &&
                record?.assignCounsellor !== false &&
                record?.counsellingBooking !== null ? (
                  <>
                    {detailFun(
                      "Booking Date",
                      `${
                        record?.counsellingBooking?.bookingDate
                          ? formattedDate(
                              record?.counsellingBooking?.bookingDate
                            )
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Booking Day",
                      `${
                        record?.counsellingBooking?.bookingDay
                          ? record?.counsellingBooking?.bookingDay
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Session Time",
                      `${
                        record?.counsellingBooking?.sessionTime
                          ? record?.counsellingBooking?.sessionTime
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "Start Time",
                      `${
                        record?.counsellingBooking?.startTime
                          ? record?.counsellingBooking?.startTime
                          : "N/A"
                      }`
                    )}
                    {detailFun(
                      "End Time",
                      `${
                        record?.counsellingBooking?.endTime
                          ? record?.counsellingBooking?.endTime
                          : "N/A"
                      }`
                    )}
                    {record?.assignedCounsellor?.fullName
                      ? detailFun(
                          "Counsellor",
                          `${record?.assignedCounsellor?.fullName}`
                        )
                      : detailFun("Counsellor", `N/A`)}
                  </>
                ) : null}
                {detailFun(
                  "Appointment Status",
                  record?.appointmentStatus
                    ? fourTagResponses(
                        record?.appointmentStatus,
                        "PENDING",
                        "COMPLETED",
                        "CONFIRMED",
                        "CANCELLED"
                      )
                    : "N/A"
                )}
                {record?.appointmentStatus === "CONFIRMED" && (
                  <div className="flexCenter">
                    <Button
                      type="primary"
                      style={{ borderRadius: "1rem" }}
                      onClick={() => {
                        setIsStartCounsellingModelVisible(true);
                        setAppointmentRecord(record);
                        dispatch({
                          type: "START_COUNSELLING_REQUEST",
                          payload: {
                            id: record?.appointmentId,
                            formData: {
                              confirmationText: "",
                            },
                          },
                        });
                      }}
                    >
                      {record?.isSessionStarted === true
                        ? "Start Counselling"
                        : "View Counselling"}
                    </Button>
                  </div>
                )}
                {record?.appointmentStatus === "COMPLETED" && (
                  <div className="flexCenter">
                    <Button
                      type="primary"
                      style={{ borderRadius: "1rem" }}
                      onClick={() => {
                        setIsStartCounsellingModelVisible(true);
                        setAppointmentRecord(record);
                        dispatch({
                          type: "START_COUNSELLING_REQUEST",
                          payload: {
                            id: record?.appointmentId,
                            formData: {
                              confirmationText: "",
                            },
                          },
                        });
                      }}
                    >
                      {record?.isSessionStarted === true
                        ? "Start Counselling"
                        : "View Counselling"}
                    </Button>
                  </div>
                )}
              </Card> */
}
