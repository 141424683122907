import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Radio, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import AddTimeline from "../../Quotation/Timeline/AddTimeline";
import { useDispatch } from "react-redux";
import AddCasePackage from "../../Visas/AddCasePackage";
import { scrollToTopFormError } from "../../../HelperFunction";

export default function ServicePackageInfo({
  serviceData,
  setServiceData,
  setCurrent,
}) {
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      quoteState: state.QuotationReducer,
    };
  });
  const [transition, setTransition] = useState("");
  const [isAddTimelineModalVisible, setIsAddTimelineModalVisible] =
    useState(false);
  const [isAddPackageModalVisible, setIsAddPackageModalVisible] =
    useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  useEffect(() => {
    handleFetchActiveCasePackage();
    dispatch({ type: "FETCH_TIMELINE_REQUEST" });
  }, []);

  const handleFetchActiveCasePackage = () => {
    dispatch({
      type: "FETCH_ACTIVE_CASE_PACKAGE_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  };

  const handleSumbitPackageInfo = (values) => {
    setServiceData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
    transition === "back" && setCurrent((prev) => 0);
    transition === "next" && setCurrent((prev) => 2);
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSumbitPackageInfo}
        onFinishFailed={scrollToTopFormError}
        initialValues={{
          natureOfProject: serviceData?.natureOfProject,
          servicePackage: serviceData?.servicePackage,
          isDiscountAllowed: serviceData?.isDiscountAllowed,
          discount: serviceData?.discount,
          packageDiscountType: serviceData?.packageDiscountType,
          gstApplicable: serviceData?.gstApplicable,
          gstAmount: serviceData?.gstAmount,
          associatedTimelineId: serviceData?.associatedTimelineId,
          associatedTimelineName: serviceData?.associatedTimelineName,
          specialNote: serviceData?.specialNote,
          description: serviceData?.description,
          paymentTermsInMonths: serviceData?.paymentTermsInMonths,
          paymentTermsInfo: serviceData?.paymentTermsInfo,
          displayPaymentTermsInfoInServiceConfirmation:
            serviceData?.displayPaymentTermsInfoInServiceConfirmation,
          serviceUpdate: serviceData?.serviceUpdate,
          displayServiceUpdateInServiceConfirmation:
            serviceData?.displayServiceUpdateInServiceConfirmation,
        }}
      >
        <Form.Item name="natureOfProject" label="Nature of Service">
          <Input placeholder="Nature of Service" />
        </Form.Item>
        <Form.List name="servicePackage" style={{ marign: "1rem" }}>
          {(fields, { add, remove }) => (
            <div style={{ gridColumn: "span 2", background: "#f9f9f9" }}>
              {fields.map(({ key, name, ...restField }, index) => (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "1rem",
                    position: "relative",
                    padding: "1rem 3rem 0 1rem",
                  }}
                  key={key}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "packageName"]}
                      label="Package"
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Select Package"
                        onChange={(value, option) => {
                          const dataObj = JSON.parse(option?.key);
                          const data = form.getFieldValue("servicePackage");
                          const tempObj = {
                            packageName: dataObj?.packageName,
                            unitPrice: dataObj?.unitPrice,
                            quantity: 1,
                            totalAmount: dataObj?.unitPrice,
                            packageId: dataObj?.id,
                          };
                          data.splice(key, 1, tempObj);
                          form.setFieldValue("servicePackage", data);
                        }}
                      >
                        {store.caseState.activeCasePackage?.data?.map(
                          (dataObj) => {
                            return (
                              <Option
                                key={JSON.stringify(dataObj)}
                                value={dataObj?.packageName}
                              >
                                {dataObj.packageName}
                                {dataObj.packageType === "IN_HOUSE" && (
                                  <Tag
                                    color="#2db7f5"
                                    style={{
                                      borderRadius: "10px",
                                      margin: "3px",
                                    }}
                                  >
                                    IH
                                  </Tag>
                                )}
                                {dataObj.packageType === "INSTITUTE" && (
                                  <Tag
                                    color="#2db7f5"
                                    style={{
                                      borderRadius: "10px",
                                      margin: "3px",
                                    }}
                                  >
                                    IN
                                  </Tag>
                                )}
                                {dataObj.packageType === "COMPANY" && (
                                  <Tag
                                    color="#2db7f5"
                                    style={{
                                      borderRadius: "10px",
                                      margin: "3px",
                                    }}
                                  >
                                    CO
                                  </Tag>
                                )}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddPackageModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <Form.Item
                    {...restField}
                    name={[name, "pacakgeId"]}
                    label="pacakgeId"
                    style={{ display: "none" }}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "quantity"]}
                    label="Quantity"
                    onChange={(e) => {
                      const data = form.getFieldValue("servicePackage");
                      const tempObj = data[key];
                      tempObj.totalAmount = e.target.value * tempObj.unitPrice;
                      data.splice(key, 1, tempObj);
                      form.setFieldValue("servicePackage", data);
                    }}
                  >
                    <Input type="number" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "unitPrice"]}
                    label="Unit Price"
                    onChange={(e) => {
                      const data = form.getFieldValue("servicePackage");
                      const tempObj = data[key];
                      tempObj.totalAmount = e.target.value * tempObj.quantity;
                      data.splice(key, 1, tempObj);
                      form.setServiceData("servicePackage", data);
                    }}
                  >
                    <Input readOnly type="number" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "totalAmount"]}
                    label="Price"
                  >
                    <Input readOnly type="number" />
                  </Form.Item>
                  <Button
                    type="danger"
                    onClick={() => remove(name)}
                    style={{
                      padding: "0",
                      borderRadius: "50%",
                      height: "1.5rem",
                      width: "1.5rem",
                      position: "absolute",
                      top: "0.5rem",
                      right: "0.5rem",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              ))}
              <Button block type="dashed" onClick={() => add()}>
                <PlusOutlined />
                Add Package
              </Button>
            </div>
          )}
        </Form.List>

        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Form.Item
            name={"isDiscountAllowed"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setServiceData((previousData) => {
                  return {
                    ...previousData,
                    isDiscountAllowed: e.target.checked,
                  };
                });
                form.setFieldValue("isDiscountAllowed", e.target.checked);
              }}
            >
              is Discount Allowed?
            </Checkbox>
          </Form.Item>
          {serviceData?.isDiscountAllowed && (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "0.5rem",
                }}
              >
                <Form.Item
                  label="Discount amount"
                  name={"discount"}
                  style={{ width: "100%" }}
                >
                  <Input type="number" placeholder="Discount" />
                </Form.Item>
                <Form.Item
                  label={"Type"}
                  name={"packageDiscountType"}
                  style={{ width: "100%" }}
                >
                  <Radio.Group>
                    <Radio value={"FLAT"}>is Flat</Radio>
                    <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "0.5rem",
          }}
        >
          <Form.Item
            name={"gstApplicable"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setServiceData((previousData) => {
                  return {
                    ...previousData,
                    gstApplicable: e.target.checked,
                  };
                });
                form.setFieldValue("gstApplicable", e.target.checked);
              }}
            >
              Is GST/VAT applicable?
            </Checkbox>
          </Form.Item>
          {serviceData?.gstApplicable && (
            <Form.Item
              label="GST/TAX"
              name={"gstAmount"}
              style={{ width: "100%" }}
            >
              <Input
                type="number"
                min={0}
                max={100}
                placeholder="GST/TAX (%)"
              />
            </Form.Item>
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Timeline"
            name={"associatedTimelineName"}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Select Timeline"
              onChange={(value, option) => {
                setServiceData((prev) => {
                  return {
                    ...prev,
                    associatedTimelineId: option?.key,
                  };
                });
              }}
            >
              {store.quoteState?.timelines?.data?.map((dataObj) => {
                return (
                  <Option key={dataObj.id} value={dataObj?.timelineName}>
                    {dataObj.timelineName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddTimelineModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>

        <Form.Item
          label="Special Note"
          name={"specialNote"}
          style={{ width: "100%" }}
        >
          <TextArea rows={4} placeholder="Write something..." />
        </Form.Item>

        <Form.Item
          label="Description"
          name={"description"}
          style={{ width: "100%" }}
        >
          <TextArea rows={4} placeholder="Write something..." />
        </Form.Item>

        <Form.Item
          label="Payment terms in months"
          name={"paymentTermsInMonths"}
          style={{ width: "100%" }}
        >
          <Input type="number" placeholder="Payment terms in months" />
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "flex-start",
          }}
        >
          <Form.Item
            label="Payment Terms"
            name={"paymentTermsInfo"}
            style={{ width: "60%" }}
          >
            <TextArea rows={4} placeholder="Write something..." />
          </Form.Item>
          <Form.Item
            name={"displayPaymentTermsInfoInServiceConfirmation"}
            valuePropName="checked"
            style={{ width: "40%", paddingTop: "1.5rem" }}
          >
            <Checkbox
              onChange={(e) => {
                setServiceData((previousData) => {
                  return {
                    ...previousData,
                    displayPaymentTermsInfoInServiceConfirmation:
                      e.target.checked,
                  };
                });
                form.setFieldValue(
                  "displayPaymentTermsInfoInServiceConfirmation",
                  e.target.checked
                );
              }}
            >
              Display in service confirmention?
            </Checkbox>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "flex-start",
          }}
        >
          <Form.Item
            label="Service Update"
            name={"serviceUpdate"}
            style={{ width: "60%" }}
          >
            <TextArea placeholder="Write something..." rows={4} />
          </Form.Item>
          <Form.Item
            name={"displayServiceUpdateInServiceConfirmation"}
            valuePropName="checked"
            style={{ width: "40%", paddingTop: "1.5rem" }}
          >
            <Checkbox
              onChange={(e) => {
                setServiceData((previousData) => {
                  return {
                    ...previousData,
                    displayServiceUpdateInServiceConfirmation: e.target.checked,
                  };
                });
              }}
            >
              Display in service confirmention?
            </Checkbox>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="outline"
            onClick={() => {
              setTransition("back");
            }}
            htmlType="submit"
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setTransition("next");
            }}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
      {isAddTimelineModalVisible && (
        <AddTimeline
          isAddTimelineModalVisible={isAddTimelineModalVisible}
          setIsAddTimelineModalVisible={setIsAddTimelineModalVisible}
        />
      )}

      {isAddPackageModalVisible && (
        <AddCasePackage
          from={"CASE"}
          isAddPackageModalVisible={isAddPackageModalVisible}
          setIsAddPackageModalVisible={setIsAddPackageModalVisible}
        />
      )}
    </div>
  );
}
