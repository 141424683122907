import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  date,
  date00Time,
  dateAndTime,
  scrollToTopFormError,
} from "../../HelperFunction";
import { assignIn } from "lodash";
const { Option } = Select;
const { TextArea } = Input;

const AddTask = ({ isAddTaskModalVisible, setIsAddTaskModalVisible }) => {
  const AddTaskState = useSelector((state) => state.VisasReducer);
  const ProfileState = useSelector((store) => store?.ProfileReducer?.profile);
  const [assignedTo, setAssignedTo] = useState(null);
  const [emailId, setEmailId] = useState(null);

  console.log("record", assignedTo, emailId, assignedTo?.includes(emailId));

  const dispatch = useDispatch();
  useEffect(() => {
    if (isAddTaskModalVisible === true) {
      dispatch({
        type: "FETCH_CASES_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
        },
      });
    }
  }, [isAddTaskModalVisible]);
  const [form] = Form.useForm();
  const [taskData, setTaskData] = useState({
    forCase: false,
    caseId: null,
  });
  const addTaskSubmitHandler = (value) => {
    const startDate = date00Time(value.startDate);
    const dueDate = date00Time(value.dueDate);
    const formData = {
      startDate,
      dueDate,
      ...value,
      assignedTo: assignedTo,
    };
    dispatch({
      type: "ADD_TASK_REQUEST",
      payload: { branchId: localStorage.getItem("branchId"), formData },
    });
    form.resetFields();
    setIsAddTaskModalVisible(false);
  };

  useEffect(() => {
    setEmailId(
      AddTaskState.caseDropdown?.consultancyEmployee?.find(
        (data) => data?.emailId === ProfileState?.emailId
      )?.id
    );
  }, []);

  return (
    <Drawer
      title="Add Task"
      open={isAddTaskModalVisible}
      onClose={() => {
        setIsAddTaskModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={addTaskSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        initialValues={{ startDate: moment() }}
      >
        <Form.Item
          label="Task Title"
          name={"taskTitle"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter task!",
            },
          ]}
        >
          <Input placeholder="Task Title" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Start Date"
            name={"startDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Form.Item
            label="Assign Task To"
            name={"assignedToEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter assignedTo!",
              },
            ]}
          >
            <Select
              mode="multiple"
              onChange={(value, option) => {
                setAssignedTo(option?.map((keyobj) => parseInt(keyobj?.key)));

                form.setFieldsValue({
                  assignToMyself: value?.includes(ProfileState?.emailId)
                    ? true
                    : false,
                });
              }}
            >
              {AddTaskState.caseDropdown?.consultancyEmployee?.map(
                (dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj?.emailId}>
                      {dataObj.emailId}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name={"assignToMyself"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                console.log(
                  "testing",
                  ProfileState?.emailId,
                  ProfileState,
                  assignedTo?.includes(emailId),
                  assignedTo
                );
                if (assignedTo?.includes(emailId)) {
                  setAssignedTo(assignedTo?.filter((id) => id !== emailId));

                  form.setFieldsValue({
                    assignedToEmail: form
                      .getFieldValue("assignedToEmail")
                      ?.filter((email) => email !== ProfileState?.emailId),
                  });
                } else {
                  assignedTo?.length > 0
                    ? setAssignedTo((prev) => [...prev, emailId])
                    : setAssignedTo([emailId]);
                  assignedTo?.length > 0
                    ? form.setFieldsValue({
                        assignedToEmail: [
                          ...form?.getFieldValue("assignedToEmail"),
                          ProfileState?.emailId,
                        ],
                      })
                    : form.setFieldsValue({
                        assignedToEmail: [ProfileState?.emailId],
                      });
                }

                // if (assignedTo?.includes(emailId)) {
                //   setAssignedTo(assignedTo?.filter((id) => id != emailId));

                //   form.setFieldsValue({
                //     assignedToEmail: form
                //       .getFieldValue("assignedToEmail")
                //       ?.filter((email) => email != ProfileState?.emailId),
                //   });
                // } else {
                //   assignedTo?.length > 0 &&
                //     setAssignedTo((prev) => [...prev, emailId]);
                //   assignedTo?.length > 0 &&
                //     form.setFieldsValue({
                //       assignedToEmail: [
                //         ...form?.getFieldValue("assignedToEmail"),
                //         ProfileState?.emailId,
                //       ],
                //     });
                // }
              }}
            >
              Assign to Myself
            </Checkbox>
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Task Status"
            name={"taskStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select a taskStatus!",
              },
            ]}
          >
            <Select>
              <Option value={"PENDING"}>Pending</Option>
              <Option value={"ASSIGNED"}>Assigned</Option>
              <Option value={"COMPLETED"}>Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Priority"
            name={"priority"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter priority!",
              },
            ]}
          >
            <Select>
              <Option value={"NORMAL"}>Normal</Option>
              <Option value={"HIGH"}>High</Option>
              <Option value={"URGENT"}>Urgent</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label="Task Description"
          name={"taskDescription"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <TextArea placeholder="Write about task" />
        </Form.Item>
        <Form.Item
          label="Update Note"
          name={"updateNote"}
          style={{ width: "100%" }}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter updateNote!",
          //   },
          // ]}
        >
          <TextArea placeholder="Write recent update" />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Task
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddTask;
