import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
const { Option } = Select;

const AsignEmailToCase = ({
  isAsignModelVisible,
  setIsAsignModelVisible,
  caseId,
  successFn,
  isUpdate,
  assigneEmail,
}) => {
  const dispatch = useDispatch();
  const [activeEmployees, setActiceEmployees] = useState([]);

  useEffect(() => {
    fetchActiveEmployeList();
  }, []);

  const fetchActiveEmployeList = async () => {
    const tempToken = Cookies.get("accessToken");
    await axios
      .get(`v1/api/client/get-active-user-list`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setActiceEmployees(res.data);
      })
      .catch((err) => {});
  };

  const handleSubmitCaseAsignTo = (values) => {
    dispatch({
      type: "ADD_ASIGNTO_EMAIL_REQUEST",
      payload: { caseId: caseId, email: values?.asignEmailTo },
      isSuccessFn: true,
      successFunction: () => {
        successFn();
        setIsAsignModelVisible(false);
      },
    });
  };

  return (
    <Modal
      title={`${isUpdate ? "Update" : "Add"} Case Assign Email`}
      open={isAsignModelVisible}
      onCancel={() => {
        setIsAsignModelVisible(false);
      }}
      footer={null}
    >
      <Form
        initialValues={{ asignEmailTo: assigneEmail }}
        layout="vertical"
        onFinish={handleSubmitCaseAsignTo}
      >
        <Form.Item
          label="Case Assign To"
          name={"asignEmailTo"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select Assignee Email!",
            },
          ]}
        >
          <Select placeholder="Select Assignee">
            {activeEmployees?.map((emp) => (
              <Select.Option value={emp.emailId}>
                {emp.fullName}({emp.emailId})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button block type="primary" htmlType="submit">
          {isUpdate ? "Update" : "Add"} Add Asign Email
        </Button>
      </Form>
    </Modal>
  );
};

export default AsignEmailToCase;
