import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

export default function AddDocumentChecklist({
  handleGetDocumentChecklist,
  isAddModalVisible,
  setIsAddModalVisible,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setIsAddModalVisible(false);
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleAddDocumentChecklist = (values) => {
    dispatch({
      type: "ADD_DOCUMENT_CHECKLIST_REQUEST",
      payload: { name: values?.documentName },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  const successFunction = () => {
    setIsAddModalVisible(false);
    handleGetDocumentChecklist();
  };

  return (
    <Modal
      title="Add Document Checklist"
      open={isAddModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form layout="vertical" onFinish={handleAddDocumentChecklist}>
        <Form.Item
          name="documentName"
          label="Document Name"
          rules={[
            {
              required: true,
              message: "Document name is required",
            },
          ]}
        >
          <Input placeholder="Document name" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Add Document Checklist
        </Button>
      </Form>
    </Modal>
  );
}
