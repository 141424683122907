import { message } from "antd";
import {
  removeCookie,
  removeLocalStorage,
} from "../../../Helpers/FrontendHelper";

const INIT_STATE = {
  isLoading: false,
  profile: "",
  packages: [],
  subscriptionResponse: {},
  todayReminders: {},
  notifications: {},
  globalSearchResult: {
    customer: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    contact: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    leads: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    appointments: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    cases: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    institute: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    course: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    company: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    headAgent: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    subAgent: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
    consultancyEmployees: {
      data: [],
      totalPage: 0,
      totalData: 0,
      pageNumber: 0,
      hasNext: false,
    },
  },
};
const ProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_PROFILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_PROFILE_REQUEST_SUCCESS": {
      return {
        ...state,
        profile: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PROFILE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_PACKAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_PACKAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        packages: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_PACKAGE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_PROFILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_PROFILE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Contact updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_PROFILE_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update contact.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "PURCHASE_SUBSCRIPTION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS": {
      return {
        ...state,
        subscriptionResponse: action.payload,
        isLoading: false,
      };
    }
    case "PURCHASE_SUBSCRIPTION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to proceed to checkout.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_PROFILE_IMAGE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_PROFILE_IMAGE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Profile image uploades.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_PROFILE_IMAGE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to upload profile image.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_COMPANY_LOGO_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CHANGE_COMPANY_LOGO_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Profile image uploades.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_COMPANY_LOGO_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to upload profile image.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOGOUT_CONSULTANCY_REQUEST": {
      removeCookie("accessToken");
      removeLocalStorage("menuList");
      removeLocalStorage("permissionList");
      removeLocalStorage("branchName");
      removeLocalStorage("branchId");

      window.location.href = "/";
      return {
        ...state,
        loading: false,
      };
    }
    case "FETCH_TODAY_REMINDER_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_TODAY_REMINDER_REQUEST_SUCCESS": {
      return {
        ...state,
        todayReminders: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_TODAY_REMINDER_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load reminders.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_NOTIFICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_NOTIFICATION_REQUEST_SUCCESS": {
      return {
        ...state,
        notifications: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_NOTIFICATION_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load notification.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST_SUCCESS": {
      console.warn(action.payload, "eta pani milna ta parne ho ni ");
      return {
        ...state,
        globalSearchResult: action.payload,
        isLoading: false,
      };
    }
    case "GLOBAL_SEARCH_FROM_DASHBOARD_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to search.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "MAILBOX_ONCLICK_ZERO_REQUSEST": {
      return {
        ...state,
        profile: {
          ...state.profile,
          unseenMailInboxCount: 0,
        },
        isLoading: true,
      };
    }

    default:
      return state;
  }
};

export default ProfileReducer;
