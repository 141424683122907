import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { dateAndTime, scrollToTopFormError } from "../../HelperFunction";
const { Option } = Select;
const { TextArea } = Input;

const UpdateTask = ({
  record,
  isUpdateTaskModalVisible,
  setIsUpdateTaskModalVisible,
}) => {
  const UpdateTaskState = useSelector((state) => state.VisasReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isUpdateTaskModalVisible === true) {
      dispatch({
        type: "FETCH_CASES_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
        },
      });
    }
  }, [isUpdateTaskModalVisible]);
  const [form] = Form.useForm();
  const updateTaskSubmitHandler = (value) => {
    const startDate = dateAndTime(value.startDate);
    const dueDate = dateAndTime(value.dueDate);
    const formData = {
      startDate,
      dueDate,
      ...value,
    };
    dispatch({
      type: "UPDATE_TASK_REQUEST",
      payload: { id: record.id, values: formData },
    });
    // form.resetFields();
    setIsUpdateTaskModalVisible(false);
  };

  return (
    <Drawer
      title="Update Task"
      open={isUpdateTaskModalVisible}
      onClose={() => {
        setIsUpdateTaskModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateTaskSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        fields={[
          {
            name: ["taskTitle"],
            value: record.taskTitle,
          },
          {
            name: ["taskStatus"],
            value: record.taskStatus,
          },
          {
            name: ["assignedTo"],
            value: record?.assignedTo?.id,
          },
          {
            name: ["priority"],
            value: record.priority,
          },
          {
            name: ["startDate"],
            value: record.startDate
              ? moment.utc(record.startDate).local()
              : null,
          },
          {
            name: ["dueDate"],
            value: record.dueDate ? moment.utc(record.dueDate).local() : null,
          },
          {
            name: ["updateNote"],
            value: record.updateNote,
          },
          {
            name: ["taskDescription"],
            value: record.taskDescription,
          },
        ]}
        // initialValues={{ startDate: moment() }}
      >
        <Form.Item
          label="Task Title"
          name={"taskTitle"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter task!",
            },
          ]}
        >
          <Input placeholder="Task Title" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Start Date"
            name={"startDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {/* <Form.Item
            label="assignedBy"
            name={"assignedBy"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Select>
              {UpdateTaskState.leadsDropdown?.consultantList?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj.emailId}</Option>;
              })}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Assigned To"
            name={"assignedTo"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter assignedTo!",
              },
            ]}
          >
            <Select mode="multiple">
              {UpdateTaskState.caseDropdown?.consultancyEmployee?.map(
                (dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.id}>
                      {dataObj.emailId}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name={"assignToMyself"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox>Assign to Myself</Checkbox>
          </Form.Item> */}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Task Status"
            name={"taskStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select taskStatus!",
              },
            ]}
          >
            <Select>
              <Option value={"PENDING"}>Pending</Option>
              <Option value={"ASSIGNED"}>Assigned</Option>
              <Option value={"COMPLETED"}>Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Priority"
            name={"priority"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter priority!",
              },
            ]}
          >
            <Select>
              <Option value={"NORMAL"}>Normal</Option>
              <Option value={"HIGH"}>High</Option>
              <Option value={"URGENT"}>Urgent</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label="Task Description"
          name={"taskDescription"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <TextArea placeholder="Write about task" />
        </Form.Item>
        <Form.Item
          label="Update Note"
          name={"updateNote"}
          style={{ width: "100%" }}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter updateNote!",
          //   },
          // ]}
        >
          <TextArea placeholder="Write recent update" />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Task
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateTask;
