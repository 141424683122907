import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Upload,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getCookie } from "../../Helpers/FrontendHelper";
import axios from "axios";
import Cookies from "js-cookie";
import { scrollToTopFormError } from "../../HelperFunction";
const { Option } = Select;

const UpdateCompanyProfile = ({ profileRecord }) => {
  const store = useSelector((state) => {
    return {
      loginState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [record, setRecord] = useState("");
  const [countryList, setCountryList] = useState([]);

  const updateCompanyProfileSubmitHandler = (value) => {
    const formData = {
      ...value,
    };
    dispatch({ type: "UPDATE_PROFILE_REQUEST", payload: formData });
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <Card bordered={false} loading={store.loginState.isLoading}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "35%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "0.4rem",
          }}
        >
          <h3>Company Profile</h3>
          <h4>Current Logo:</h4>

          {profileRecord?.companyLogo ? (
            <Image src={profileRecord?.companyLogo} className={"clientImage"} />
          ) : (
            <Image className={"clientImage"} />
          )}
          <div style={{ width: "90%" }}>
            <input
              type={"file"}
              name="file"
              style={{ display: "none" }}
              id="file"
              onChange={(e) => {
                const profileFormData = new FormData();
                // profileFormData.append("description", "hello world");
                profileFormData.append("image", e.target.files[0]);
                // axios.post(
                //   "http://192.168.10.111:8090/khrouch/v1/api/users/checkIn",
                //   profileFormData,
                //   {
                //     headers: {
                //       Authorization: `Bearer ${getCookie("accessToken")}`,
                //     },
                //   }
                // );
                // return;
                dispatch({
                  type: "CHANGE_COMPANY_LOGO_REQUEST",
                  payload: profileFormData,
                });
              }}
            />
            <label for="file">
              <div
                className="companyLogoBtn"
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: "white",
                  backgroundColor: "#1890ff",
                  padding: "4px 0",
                }}
              >
                Upload Logo
              </div>
            </label>
          </div>
        </div>
        <Form
          layout="vertical"
          onFinish={updateCompanyProfileSubmitHandler}
          onFinishFailed={scrollToTopFormError}
          colon={true}
          form={form}
          style={{ width: "100%" }}
          fields={[
            {
              name: ["firstName"],
              value: profileRecord?.firstName,
            },
            {
              name: ["lastName"],
              value: profileRecord?.lastName,
            },
            {
              name: ["country"],
              value: profileRecord?.country,
            },
            {
              name: ["callingCode"],
              value: profileRecord?.callingCode,
            },
            {
              name: ["phoneNumber"],
              value: profileRecord?.phoneNumber,
            },
            {
              name: ["emailId"],
              value: profileRecord?.emailId,
            },
            {
              name: ["companyName"],
              value: profileRecord?.companyName,
            },
            {
              name: ["companyRegistrationNumber"],
              value: profileRecord?.companyRegistrationNumber,
            },
            {
              name: ["companyAddress"],
              value: profileRecord?.companyAddress,
            },
          ]}
        >
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="First Name"
              name={"firstName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name={"lastName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your last name!",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Country"
              name={"country"}
              style={{ width: "100%" }}
            >
              <Select
                onChange={(value, option) => {
                  form.setFieldsValue({ callingCode: option?.key });
                }}
                placeholder="Select Country"
              >
                {countryList?.map((country) => {
                  return (
                    <Option
                      key={country.callingCode}
                      value={country.countryName}
                    >
                      {country.countryName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Country Code"
              name={"callingCode"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter country code!",
                },
              ]}
            >
              <Input readOnly placeholder="E.g +61" />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Phone Number"
              name={"phoneNumber"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number!",
                },
                {
                  pattern: /^[\d]{9,11}$/,
                  message: "Phone number should contain 9 to 11 numbers",
                },
              ]}
            >
              <Input type="number" placeholder="Phone Number" />
            </Form.Item>
            {/* <Form.Item
              label="Email"
              name={"emailId"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter valid email!",
                },
              ]}
            >
              <Input type="email" placeholder="e.g. example@domainname.com" />
            </Form.Item> */}
          </div>

          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Company Name"
              name={"companyName"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your company name!",
                },
              ]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
            <Form.Item
              label="Company Registration Number"
              name={"companyRegistrationNumber"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your company registration number!",
                },
              ]}
            >
              <Input placeholder="ABN/ACN" />
            </Form.Item>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Form.Item
              label="Company Address"
              name={"companyAddress"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your company address!",
                },
              ]}
            >
              <Input placeholder="company addressr" />
            </Form.Item>
          </div>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default UpdateCompanyProfile;
