import {
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Input,
  Tooltip,
  Checkbox,
  Button,
  Tag,
  Form,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { FcDocument } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import visa from "../../../Assets/visa.jpg";

const EducationVisaDetails = ({
  record,
  isVisaDetailModelVisible,
  setIsVisaDetailModelVisible,
}) => {
  const dispatch = useDispatch();
  const educationVisasById = useSelector(
    (store) => store?.VisaClassReducer?.educationVisasById
  );

  console.log("record record", educationVisasById);

  const [current, setCurrent] = useState("visaDetails");

  useEffect(() => {
    fetchEducationSubClassById();
  }, []);

  const fetchEducationSubClassById = () => {
    dispatch({
      type: "FETCH_EDUCATION_VISAS_BYID_REQUEST",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const visaDetials = [
    {
      key: "visaDetails",
      tab: "Visa Details",
    },
  ];

  return (
    <Drawer
      title="Visa Details"
      open={isVisaDetailModelVisible}
      onClose={() => {
        setIsVisaDetailModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Image
            src={visa}
            alt=""
            className={"clientImage"}
            style={{ background: "#e4edfb" }}
          />

          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              gap: "1rem",
            }}
          >
            {educationVisasById?.country}
          </div>
        </div>

        <Card
          bordered={false}
          tabList={visaDetials}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "visaDetails" && (
            <div>
              <Card className="clientCard" style={{ width: "100%" }}>
                <div
                  className="flexColumnWithoutStyle"
                  style={{ width: "100%" }}
                >
                  <div className="flexRowSpaceBetween">
                    Country:
                    <span>
                      {educationVisasById?.country
                        ? educationVisasById?.country
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Visa Subclass:
                    <span>
                      {educationVisasById?.subClass
                        ? educationVisasById?.subClass
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "3fr 2fr",
                      gap: "1rem",
                    }}
                  >
                    Descriptioin:
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: educationVisasById?.description,
                        }}
                      ></span>
                    </div>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <h2 className="cardHeader">Documents </h2>

                  <div className="flexRowSpaceBetween">
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FcDocument style={{ fontSize: "30px" }} />
                      {educationVisasById?.documentName}
                    </span>
                    <Tooltip>
                      <a href={educationVisasById?.documentUrl}>
                        <div
                          className="bordered"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Button type="primary">Download</Button>
                        </div>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default EducationVisaDetails;
