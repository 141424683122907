import { Button, Card, Tag } from "antd";
import React from "react";
import { Divider } from "antd";
import moment from "moment";

const VisaPreviewAndSubmit = ({
  addCaseSubmitHandler,
  setCurrent,
  current,
  setPackages,
  packages,
  setVisaCaseData,
  visaCaseData,
  setIsAddVisaCasesModalVisible,
  setIsStartCounsellingModalVisible,
}) => {
  const caseDetails = (title, value) => {
    return (
      <div>
        <div className="profileTitle">{title}:</div>
        <div>{value}</div>
      </div>
    );
  };
  return (
    <>
      <Card bordered={true}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: "1rem" }}>
            <div>
              <h4>CLIENT INFORMATION:</h4>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0" }} />
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {caseDetails(
                  "Start Date",
                  visaCaseData.startDate
                    ? moment(visaCaseData.startDate)
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    : moment().utc().local().format("DD-MM-YYYY")
                )}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.leadType !== "" &&
                visaCaseData.leadType !== null &&
                visaCaseData.leadType !== undefined
                  ? caseDetails("Lead Type", visaCaseData.leadType)
                  : caseDetails("Lead Type", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.salesRepresentativeName !== "" &&
                visaCaseData.salesRepresentativeName !== null &&
                visaCaseData.salesRepresentativeName !== undefined
                  ? caseDetails(
                      "Sales Representative",
                      visaCaseData.salesRepresentativeName
                    )
                  : caseDetails("Sales Representative", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.commenceDate !== "" &&
                visaCaseData.commenceDate !== null &&
                visaCaseData.commenceDate !== undefined
                  ? caseDetails(
                      "Commence Date",
                      moment(visaCaseData.commenceDate)
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    )
                  : caseDetails("Commence Date", "N/A")}
              </div>
            </div>
            {/* <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
            
            
          </div> */}
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.firstName !== "" &&
                visaCaseData.firstName !== null &&
                visaCaseData.firstName !== undefined &&
                visaCaseData.lastName !== "" &&
                visaCaseData.lastName !== null &&
                visaCaseData.lastName !== undefined
                  ? caseDetails(
                      "Name",
                      `${visaCaseData.firstName} ${visaCaseData.lastName} ${
                        visaCaseData.clientType === "EXISTING"
                          ? "(Existing)"
                          : visaCaseData.clientType === "NEW"
                          ? "(New)"
                          : null
                      } `
                    )
                  : caseDetails("Name", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.mobile !== "" &&
                visaCaseData.mobile !== null &&
                visaCaseData.mobile !== undefined
                  ? caseDetails("Mobile Number", visaCaseData.mobile)
                  : caseDetails("Mobile Number", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.email !== "" &&
                visaCaseData.email !== null &&
                visaCaseData.email !== undefined
                  ? caseDetails("Email", visaCaseData.email)
                  : caseDetails("Email", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.dob !== "" &&
                visaCaseData.dob !== null &&
                visaCaseData.dob !== undefined
                  ? caseDetails(
                      "Date of Birth",
                      moment
                        .utc(moment.utc(visaCaseData.dob).local())
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    )
                  : caseDetails("Date of Birth", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.phone !== "" &&
                visaCaseData.phone !== null &&
                visaCaseData.phone !== undefined
                  ? caseDetails("Phone Number", visaCaseData.phone)
                  : caseDetails("Phone Number", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.address !== "" &&
                visaCaseData.address !== null &&
                visaCaseData.address !== undefined
                  ? caseDetails("Address", visaCaseData.address)
                  : caseDetails("Address", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.passportNumber !== "" &&
                visaCaseData.passportNumber !== null &&
                visaCaseData.passportNumber !== undefined
                  ? caseDetails("Passport Number", visaCaseData.passportNumber)
                  : caseDetails("Passport Number", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.maritalStatus !== "" &&
                visaCaseData.maritalStatus !== null &&
                visaCaseData.maritalStatus !== undefined
                  ? caseDetails("Marital Status", visaCaseData.maritalStatus)
                  : caseDetails("Marital Status", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.sexStatus !== "" &&
                visaCaseData.sexStatus !== null &&
                visaCaseData.sexStatus !== undefined
                  ? caseDetails("Sex Status", visaCaseData.sexStatus)
                  : caseDetails("Sex Status", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.countryOfResidence !== "" &&
                visaCaseData.countryOfResidence !== null &&
                visaCaseData.countryOfResidence !== undefined
                  ? caseDetails(
                      "Country of Residence",
                      visaCaseData.countryOfResidence
                    )
                  : caseDetails("Country of Residence", "N/A")}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <h4>VISA INFORMATION:</h4>
            <Divider orientationMargin="0" style={{ margin: "0" }} />
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.visaType !== "" &&
                visaCaseData.visaType !== null &&
                visaCaseData.visaType !== undefined
                  ? caseDetails("Visa Type", visaCaseData.visaType)
                  : caseDetails("Visa Type", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.visaSubClass !== "" &&
                visaCaseData.visaSubClass !== null &&
                visaCaseData.visaSubClass !== undefined
                  ? caseDetails("Visa Subclass", visaCaseData.visaSubClass)
                  : caseDetails("Visa Subclass", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.currentStatus !== "" &&
                visaCaseData.currentStatus !== null &&
                visaCaseData.currentStatus !== undefined
                  ? caseDetails("Current Status", visaCaseData.currentStatus)
                  : caseDetails("Current Status", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.country !== "" &&
                visaCaseData.country !== null &&
                visaCaseData.country !== undefined
                  ? caseDetails("Country", visaCaseData.country)
                  : caseDetails("Country", "N/A")}
              </div>
            </div>
            {/* <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
             
            </div> */}
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.workFlow !== "" &&
                visaCaseData.workFlow !== null &&
                visaCaseData.workFlow !== undefined
                  ? caseDetails("Workflow", visaCaseData.workFlow)
                  : caseDetails("Workflow", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.workFlowStatus !== "" &&
                visaCaseData.workFlowStatus !== null &&
                visaCaseData.workFlowStatus !== undefined
                  ? caseDetails("Workflow Status", visaCaseData.workFlowStatus)
                  : caseDetails("Workflow Status", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.instituteName !== "" &&
                visaCaseData.instituteName !== null &&
                visaCaseData.instituteName !== undefined
                  ? caseDetails("Institute", visaCaseData.instituteName)
                  : caseDetails("Institute", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.degreeName !== "" &&
                visaCaseData.degreeName !== null &&
                visaCaseData.degreeName !== undefined
                  ? caseDetails("Degree", visaCaseData.degreeName)
                  : caseDetails("Degree", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.courseName !== "" &&
                visaCaseData.courseName !== null &&
                visaCaseData.courseName !== undefined
                  ? caseDetails("Course", visaCaseData.courseName)
                  : caseDetails("Course", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.Intake !== "" &&
                visaCaseData.Intake !== null &&
                visaCaseData.Intake !== undefined
                  ? caseDetails("Intake", visaCaseData.Intake)
                  : caseDetails("Intake", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.wantToPayFor !== "" &&
                visaCaseData.wantToPayFor !== null &&
                visaCaseData.wantToPayFor !== undefined
                  ? caseDetails("Want to pay for", visaCaseData.wantToPayFor)
                  : caseDetails("Want to pay for", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.wantToPayFor !== "" &&
                visaCaseData.wantToPayFor !== null &&
                visaCaseData.wantToPayFor !== undefined
                  ? caseDetails("Paying Fee", visaCaseData.wantToPayFee)
                  : caseDetails("Paying Fee", "N/A")}
              </div>
            </div>

            {visaCaseData.currentStatus === "ONSHORE" && (
              <>
                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.onShoreFee !== "" &&
                    visaCaseData.course?.onShoreFee !== null &&
                    visaCaseData.course?.onShoreFee !== undefined
                      ? caseDetails(
                          "Onshore Fee",
                          visaCaseData.course?.onShoreFee
                        )
                      : caseDetails("Onshore Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.onshoreApplicationFee !== "" &&
                    visaCaseData.course?.onshoreApplicationFee !== null &&
                    visaCaseData.course?.onshoreApplicationFee !== undefined
                      ? caseDetails(
                          "Onshore Application Fee",
                          visaCaseData.course?.onshoreApplicationFee
                        )
                      : caseDetails("Onshore Application Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.onshoreMaterialFee !== "" &&
                    visaCaseData.course?.onshoreMaterialFee !== null &&
                    visaCaseData.course?.onshoreMaterialFee !== undefined
                      ? caseDetails(
                          "Onshore Material Fee",
                          visaCaseData.course?.onshoreMaterialFee
                        )
                      : caseDetails("Onshore Material Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.onshoreAdditionalFee !== "" &&
                    visaCaseData.course?.onshoreAdditionalFee !== null &&
                    visaCaseData.course?.onshoreAdditionalFee !== undefined
                      ? caseDetails(
                          "Onshore Additional Fee",
                          visaCaseData.course?.onshoreAdditionalFee
                        )
                      : caseDetails("Onshore Additional Fee", "N/A")}
                  </div>
                </div>

                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.onshoreIsDiscount === true && (
                      <>
                        {visaCaseData.course?.onshoreDiscountAmount !== "" &&
                        visaCaseData.course?.onshoreDiscountAmount !== null &&
                        visaCaseData.course?.onshoreDiscountAmount !==
                          undefined &&
                        visaCaseData.course?.onshoreDiscountType !== "" &&
                        visaCaseData.course?.onshoreDiscountType !== null &&
                        visaCaseData.course?.onshoreDiscountType !== undefined
                          ? caseDetails(
                              "Onshore Discount",
                              `${visaCaseData.course?.onshoreDiscountAmount} ${
                                visaCaseData.course?.onshoreDiscountType ===
                                "FLAT"
                                  ? "FLAT"
                                  : visaCaseData.course?.onshoreDiscountType ===
                                    "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Onshore Discount", "N/A")}
                      </>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.onshoreIsScholarship === true && (
                      <>
                        {visaCaseData.course?.onshoreScholarshipAmount !== "" &&
                        visaCaseData.course?.onshoreScholarshipAmount !==
                          null &&
                        visaCaseData.course?.onshoreScholarshipAmount !==
                          undefined &&
                        visaCaseData.course?.onshoreScholarshipType !== "" &&
                        visaCaseData.course?.onshoreScholarshipType !== null &&
                        visaCaseData.course?.onshoreScholarshipType !==
                          undefined
                          ? caseDetails(
                              "Onshore Scholarship",
                              `${
                                visaCaseData.course?.onshoreScholarshipAmount
                              } ${
                                visaCaseData.course?.onshoreScholarshipType ===
                                "FLAT"
                                  ? "FLAT"
                                  : visaCaseData.course
                                      ?.onshoreScholarshipType === "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Onshore Scholarship", "N/A")}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {visaCaseData.currentStatus === "OFFSHORE" && (
              <>
                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.offShoreFee !== "" &&
                    visaCaseData.course?.offShoreFee !== null &&
                    visaCaseData.course?.offShoreFee !== undefined
                      ? caseDetails(
                          "Offshore Fee",
                          visaCaseData.course?.offShoreFee
                        )
                      : caseDetails("Offshore Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.offshoreApplicationFee !== "" &&
                    visaCaseData.course?.offshoreApplicationFee !== null &&
                    visaCaseData.course?.offshoreApplicationFee !== undefined
                      ? caseDetails(
                          "Offshore Application Fee",
                          visaCaseData.course?.offshoreApplicationFee
                        )
                      : caseDetails("Offshore Application Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.offshoreMaterialFee !== "" &&
                    visaCaseData.course?.offshoreMaterialFee !== null &&
                    visaCaseData.course?.offshoreMaterialFee !== undefined
                      ? caseDetails(
                          "Offshore Material Fee",
                          visaCaseData.course?.offshoreMaterialFee
                        )
                      : caseDetails("Offshore Material Fee", "N/A")}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.offshoreAdditionalFee !== "" &&
                    visaCaseData.course?.offshoreAdditionalFee !== null &&
                    visaCaseData.course?.offshoreAdditionalFee !== undefined
                      ? caseDetails(
                          "Offshore Additional Fee",
                          visaCaseData.course?.offshoreAdditionalFee
                        )
                      : caseDetails("Offshore Additional Fee", "N/A")}
                  </div>
                </div>
                <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.offshoreIsDiscount === true && (
                      <>
                        {visaCaseData.course?.offshoreDiscountAmount !== "" &&
                        visaCaseData.course?.offshoreDiscountAmount !== null &&
                        visaCaseData.course?.offshoreDiscountAmount !==
                          undefined &&
                        visaCaseData.course?.offshoreDiscountType !== "" &&
                        visaCaseData.course?.offshoreDiscountType !== null &&
                        visaCaseData.course?.offshoreDiscountType !== undefined
                          ? caseDetails(
                              "Offshore Discount",
                              `${visaCaseData.course?.offshoreDiscountAmount} ${
                                visaCaseData.course?.offshoreDiscountType ===
                                "FLAT"
                                  ? "FLAT"
                                  : visaCaseData.course
                                      ?.offshoreDiscountType === "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Offshore Discount", "N/A")}
                      </>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    {visaCaseData.course?.offshoreIsScholarship === true && (
                      <>
                        {visaCaseData.course?.offshoreScholarshipAmount !==
                          "" &&
                        visaCaseData.course?.offshoreScholarshipAmount !==
                          null &&
                        visaCaseData.course?.offshoreScholarshipAmount !==
                          undefined &&
                        visaCaseData.course?.offshoreScholarshipType !== "" &&
                        visaCaseData.course?.offshoreScholarshipType !== null &&
                        visaCaseData.course?.offshoreScholarshipType !==
                          undefined
                          ? caseDetails(
                              "Offshore Discount",
                              `${
                                visaCaseData.course?.onshoreScholarshipAmount
                              } ${
                                visaCaseData.course?.offshoreScholarshipType ===
                                "FLAT"
                                  ? "FLAT"
                                  : visaCaseData.course
                                      ?.offshoreScholarshipType === "PERCENTAGE"
                                  ? "%"
                                  : null
                              }`
                            )
                          : caseDetails("Offshore Discount", "N/A")}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {visaCaseData.fee !== "" &&
            visaCaseData.fee !== null &&
            visaCaseData.fee !== undefined
              ? caseDetails(
                  "Total Fee",
                  visaCaseData.fee === NaN ? 0 : visaCaseData.fee
                )
              : caseDetails("Total Fee", "N/A")}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <div>
              <h4>PACKAGE INFORMATION:</h4>
            </div>
            <Divider orientationMargin="0" style={{ margin: "0" }} />
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.fee !== "" &&
                visaCaseData.fee !== null &&
                visaCaseData.fee !== undefined
                  ? caseDetails(
                      "Total Fee",
                      `${visaCaseData.fee} ${
                        visaCaseData.currency !== null &&
                        visaCaseData.currency !== undefined &&
                        visaCaseData.currency !== ""
                          ? visaCaseData.currency
                          : ""
                      } `
                    )
                  : caseDetails("Total Fee", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.packageTotal !== "" &&
                visaCaseData.packageTotal !== null &&
                visaCaseData.packageTotal !== undefined
                  ? caseDetails(
                      "Package Total",
                      `${visaCaseData.packageTotal} ${
                        visaCaseData.currency !== null &&
                        visaCaseData.currency !== undefined &&
                        visaCaseData.currency !== ""
                          ? visaCaseData.currency
                          : ""
                      } `
                    )
                  : caseDetails("Package Total", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.packageTotal !== "" &&
                visaCaseData.packageTotal !== null &&
                visaCaseData.packageTotal !== undefined &&
                visaCaseData.fee !== "" &&
                visaCaseData.fee !== null &&
                visaCaseData.fee !== undefined
                  ? caseDetails(
                      "Grand Total",
                      `${visaCaseData.fee + visaCaseData.packageTotal} ${
                        visaCaseData.currency !== null &&
                        visaCaseData.currency !== undefined &&
                        visaCaseData.currency !== ""
                          ? visaCaseData.currency
                          : ""
                      } `
                    )
                  : caseDetails("Grand Total", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.deadlineDate !== "" &&
                visaCaseData.deadlineDate !== null &&
                visaCaseData.deadlineDate !== undefined
                  ? caseDetails(
                      "Deadline",
                      moment(visaCaseData.deadlineDate)
                        .utc()
                        .local()
                        .format("DD-MM-YYYY")
                    )
                  : caseDetails("Deadline", "N/A")}
              </div>
            </div>

            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData.description !== "" &&
                visaCaseData.description !== null &&
                visaCaseData.description !== undefined
                  ? caseDetails("Description", visaCaseData.description)
                  : caseDetails("Description", "N/A")}
              </div>
              <div style={{ width: "100%" }}>
                {visaCaseData.priorNote !== "" &&
                visaCaseData.priorNote !== null &&
                visaCaseData.priorNote !== undefined
                  ? caseDetails("Prior Note", visaCaseData.priorNote)
                  : caseDetails("Prior Note", "N/A")}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData?.documentCheckList?.length > 0 &&
                  caseDetails(
                    "Workflow document checklist",
                    visaCaseData?.workflowDocumentCheckList?.map((document) => {
                      if (
                        visaCaseData?.documentCheckList?.includes(document.id)
                      ) {
                        return (
                          <Tag key={document.id} color="blue">
                            {document?.item}
                          </Tag>
                        );
                      }
                    })
                  )}
              </div>
            </div>
            <div className="flexRowSpaceBetween" style={{ gap: "0.3rem" }}>
              <div style={{ width: "100%" }}>
                {visaCaseData?.coursesChecklist?.length > 0 &&
                visaCaseData?.currentStatus === "ONSHORE"
                  ? caseDetails(
                      "Course document checklist",
                      visaCaseData?.course?.onShoreCheckList?.map(
                        (document) => {
                          if (
                            visaCaseData?.coursesChecklist?.includes(
                              document.id
                            )
                          ) {
                            return (
                              <Tag key={document.id} color={"blue"}>
                                {document?.item}
                              </Tag>
                            );
                          }
                        }
                      )
                    )
                  : visaCaseData?.coursesChecklist?.length > 0 &&
                    visaCaseData?.currentStatus === "OFFSHORE"
                  ? caseDetails(
                      "Course document checklist",
                      visaCaseData?.course?.offShoreCheckList?.map(
                        (document) => {
                          if (
                            visaCaseData?.coursesChecklist?.includes(
                              document.id
                            )
                          ) {
                            return (
                              <Tag key={document.id} color={"blue"}>
                                {document?.item}
                              </Tag>
                            );
                          }
                        }
                      )
                    )
                  : null}
              </div>
            </div>
          </div>
          <div
            className="flexRowWithoutStyle"
            style={{ justifyContent: "space-between", gap: "1rem" }}
          >
            {current === 3 && (
              <Button
                onClick={() => {
                  setCurrent(2);
                }}
              >
                Back
              </Button>
            )}
            <Button
              name="caseSubmit"
              type="primary"
              onClick={() => {
                addCaseSubmitHandler();
                setIsAddVisaCasesModalVisible(false);
                // setIsStartCounsellingModalVisible(false);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default VisaPreviewAndSubmit;
