import { message } from "antd";
const INIT_STATE = {
  isLoading: false,
  isOtpSend: false,
  isOTPverified: false,
  isPasswordChanged: false,
  userName: "",
  otp: "",
};

const PasswordResetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FORGOT_PASSWORD_CODE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FORGOT_PASSWORD_CODE_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        userName: action.payload.userName,
        isOtpSend: true,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_CODE_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to register email.");
      }

      return {
        ...state,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        userName: action.payload.userName,
        isOtpSend: true,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_CODE_BY_COMPANY_URL_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to register email.");
      }

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CLEAR_FORGOT_PASSWORD_DATA_REQUEST": {
      return {
        ...state,
        isOtpSend: false,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        userName: action.payload.userName,
        isOTPverified: true,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_OTP_VERIFICATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to verify OTP.");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        otp: action.payload.otp,
        userName: action.payload.userName,
        isOTPverified: true,
        isLoading: false,
      };
    }
    case "FORGOT_PASSWORD_OTP_VERIFICATION_BY_COMPANY_URL_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to verify OTP.");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    // case "FORGOT_PASSWORD_RESEND_OTP_REQUEST": {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }
    // case "FORGOT_PASSWORD_RESEND_OTP_REQUEST_SUCCESS": {
    //   message.success(action.payload.message);
    //   return {
    //     ...state,
    //     otp: action.payload.otp,
    //     userName: action.payload.userName,
    //     isLoading: false,
    //   };
    // }
    // case "FORGOT_PASSWORD_RESEND_OTP_REQUEST_FAILURE": {
    //   if (action.payload.response?.data?.message) {
    //     message.error(action.payload.response?.data?.message);
    //   } else if ((action.payload.message = "Network Error!")) {
    //     message.error("Network Error");
    //   } else {
    //     message.error("Error! Unable to resend OTP.");
    //   }
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    // }
    case "CHANGE_PASSWORD_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "CHANGE_PASSWORD_REQUEST_SUCCESS": {
      message.success(action.payload.message);
      return {
        ...state,
        isPasswordChanged: true,
        isLoading: false,
      };
    }
    case "CHANGE_PASSWORD_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to sign up.");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_SEND_OTP_STATUS": {
      return {
        ...state,
        isOtpSend: false,

        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default PasswordResetReducer;
