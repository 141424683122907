import { Divider } from "antd";
import React from "react";
import classes from "./Mailbox.module.css";
import { BsReply, BsReplyFill } from "react-icons/bs";
import moment from "moment";
import { useState } from "react";
import ReplyToMaiSubmitHandler from "./ReplyToMail";
import { useSelector } from "react-redux";

const FullLengthEmail = ({ mailRecord, fromInbox }) => {
  const store = useSelector((state) => {
    return {
      profileState: state.ProfileReducer,
    };
  });
  const momentObjDate = moment(
    mailRecord?.sentDateTime,
    "HH:mm:ss",
    "Australia/Sydney"
  );
  const utc = momentObjDate.utc();
  const [isReplyToMaiModalVisible, setIsReplyToMailModalVisible] =
    useState(false);

  const mailSydneyTimeMoment = fromInbox
    ? moment(mailRecord?.receivedDateTime, "ddd MMM DD HH:mm:ss z YYYY").format(
        "YYYY-MM-DD HH:mm"
      )
    : fromInbox === false
    ? moment(mailRecord?.sentDateTime, "ddd MMM DD HH:mm:ss z YYYY").format(
        "YYYY-MM-DD HH:mm"
      )
    : null;
  const sydneyTime = moment.tz(mailSydneyTimeMoment, "Australia/Sydney");
  const localTime = moment(sydneyTime, "HH:mm:ss")
    .utc()
    .local()
    .format("D MMMM YYYY, h:mm A");
  // const myDiv = document.getElementById("myDiv");
  // myDiv.innerHTML = "Hello \n World";
  return (
    <div style={{ padding: "1rem" }}>
      <div className="flexRowWithoutStyle">
        {mailRecord !== null &&
          store.profileState?.profile?.department?.rolesAndPermissionList?.EMAIL?.includes(
            "REPLY_EMAIL"
          ) && (
            <div
              className="flexRowWithoutStyle"
              id={classes.mailboxReplyMailboxText}
              onClick={() => {
                setIsReplyToMailModalVisible(true);
              }}
            >
              <div>
                <BsReplyFill className={classes.mailboxReplyIcon} />
              </div>
              <div className={classes.mailboxReply}>Reply</div>
            </div>
          )}
      </div>
      <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

      <div className="flexColumnwithoutStyle">
        <div
          className="flexRowWithoutStyle mailboxEmailTime"
          style={{ justifyContent: "flex-end" }}
        >
          {localTime !== "Invalid date" && localTime}
          {/* {mailRecord?.sentDateTime &&
            moment
              .utc(mailRecord?.sentDateTime, "HH:mm:ss")
              .local()
              .format("d MMMM, h:mm A")}
          {mailRecord?.receivedDateTime &&
            moment
              .utc(mailRecord?.receivedDateTime, "HH:mm:ss")
              .local()
              .format("d MMMM, h:mm A")} */}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "center" }}
        >
          <h3>{mailRecord?.subjectContent}</h3>
        </div>
        {fromInbox && (
          <div>
            {/* dangerouslySetInnerHTML={{
           __html: mailRecord?.bodyContent,
         }} */}

            <pre
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                lineHeight: "inherit",
                margin: "0",
                whiteSpace: "pre-wrap",
              }}
            >
              {mailRecord?.bodyContent}
            </pre>
          </div>
        )}
        {fromInbox === false && (
          <div
            dangerouslySetInnerHTML={{
              __html: mailRecord?.bodyContent,
            }}
          ></div>
        )}
      </div>
      {isReplyToMaiModalVisible && (
        <ReplyToMaiSubmitHandler
          fromInbox={fromInbox}
          mailRecord={mailRecord}
          isReplyToMaiModalVisible={isReplyToMaiModalVisible}
          setIsReplyToMailModalVisible={setIsReplyToMailModalVisible}
        />
      )}
    </div>
  );
};

export default FullLengthEmail;
